import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  Input,
} from '@angular/core';
import { Sort } from '../utils/sort';
@Directive({
  selector: '[appSort]',
})
export class SortDirective {
  // @Input() appSort: Array<any>;
  // constructor(private renderer: Renderer2, private targetElem: ElementRef) {}
  // @HostListener('click')
  // sortData() {
  //   const sort = new Sort();
  //   const elem = this.targetElem.nativeElement;
  //   const order = elem.getAttribute('data-order');
  //   const type = elem.getAttribute('data-type');
  //   const property = elem.getAttribute('data-name');
  //   if (order === 'desc') {
  //     this.appSort.sort(sort.startSort(property, order, type));
  //     elem.setAttribute('data-order', 'asc');
  //   } else {
  //     this.appSort.sort(sort.startSort(property, order, type));
  //     elem.setAttribute('data-order', 'desc');
  //   }
  // }
  @Input() appSort: Array<any>;
  constructor(private renderer: Renderer2, private targetElem: ElementRef) {}

  @HostListener('click')
  sortData() {
    // Create Object of Sort Class
    console.log('HEYY');
    const sort = new Sort();
    // Get Reference Of Current Clicked Element
    const elem = this.targetElem.nativeElement;
    // Get In WHich Order list should be sorted by default it should be set to desc on element attribute
    const order = elem.getAttribute('data-order');
    // Get The Property Type specially set [data-type=date] if it is date field
    const type = elem.getAttribute('data-type');
    // Get The Property Name from Element Attribute
    const property = elem.getAttribute('data-name');
    if (order === 'desc') {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute('data-order', 'asc');
    } else {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute('data-order', 'desc');
    }
  }
}
