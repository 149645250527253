import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { SwalPopupService } from 'src/app/services/swal-popup.service';

@Component({
  selector: 'app-auth-code',
  templateUrl: './auth-code.component.html',
  styleUrls: ['./auth-code.component.scss'],
})
export class AuthCodeComponent implements OnInit {
  verifyAuthCode: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  authCode: number = null;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthService,
    private loader: NgxUiLoaderService,
    private swal: SwalPopupService
  ) {}

  ngOnInit(): void {}

  get f() {
    return this.verifyAuthCode.controls;
  }

  onSubmit() {
    if (this.authCode && this.authCode.toString().length === 6) {
      this.loader.start();
      this.authenticationService.verifyAuthCode(this.authCode).subscribe(
        (resp) => {
          this.loader.stop();
          if (
            resp['statusCode'] == 100 &&
            resp['message'] == 'Authentication code verified successfully'
          ) {
            let {
              tokens: { accessToken, refreshToken },
              user,
            } = resp['data'];

            this.swal.success('Authentication code verified successfully');
            this.authenticationService.setUserAndToken(
              accessToken,
              refreshToken,
              user,
              user.email
            );
            this.router.navigate(['/reset-password']);
          } else {
            this.loader.stop();
            this.swal.error('Something went wrong');
          }
        },
        (error: HttpErrorResponse) => {
          this.loader.stop();
          console.error(error);
          error['error']['message'] == 'Authentication failure'
            ? this.swal.error('Please enter authentication code')
            : this.swal.error(error['error']['message']);
        }
      );
    } else {
      this.swal.error('Invalid authentication code!');
    }
  }
}
