import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HotToastModule } from '@ngneat/hot-toast';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttpInterceptor } from './custom-http-interceptor';
import { ToastrModule } from 'ngx-toastr';
import { AppPipesModule } from './app-pipes/app-pipes.module';
import { AuthGuard } from './services/auth.guard';
import { ExcelService } from './services/excel.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
/* state management */
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
/* states */
// import { AnalysisState } from "./store/state/analysis.state";
import { CreditApplicationsState } from './store/state/credit-state/credit-dashboard.state';
import { RevenueState } from './store/state/credit-state/revenue.state';
import { AnalysisState } from './store/state/credit-state/analysis.state';
import { IcAnalysisState } from './store/state/ic-state/ic-analysis.state';
import { CompanyState } from './store/state/company.state';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { UiSwitchModule } from 'ngx-ui-switch';

import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  POSITION,
  SPINNER,
} from 'ngx-ui-loader';
import { OpsDashboardState } from './store/state/ops-state/ops-dashboard.state';
import { DealsState } from './store/state/deals.state';
import { RecoveryState } from './store/state/recovery.state';
import { environment } from 'src/environments/environment';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { AuthCodeComponent } from './auth/auth-code/auth-code.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { Config } from './config';
import { PdListState } from './store/state/ic-state/pd-list.state';
import { MIS_State } from './store/state/mis.state';
import { OpsEFDashboardState } from './store/state/ops-state/opsEF.state';
import { RepaymentsState } from './store/state/ops-state/repayments.state';
import { DealsEfState } from './store/state/deals-ef.state';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MaskDirective } from './utils/mask.directive';

const config: SocketIoConfig = {
  url: new Config().getSocketUrl(),
  options: {},
};
export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

const states: Array<any> = [
  CreditApplicationsState,
  RevenueState,
  IcAnalysisState,
  AnalysisState,
  CompanyState,
  OpsDashboardState,
  DealsState,
  RecoveryState,
  PdListState,
  MIS_State,
  OpsEFDashboardState,
  RepaymentsState,
  DealsEfState,
];

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'var(--main-color)',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.squareJellyBox, // background spinner type
  fgsType: SPINNER.squareJellyBox, // progress bar thickness
};

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-auth-code', component: AuthCodeComponent },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    AuthCodeComponent,
    ResetPasswordComponent,
    MaskDirective,
  ],
  imports: [
    AppPipesModule,
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    NgxMaskModule.forRoot(options),
    HotToastModule.forRoot({ position: 'bottom-center' }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    /* state management */
    NgxsModule.forRoot(states),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    SocketIoModule.forRoot(config),
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'rgb(134 219 251)',

      defaultBgColor: '#24a5bb',
      defaultBoColor: '#24a5bb',
      checkedLabel: 'on',
      uncheckedLabel: 'off',
    }),
    ReactiveFormsModule,
    ScrollingModule,
  ],
  providers: [
    AuthGuard,
    DatePipe,
    ExcelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor, // change this line with your custom http interceptor
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
