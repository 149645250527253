import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core"
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { tap } from "rxjs/operators";
import { OpsService } from "../../../services/ops.service";
import { ChangeValueInChecklist, FetchAllInvoices, FetchApplications, FetchBorrowerRequest, FetchCardSpends, FetchChecklistLogs, FetchDailyRepayments, FetchDealActivityLogs, FetchDealParams, FetchDocumentList, FetchDocumentLogs, FetchInvestingPartners, FetchLoanRequest, FetchOnboardingChecklist, FetchFPChecklist,FetchPayments, FetchPendingActionCount, FetchPendingActionsByType, FetchPgAccounts, FetchPnachRequest, FetchRecoveryAccounts, FetchVendors, GenerateFinboxSession, ResetOnDifferentApplication, SetSelectedApplication } from "../../actions/ops.action";
import { KudosService } from "../../../services/kudos.service";
import { updateState } from "../../../utils/immutableUpdate";
import { DealParams } from "src/app/models/dealParams.model";
import { FinboxService } from "src/app/services/finbox.service";
import { CompanyService } from "src/app/services/company.service";

export class OpsDashboardStateModel {
    isApplicationsLoaded: boolean
    isDealLoaded: boolean
    isPaymentsLoaded: boolean
    isVendorsLoaded: boolean
    isChecklistLoaded: boolean
    isDealActivityLogsLoaded: boolean
    isChecklistLogsLoaded: boolean
    isDocumentListLoaded: boolean
    isDocumentLogsLoaded: boolean
    isRecoveryAccountsLoaded: boolean
    isPgAccountsLoaded: boolean
    isInvoicesLoaded: boolean
    isCardSpendsLoaded: boolean
    isInvestingPartnersLoaded: boolean
    isPendingActionCountLoaded: boolean
    isPendingActionDetailsLoaded: boolean
    isFeeDisbursementsLoaded: boolean
    isDailyRepaymentsLoaded: boolean
    isFpChecklistLoaded:boolean;

    applications: any[]
    currentPage: number
    totalPages: number
    searchKey: string
    selectedApplication: string
    selectedCompany: string
    dealData: DealParams
    pgAccounts: any[]
    bankAccounts: any[]
    payments: any[]
    vendors: any[]
    checklist: object
    fpChecklist: object
    sessionUrl: string
    dealActivityLogs: any[]
    checklistLogs: any[]
    uploadedDocumentList: any[]
    documentLogs: any[]
    recoveryAccounts: any[]
    allInvoices: any[]
    loanRequest: object
    isLoanRequestLoaded: boolean
    borrowerRequest: object
    isborrowerRequestLoaded: boolean
    pNachRequest: object
    ispNachRequestLoaded: boolean
    totalInvoicePages: number
    currentInvoicePage: number
    invoiceSearchedCompany: string
    cardSpends: any[]
    totalSpendsPages: number
    currentSpendPage: number
    investingPartners: any[]
    pendingActionsCount: any
    pendingActionDetails: any
    feeDisbursements: any[]
    dailyRepayments: any[]
}

/* ========================================Initial State======================================== */
@State<OpsDashboardStateModel>({
    name: 'opsDashboardState',
    defaults: {
        isApplicationsLoaded: false,
        applications: null,
        currentPage: 0,
        totalPages: 0,
        searchKey: '',
        selectedApplication: null,
        selectedCompany: null,
        dealData: null,
        pgAccounts: [],
        bankAccounts: [],
        isDealLoaded: false,
        isPaymentsLoaded: false,
        payments: null,
        isVendorsLoaded: false,
        vendors: null,
        isChecklistLoaded: false,
        checklist: null,
        fpChecklist: null,
        sessionUrl: null,
        isDealActivityLogsLoaded: false,
        dealActivityLogs: [],
        isChecklistLogsLoaded: false,
        isFpChecklistLoaded: false,
        checklistLogs: [],
        isDocumentListLoaded: false,
        uploadedDocumentList: [],
        documentLogs: [],
        isDocumentLogsLoaded: false,
        loanRequest: null,
        isLoanRequestLoaded: false,
        borrowerRequest: null,
        isborrowerRequestLoaded: false,
        pNachRequest: null,
        ispNachRequestLoaded: false,
        isRecoveryAccountsLoaded: false,
        recoveryAccounts: [],
        isPgAccountsLoaded: false,
        allInvoices: [],
        isInvoicesLoaded: false,
        currentInvoicePage: 0,
        invoiceSearchedCompany: null,
        totalInvoicePages: 0,
        isCardSpendsLoaded: false,
        cardSpends: [],
        totalSpendsPages: 0,
        currentSpendPage: 0,
        investingPartners: [],
        isInvestingPartnersLoaded: false,
        isPendingActionCountLoaded: false,
        isPendingActionDetailsLoaded: false,
        pendingActionsCount: {
            pendingDeals: 0,
            pendingDocuments: 0,
            pendingPayments: 0,
            noDocuments: 0,
        },
        pendingActionDetails: {
            documents: [],
            deals: [],
            payments: [],
            noDocuments: []
        },
        isFeeDisbursementsLoaded: false,
        feeDisbursements: [],
        isDailyRepaymentsLoaded: false,
        dailyRepayments: []
    }
})

@Injectable()
export class OpsDashboardState {
    constructor(private _opsService: OpsService, private _kudosService: KudosService,
        private _finService: FinboxService, private _compService: CompanyService) { }

    /* ========================================Selectors======================================== */
    @Selector()
    static isApplicationsLoaded(state: OpsDashboardStateModel) {
        return state.isApplicationsLoaded
    }

    @Selector()
    static applicationDetails(state: OpsDashboardStateModel) {
        return {
            applications: state.applications,
            currentPage: state.currentPage,
            totalPages: state.totalPages,
            searchedApplication: state.searchKey
        }
    }

    @Selector()
    static getSelectedApplication(state: OpsDashboardStateModel) {
        return state.selectedApplication
    }

    @Selector()
    static getSelectedCompany(state: OpsDashboardStateModel) {
        return state.selectedCompany
    }

    @Selector()
    static getDealData(state: OpsDashboardStateModel) {
        return state.dealData
    }

    @Selector()
    static isPaymentsLoaded(state: OpsDashboardStateModel) {
        return state.isPaymentsLoaded
    }

    @Selector()
    static isVendorsLoaded(state: OpsDashboardStateModel) {
        return state.isVendorsLoaded
    }

    @Selector()
    static getVendorsList(state: OpsDashboardStateModel) {
        return state.vendors
    }

    @Selector()
    static getPaymentsList(state: OpsDashboardStateModel) {
        return state.payments
    }

    @Selector()
    static isChecklistLoaded(state: OpsDashboardStateModel) {
        return state.isChecklistLoaded
    }

    @Selector()
    static isFPChecklistLoaded(state: OpsDashboardStateModel){
        return state.isFpChecklistLoaded
    }

    @Selector()
    static getChecklist(state: OpsDashboardStateModel) {
        return state.checklist
    }

    @Selector()
    static getFPChecklist(state: OpsDashboardStateModel){
       return state.fpChecklist
    }

    @Selector()
    static getSessionUrl(state: OpsDashboardStateModel) {
        return state.sessionUrl
    }

    @Selector()
    static isDealActivityLoaded(state: OpsDashboardStateModel) {
        return state.isDealActivityLogsLoaded
    }

    @Selector()
    static getDealActivity(state: OpsDashboardStateModel) {
        return state.dealActivityLogs
    }

    @Selector()
    static isChecklistLogsLoaded(state: OpsDashboardStateModel) {
        return state.isChecklistLogsLoaded
    }

    @Selector()
    static getChecklistLogs(state: OpsDashboardStateModel) {
        return state.checklistLogs
    }

    @Selector()
    static isDocumentListLoaded(state: OpsDashboardStateModel) {
        return state.isDocumentListLoaded
    }

    @Selector()
    static uploadedDocuments(state: OpsDashboardStateModel) {
        return state.uploadedDocumentList
    }

    @Selector()
    static isDocumentLogsLoaded(state: OpsDashboardStateModel) {
        return state.isDocumentLogsLoaded
    }

    @Selector()
    static documentLogs(state: OpsDashboardStateModel) {
        return state.documentLogs
    }

    @Selector()
    static isRecoveryAccountsLoaded(state: OpsDashboardStateModel) {
        return state.isRecoveryAccountsLoaded
    }

    @Selector()
    static recoveryAccounts(state: OpsDashboardStateModel) {
        return state.recoveryAccounts
    }

    @Selector()
    static isPgAccountsLoaded(state: OpsDashboardStateModel) {
        return state.isPgAccountsLoaded
    }

    @Selector()
    static pgAccounts(state: OpsDashboardStateModel) {
        return state.pgAccounts
    }

    static isLoanRequestLoaded(state: OpsDashboardStateModel) {
        return state.isLoanRequestLoaded
    }

    @Selector()
    static getLoandRequest(state: OpsDashboardStateModel) {
        return state.loanRequest
    }


    @Selector()
    static isBorrowerRequestLoaded(state: OpsDashboardStateModel) {
        return state.isborrowerRequestLoaded
    }

    @Selector()
    static getBorrowerRequest(state: OpsDashboardStateModel) {
        return state.borrowerRequest
    }

    @Selector()
    static isPnachRequestLoaded(state: OpsDashboardStateModel) {
        return state.ispNachRequestLoaded
    }

    @Selector()
    static getPnachRequest(state: OpsDashboardStateModel) {
        return state.pNachRequest
    }

    @Selector()
    static isAllInvoicesLoaded(state: OpsDashboardStateModel) {
        return state.isInvoicesLoaded
    }

    @Selector()
    static allInvoices(state: OpsDashboardStateModel) {
        return {
            invoices: state.allInvoices,
            totalPages: state.totalInvoicePages,
            currentPage: state.currentInvoicePage,
            searchedKey: state.invoiceSearchedCompany
        }
    }

    @Selector()
    static isCardSpendsLoaded(state: OpsDashboardStateModel) {
        return state.isCardSpendsLoaded
    }

    @Selector()
    static cardSpends(state: OpsDashboardStateModel) {
        return {
            allSpends: state.cardSpends,
            currentPage: state.currentSpendPage,
            totalPages: state.totalSpendsPages
        }
    }

    @Selector()
    static isInvestingPartnersLoaded(state: OpsDashboardStateModel) {
        return state.isInvestingPartnersLoaded
    }

    @Selector()
    static investingPartnersList(state: OpsDashboardStateModel) {
        return state.investingPartners
    }


    /* ========================================Actions======================================== */
    @Action(FetchApplications)
    fetchApplications({ getState, setState }: StateContext<OpsDashboardStateModel>, { payload }: FetchApplications) {
        const state = getState();
        let page = payload.page
        let count = payload.count
        let company = payload.search
        return this._opsService.fetchApplicationListForOpsNew(payload).pipe(
            tap({
                next: (data:any) => {
                    if(data.statusCode === "100"){
                        updateState(state, this.successUpdate(data, {page,company, count}), setState)
                    }else{
                        updateState(state, this.failureUpdate(), setState)
                    }
                  },
                  error: (error) => {
                      console.log('on error', error.message);
                    updateState(state, this.failureUpdate(), setState)
                  },
            })
        )
    }

    @Action(ResetOnDifferentApplication)
    resetOnDifferentApplication({ getState, setState }: StateContext<OpsDashboardStateModel>,) {
        const state = getState();
        updateState(state, {
            isDealLoaded: false, isPaymentsLoaded: false, isVendorsLoaded: false, isChecklistLoaded: false, isFPChecklistLoaded: false,
            isDealActivityLogsLoaded: false, isChecklistLogsLoaded: false, dealData: null, isDocumentListLoaded: false, isDocumentLogsLoaded: false, isRecoveryAccountsLoaded: false, isPgAccountsLoaded: false, isCardSpendsLoaded: false,
            isInvestingPartnersLoaded: false
        }, setState)
    }

    @Action(SetSelectedApplication)
    setSelectedApplication({ getState, setState }: StateContext<OpsDashboardStateModel>, { payload }: SetSelectedApplication) {
        const state = getState();
        updateState(state, { selectedApplication: payload }, setState)
    }

    @Action(FetchDealParams)
    fetchDealData({ getState, setState }: StateContext<OpsDashboardStateModel>, { payload }: FetchDealParams) {
        const state = getState();
        return this._opsService.fetchDealData(payload).pipe(
            tap(data => {
                (data.statusCode === "100")
                    && updateState(state, this.fetchedDealData(data['data']), setState)
                    || updateState(state, { dealData: null }, setState)
            },
                error => updateState(state, { dealData: null }, setState))
        )
    }

    @Action(FetchVendors)
    fetchVendors({ getState, setState }: StateContext<OpsDashboardStateModel>, { id }: FetchVendors) {
        const state = getState();
        updateState(state, { isVendorsLoaded: false }, setState)
        return this._opsService.fetchVendors(id).pipe(
            tap(data => {
                (data.statusCode === "100")
                    && updateState(state, { isVendorsLoaded: true, vendors: data['data'] }, setState)
                    || updateState(state, { isVendorsLoaded: false, vendors: null }, setState)
            },
                error => updateState(state, { isVendorsLoaded: false, vendors: null }, setState))
        )
    }

    @Action(FetchPayments)
    fetchPayments({ getState, setState }: StateContext<OpsDashboardStateModel>, { id }: FetchPayments) {
        const state = getState();
        updateState(state, { isPaymentsLoaded: false }, setState)
        return this._opsService.fetchPayments(id).pipe(
            tap(data => {
                (data.statusCode === "100")
                    && updateState(state, { isPaymentsLoaded: true, payments: data['data']['Payments'] }, setState)
                    || updateState(state, { isPaymentsLoaded: false, payments: null }, setState)
            },
                error => updateState(state, { isPaymentsLoaded: false, payments: null }, setState))
        )
    }

    @Action(FetchOnboardingChecklist)
    fetchChecklist({ getState, setState }: StateContext<OpsDashboardStateModel>, { id }: FetchOnboardingChecklist) {
        const state = getState();
        return this._opsService.fetchChecklist(id).pipe(
            tap(data => {
                (data.statusCode === "100")
                    && updateState(state, { isChecklistLoaded: true, checklist: data['data'] }, setState)
                    || updateState(state, { isChecklistLoaded: false, checklist: null }, setState)
            },
                error => updateState(state, { isChecklistLoaded: false, checklist: null }, setState))
        )
    }

    @Action(FetchFPChecklist)
    fetchFPChecklist({ getState, setState }: StateContext<OpsDashboardStateModel>, { id }: FetchFPChecklist) {
        const state = getState();
        return this._opsService.fetchFPChecklist(id).pipe(
            tap(data => {
                (data.statusCode === "100")
                    && updateState(state, { isFPChecklistLoaded: true, fpChecklist: data['data'] }, setState)
                    || updateState(state, { isFPChecklistLoaded: false, fpChecklist: null }, setState)
            },
                error => updateState(state, { isFPChecklistLoaded: false, fpChecklist: null }, setState))
        )
    }


    @Action(FetchLoanRequest)
    fetchLoanRequest({ getState, setState }: StateContext<OpsDashboardStateModel>, { compId, appId }: FetchLoanRequest) {
        const state = getState();
        updateState(state, { isLoanRequestLoaded: false }, setState)
        return this._kudosService.getLoanRequest(compId, appId).pipe(
            tap(data => {
                (data.statusCode === "100")
                    && updateState(state, { isLoanRequestLoaded: true, loanRequest: data['data'] }, setState)
                    || updateState(state, { isLoanRequestLoaded: false, loanRequest: null }, setState)
            },
                error => updateState(state, { isLoanRequestLoaded: false, loanRequest: null }, setState))
        )
    }


    @Action(FetchBorrowerRequest)
    fetchBorrowerRequest({ getState, setState }: StateContext<OpsDashboardStateModel>, { compId, appId }: FetchBorrowerRequest) {
        const state = getState();
        updateState(state, { isborrowerRequestLoaded: false }, setState)
        return this._kudosService.getBorrowerRequest(compId, appId).pipe(
            tap(data => {
                (data.statusCode === "100")
                    && updateState(state, { isborrowerRequestLoaded: true, borrowerRequest: data['data'] }, setState)
                    || updateState(state, { isborrowerRequestLoaded: false, borrowerRequest: null }, setState)
            },
                error => updateState(state, { isborrowerRequestLoaded: false, borrowerRequest: null }, setState))
        )
    }



    @Action(FetchPnachRequest)
    fetchPnachRequest({ getState, setState }: StateContext<OpsDashboardStateModel>, { compId, appId }: FetchPnachRequest) {
        const state = getState();
        updateState(state, { ispNachRequestLoaded: false }, setState)
        return this._kudosService.getPnachRequest(compId, appId).pipe(
            tap(data => {
                (data.statusCode === "100")
                    && updateState(state, { ispNachRequestLoaded: true, pNachRequest: data['data'] }, setState)
                    || updateState(state, { ispNachRequestLoaded: false, pNachRequest: null }, setState)
            },
                error => updateState(state, { ispNachRequestLoaded: false, pNachRequest: null }, setState))
        )
    }

    @Action(ChangeValueInChecklist)
    changeDataInChecklist({ getState, setState }: StateContext<OpsDashboardStateModel>, { payload }: ChangeValueInChecklist) {
        const state = getState();
        updateState(state, { checklist: payload }, setState)
    }

    @Action(GenerateFinboxSession)
    generateSessionUrl({ getState, setState }: StateContext<OpsDashboardStateModel>, { id }: GenerateFinboxSession) {
        const state = getState();
        return this._finService.generateSession(id).pipe(tap(
            data => {
                updateState(state, { sessionUrl: data['redirect_url'] }, setState)
            },
            error => updateState(state, { sessionUrl: null }, setState)
        ))
    }

    @Action(FetchDealActivityLogs)
    fetchDealLogs({ getState, setState }: StateContext<OpsDashboardStateModel>, { id }: FetchDealActivityLogs) {
        const state = getState();
        return this._opsService.loadDealActivityLogs(id).pipe(tap(
            data => {
                (data.statusCode === "100")
                    && updateState(state, { dealActivityLogs: data['data'], isDealActivityLogsLoaded: true }, setState)
                    || updateState(state, { dealActivityLogs: [], isDealActivityLogsLoaded: false }, setState)
            },
            error => updateState(state, { dealActivityLogs: [], isDealActivityLogsLoaded: false }, setState)
        ))
    }

    @Action(FetchChecklistLogs)
    fetchChecklistLogs({ getState, setState }: StateContext<OpsDashboardStateModel>, { id }: FetchChecklistLogs) {
        const state = getState();
        return this._opsService.fetchChecklistLogs(id).pipe(tap(
            data => {
                (data.statusCode === "100")
                    && updateState(state, { checklistLogs: data['data'], isChecklistLogsLoaded: true }, setState)
                    || updateState(state, { checklistLogs: [], isChecklistLogsLoaded: false }, setState)
            },
            error => updateState(state, { checklistLogs: [], isChecklistLogsLoaded: false }, setState)
        ))
    }

    @Action(FetchDocumentList)
    fetchUploadedDocuments({ getState, setState }: StateContext<OpsDashboardStateModel>, { id }: FetchDocumentList) {
        const state = getState();
        return this._opsService.getListOfUploadedDocuments(id).pipe(tap(
            data => {
                (data.statusCode === "100")
                    && updateState(state, { isDocumentListLoaded: true, uploadedDocumentList: data['data']['documents'] }, setState)
                    || updateState(state, { isDocumentListLoaded: false, uploadedDocumentList: [] }, setState)
            },
            error => updateState(state, { isDocumentListLoaded: false, uploadedDocumentList: [] }, setState)
        ))
    }

    @Action(FetchDocumentLogs)
    fetchDocumentLogs({ getState, setState }: StateContext<OpsDashboardStateModel>, { id }: FetchDocumentLogs) {
        const state = getState();
        return this._opsService.fetchDocumentLogs(id).pipe(tap(
            data => {
                (data.statusCode === "100")
                    && updateState(state, { isDocumentLogsLoaded: true, documentLogs: data['data'] }, setState)
                    || updateState(state, { isDocumentLogsLoaded: false, documentLogs: [] }, setState)
            },
            error => updateState(state, { isDocumentLogsLoaded: false, documentLogs: [] }, setState)
        ))
    }

    @Action(FetchRecoveryAccounts)
    getRecoveryAccounts({ getState, setState }: StateContext<OpsDashboardStateModel>, { id }: FetchRecoveryAccounts) {
        const state = getState();
        return this._opsService.fetchRecoveryAccounts(id).pipe(tap(
            data => {
                (data.statusCode === "100")
                    && updateState(state, { isRecoveryAccountsLoaded: true, recoveryAccounts: data['data'] }, setState)
                    || updateState(state, { isRecoveryAccountsLoaded: false, recoveryAccounts: [] }, setState)
            },
            error => updateState(state, { isRecoveryAccountsLoaded: false, recoveryAccounts: [] }, setState)
        ))
    }

    @Action(FetchPgAccounts)
    getPgAccounts({ getState, setState }: StateContext<OpsDashboardStateModel>, { id }: FetchPgAccounts) {
        const state = getState();
        return this._compService.fetchPgAccountsByCompany(id).pipe(tap(
            data => {
                (data.statusCode === "100")
                    && updateState(state, { isPgAccountsLoaded: true, pgAccounts: data['data'] }, setState)
                    || updateState(state, { isPgAccountsLoaded: false, pgAccounts: [] }, setState)
            },
            error => updateState(state, { isPgAccountsLoaded: false, pgAccounts: [] }, setState)
        ))
    }

    @Action(FetchAllInvoices)
    getAllInvoices({ getState, setState }: StateContext<OpsDashboardStateModel>, { payload }: FetchAllInvoices) {
        const state = getState();
        return this._opsService.fetchLatestInvoices(payload).pipe(tap(
            data => {
                const { page, company } = payload;
                (data.statusCode === "100")
                    && updateState(state, {
                        isInvoicesLoaded: true, allInvoices: data['data']['Payments'],
                        totalInvoicePages: Math.ceil(data['data']['totalPayments'] / 10),
                        currentInvoicePage: page, invoiceSearchedCompany: company
                    }, setState)
                    || updateState(state, {
                        isInvoicesLoaded: false, allInvoices: [],
                        totalInvoicePages: 0, invoiceSearchedCompany: null
                    }, setState)
            },
            error => updateState(state, { isInvoicesLoaded: false, allInvoices: [], totalInvoicePages: 0, invoiceSearchedCompany: null }, setState)
        ))
    }

    @Action(FetchCardSpends)
    getCardSpends({ getState, setState }: StateContext<OpsDashboardStateModel>, { payload }: FetchCardSpends) {
        const state = getState();
        return this._opsService.fetchCardSpends(payload).pipe(tap(
            data => {
                const { page } = payload;
                (data.statusCode === "100")
                    && updateState(state, {
                        isCardSpendsLoaded: true, cardSpends: data['data']['allSpends'],
                        totalSpendsPages: Math.ceil(data['data']['totalCount'] / 10), currentSpendPage: page
                    }, setState)
                    || updateState(state, { isCardSpendsLoaded: false, cardSpends: [], totalSpendsPages: 0 }, setState)
            },
            error => updateState(state, { isCardSpendsLoaded: false, cardSpends: [], totalSpendsPages: 0 }, setState)
        ))
    }

    @Action(FetchInvestingPartners)
    getInvestingPartners({ getState, setState }: StateContext<OpsDashboardStateModel>) {
        const state = getState();
        return this._opsService.fetchInvestingPartnersList().pipe(tap(
            data => {
                (data.statusCode === "100")
                    && updateState(state, { isInvestingPartnersLoaded: true, investingPartners: data['data'] }, setState)
                    || updateState(state, { isInvestingPartnersLoaded: false, investingPartners: [] }, setState)
            },
            error => updateState(state, { isInvestingPartnersLoaded: false, investingPartners: [] }, setState)
        ))
    }

    /* ===================================Pending Actions======================== */
    @Selector()
    static isPendingActionsCountLoaded(state: OpsDashboardStateModel) {
        return state.isPendingActionCountLoaded
    }

    @Selector()
    static isPendingActionsDetailsLoaded(state: OpsDashboardStateModel) {
        return state.isPendingActionDetailsLoaded
    }

    @Selector()
    static getPendingActionCount(state: OpsDashboardStateModel) {
        return state.pendingActionsCount
    }

    @Selector()
    static getPendingActionsList(state: OpsDashboardStateModel) {
        return state.pendingActionDetails
    }

    @Action(FetchPendingActionCount)
    getPendingActionsCount({getState, setState}:StateContext<OpsDashboardStateModel>){
        const state = getState();
        return this._opsService.getPendingActionsCount().pipe(tap(
            data=>{
                (data.statusCode === "100")
                && updateState(state, { isPendingActionCountLoaded: true, pendingActionsCount: data['data'] }, setState)
                || updateState(state, { isPendingActionCountLoaded: false, pendingActionsCount: {
                    pendingDeals: 0,
                    pendingDocuments: 0,
                    noDocuments: 0,
                    pendingPayments: 0,
                } }, setState)
            },
            error=> updateState(state, { isPendingActionCountLoaded: false, pendingActionsCount: {
                pendingDeals: 0,
                pendingDocuments: 0,
                noDocuments: 0,
                pendingPayments: 0,
            } }, setState)
        ))
    }

    // @Action(FetchPendingActionsByType)
    // getPendingActionsByType({getState, setState}:StateContext<OpsDashboardStateModel>, {payload}: FetchPendingActionsByType){
    //     const state = getState();
    //     return this._opsService.getPendingActions(payload).pipe(tap(
    //         data=>{
    //             (data.statusCode === "100")
    //             && updateState(state, { isPendingActionDetailsLoaded: true, pendingActionDetails: data['data'] }, setState)
    //             || updateState(state, { isPendingActionDetailsLoaded: false, pendingActionDetails: {
    //                 pendingDeals: 0,
    //                 pendingDocuments: 0,
    //                 pendingPayments: 0,
    //             } }, setState)
    //         },
    //         error=> updateState(state, { isPendingActionDetailsLoaded: false, pendingActionDetails: {
    //             pendingDeals: 0,
    //             pendingDocuments: 0,
    //             pendingPayments: 0,
    //         } }, setState)
    //     ))
    // }
    /* ===================================Pending Actions======================== */

    /* ========================================Helpers======================================== */

    successUpdate(data, { page, company, count }) {
        console.log('COMPANY NAMES INSIDE STATE::::::::::::::::::::::::>',company)
        return {
            isApplicationsLoaded: true,
            applications: data['data']['applications'],
            currentPage: page,
            totalPages: Math.ceil(data['data']['totalCount'] / count),
            searchKey: company
        }
    }

    failureUpdate() {
        return {
            isApplicationsLoaded: false,
            applications: null,
            currentPage: 1,
            totalPages: 0,
            searchKey: ''
        }
    }

    fetchedDealData(data) {
        return {
            dealData: {
                dealId: data._id,
                dealStatus: data?.dealStatus || '--',
                opsAdminStatus: data?.opsAdminStatus || '--',
                opsManagerStatus: data?.opsManagerStatus || '--',
                productType: data?.productType || '',
                fundIssued: data?.fundIssued || null,
                feeRate: data?.feeRate || null,
                revShare: data?.revShare || 0,
                escrowRevShare: data?.escrowRevShare || 0,
                dealRevShare: data?.dealRevShare || 0,
                absoluteFeeExcTax: data?.absoluteFeeExcTax || null,
                absoluteFeeIncTax: data?.absoluteFeeIncTax || null,
                absoluteRecoveryAmtIncTax: data?.absoluteRecoveryAmtIncTax || 0,
                monthlyLimit: data?.monthlyLimit || 0,
                moratoriumPeriod: data?.moratoriumPeriod || 0,
                expectedDailyRecovery: data?.expectedDailyRecovery || 0,
                recoveryStartDate: data?.recoveryStartDate || null,
                disbursementStartDate: data?.disbursementStartDate || "",
                dealStartDate: data?.dealStartDate || "",
                isCardIssued: data?.isCardIssued || null,
                cardType: data?.cardType || null,
                cardNumber: data?.cardNumber || null,
                fundingPartnerShare: data?.fundingPartnerShare || null,
                feeStructureModel: data?.feeStructureModel || null,
                feePricing: data?.feePricing || null,
                investingPartner: data?.investingPartner || null,
                investingPartnerName: data?.investingPartnerName || null,
                gst: data?.gst || null,
                upfrontFee: data?.upfrontFee || 0,
                remainFacilityFee: data?.remainFacilityFee || 0,
                totalMonthlyRepaymentLiability: {
                    revShareType: data?.totalMonthlyRepaymentLiability?.revShareType || '',
                    minimumLiability: data?.totalMonthlyRepaymentLiability?.minimumLiability || 'NA',
                    maximumLiability: data?.totalMonthlyRepaymentLiability?.maximumLiability || 'NA',
                },
                recoveryMechanisms: {
                    pg: data?.recoveryMechanisms?.pg || [],
                    nach: data?.recoveryMechanisms?.nach || [],
                    pdc: data?.recoveryMechanisms?.pdc || []
                },
                spocDetails: data?.spocDetails || [],
                agreementConditions: {
                    preDisbursementConditions: data?.agreementConditions?.preDisbursementConditions || [],
                    postDisbursementConditions: data?.agreementConditions?.postDisbursementConditions || []
                },
                recoveryMode: data?.recoveryMode || '',
                virtualAccNum: data.recoveryAccounts?.length ? data.recoveryAccounts[0]?.accountNumber : null,
                virtualAccIfsc: data.recoveryAccounts?.length ? data.recoveryAccounts[0]?.accountIFSC : null,
                bankDetails: {
                    accountHolderName: data?.bankDetails?.accountHolderName || null,
                    accountNumber: data?.bankDetails?.accountNumber || null,
                    bankName: data?.bankDetails?.bankName || null,
                    branchName: data?.bankDetails?.branchName || null,
                    ifsc: data?.bankDetails?.ifsc || null
                },
                inCredUMRN: data?.inCredUMRN || null,
                inCredLoanNumber: data?.inCredLoanNumber || null,
                collectionType: data?.collectionType || '',
                fpClientVANumber: data?.fpClientVANumber || '',
                standingAmount:data?.standingAmount || 0,
                freqPrincipleCollection: data?.freqPrincipleCollection || '',
                isDailyLiabilityApplicable: data?.isDailyLiabilityApplicable || false,
                dailyLiabilityAmount:data?.dailyLiabilityAmount || 0,
                liabilityGenerationFrequency:data?.liabilityGenerationFrequency || '',
                isExceptionApplicable : data?.isExceptionApplicable || false,
                exceptions: data?.exceptions  || [],
                facilityType: data?.facilityType || null,
                facilitySubType: data?.facilitySubType || null,
                monthlyEmi: data?.monthlyEmi || null,
                interestRate: data?.interestRate || null,
                estimatedTenure: data?.estimatedTenure || null,
                emiDate: data?.emiDate || null,
                revolvingDay: data?.revolvingDay || null,
                isCashCollateral: data?.isCashCollateral || false,
                feeCollectionMechanism: data?.feeCollectionMechanism || '',
                programType: data?.programType,
                repaymentFrequencyDays: data?.repaymentFrequencyDays,
                limitValidity:  data?.limitValidity,
                vendorLimit: data?.vendorLimit,
                invoiceAgeing: data?.invoiceAgeing,
                MaxDisbursedTranche: data?.MaxDisbursedTranche,
                security: data?.security || [],
                city: data?.city || '',
                state: data?.state || ''
            }
        }
    }

}
