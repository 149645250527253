import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Directive, HostListener, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Config } from '../config';

@Directive({
  selector: '[appDownloadDocument]',
})
export class DownloadDocumentDirective {
  filename = '';
  baseUrl = new Config().getBaseURL();
  constructor(
    private readonly httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private loader: NgxUiLoaderService
  ) {}

  private downloadUrl: string;

  @Input('appDownloadDocument')
  public set url(url: string) {
    this.downloadUrl = url;
  }

  @HostListener('click')
  public async onClick(): Promise<void> {
    this.loader.start();
    var docName = '';
    // console.log(this.downloadUrl);
    if (this.downloadUrl.includes(`${this.baseUrl}/document/openAPI`)) {
      // for mis, and other doc downloads
      docName = this.downloadUrl
        .split('document/')
        .pop()
        .split('/')[2]
        .split('?')[1]
        .split('=')[1]
        .split('&')[0];
      console.log(docName);
    } else if (this.downloadUrl.includes('incredNachReport')) {
      let details = this.downloadUrl
        .split('incredNachReport/')
        .pop()
        .split('?')[1];
      docName = `InCred NACH Report_${details}`;
    } else if (this.downloadUrl.includes('marketingdata')) {
      let companyId = this.downloadUrl.split('/')[5];
      docName = `Spends Detailed Report_${companyId}`;
    } else if (
      this.downloadUrl.includes('/recovery/OpsRecovery/collectionRecords')
    ) {
      docName = `Collection Liabilities${new Date().toLocaleString()}`;
    } else if (this.downloadUrl.includes('pgdata')) {
      let companyId = this.downloadUrl.split('/')[5];
      docName = `PG Detailed Report_${companyId}`;
    } else if (this.downloadUrl.includes('pgOndemandData')) {
      let companyId = this.downloadUrl.split('/')[5];
      docName = `PG Ondemand Detailed Report_${companyId}`;
    } else if (this.downloadUrl.includes(`${this.baseUrl}/payment/ops`)) {
      // for payment invoice
      docName = 'Invoice';
    } else if (
      this.downloadUrl.includes(`${this.baseUrl}/agreement/creditAnalyst`)
    ) {
      // for agreement
      docName = 'Agreement';
    } else if (this.downloadUrl.includes(`${this.baseUrl}/termsheet`)) {
      this.activatedRoute.url.subscribe((url) => {
        if (url[0].path === 'analysis') {
          docName = `${this.downloadUrl
            .split('?')
            .pop()
            .split('=')
            .pop()}_Termsheet`;
        } else {
          docName = this.downloadUrl.split('?').pop().split('=').pop();
        }
        // else {
        //   docName = this.downloadUrl.split('?').pop().split('=').pop()
        // }
      });
    } else if (this.downloadUrl.includes('/document/')) {
      docName =
        this.downloadUrl.split('/')[this.downloadUrl.split('/').length - 1];
      // docName = 'Crime Check Report.pdf';
    } else if (this.downloadUrl.includes('downloadPDFCrimeCheck')) {
      docName = 'Crime Check Report.pdf';
    } else if (this.downloadUrl.includes('downloadPDFCrimeCheck')) {
      docName = 'Crime_Check_Report';
    } else if (this.downloadUrl.includes('/downloadBankStatement')) {
      const urlParams = new URLSearchParams(this.downloadUrl);
      docName = urlParams.get('filename');
    } else if (this.downloadUrl.includes('/collectionStatements')) {
      const urlParams = new URLSearchParams(this.downloadUrl);
      docName = urlParams.get('filename');
    } else if (this.downloadUrl.includes('/recoveryData')) {
      const urlParams = new URLSearchParams(this.downloadUrl);
      docName = urlParams.get('filename');
    } else if (this.downloadUrl.includes('/ledgerData')) {
      const urlParams = new URLSearchParams(this.downloadUrl);
      docName = urlParams.get('filename');
    } else if (this.downloadUrl.includes('/ledgerDataForCollections')) {
      const urlParams = new URLSearchParams(this.downloadUrl);
      docName = urlParams.get('filename');
    } else if (this.downloadUrl.includes(`ITR`)) {
      docName = 'ITR_Report';
    } else if (this.downloadUrl.includes(`GSTPDF`)) {
      docName = 'Company GST Report PDF';
    } else if (this.downloadUrl.includes(`GSTEXCEL`)) {
      docName = 'Company GST Report EXCEL';
    } else if (this.downloadUrl.includes(`${this.baseUrl}/companies/downloadVendorFile`)) {
      let file=this.downloadUrl.split('/').pop()
      docName = file.slice(file.indexOf('_'));
    }else if (this.downloadUrl.includes(`${this.baseUrl}/companies`)) {
      docName = 'BankStatement';
    } else if (this.downloadUrl.includes('/varianceCsv')) {
      docName = 'Past12MonthsVariance';
    } else if (this.downloadUrl.includes(`${this.baseUrl}/recovery/RecoveryUser/downloadCollectionRecords`)) {
      docName = `Liability_Records_${+(new Date())}`;
    } else if (this.downloadUrl.includes(`${this.baseUrl}/recovery`)) {
      docName = 'Recovery Data';
    } else if (this.downloadUrl.includes(`creditAnalysis`)) {
      docName = this.downloadUrl.split('?').pop().split('=').pop() + '.xlsx';
    } else if (this.downloadUrl.includes(`cibil`)) {
      docName = this.downloadUrl.split('?').pop().split('=').pop() + '.pdf';
    } else if (this.downloadUrl.includes(`crimecheck`)) {
      docName = this.downloadUrl.split('?').pop().split('=').pop() + '.pdf';
    } else if (this.downloadUrl.includes('proposalDownloadAdditionalFiles')) {
      docName = new URLSearchParams(this.downloadUrl).get('fileName');
    } else if (this.downloadUrl.includes('downloadFulfillmentReciept')) {
      docName = 'Fulfillment Receipt.pdf';
    } else if (this.downloadUrl.includes('getFPRepayments')) {
      docName = 'Fp_Repayments.csv';
    } else if (this.downloadUrl.includes('downloadPDFRPS')) {
      docName = 'Repayment Schedule.pdf';
    } else if (this.downloadUrl.includes('generateFTLRPS')) {
      docName = 'Repayment Schedule.xlsx';
    } else if (this.downloadUrl.includes('generateRBFRPS')) {
      docName = 'Repayment Schedule.xlsx';
    } else if (this.downloadUrl.includes('generateDropLineRPS')) {
      docName = 'Repayment Schedule.xlsx';
    } else if (this.downloadUrl.includes('recoveryDetails')) {
      docName = 'Recovery_deal_info.csv';
    } else if (this.downloadUrl.includes('generateSOA')) {
      docName = 'Statement of Accounts';
    } else if(this.downloadUrl.includes('downloadPortfolioReport')){
      docName = this.downloadUrl.split('?').pop().split('=').pop();
      if (!docName) {
        docName = 'PortfolioCuts.xlsx'
      } else {
        docName = `${docName}_PortfolioCuts.xlsx`
      }
    }
    else {
      // for others
      docName = this.downloadUrl.split('document/').pop().split('/')[1];
    }

    this.filename = `${docName}`;
    console.log("check filename:", this.filename)
    // Download the document as a blob
    const response = await this.httpClient
      .get(this.downloadUrl, { responseType: 'blob', observe: 'response' })
      .toPromise()
      .catch((error) => {
        this.loader.stop();
        Swal.fire({
          title: 'Error!',
          text: `Document not found.`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
        console.log(error);
        return error;
      });
    // console.log('🧦🧦🧦🧦', response);
    // if (response.statusCode && response.statusCode === '101') {
    //   Swal.fire({
    //     title: 'Error!',
    //     text: `Document not found.`,
    //     icon: 'error',
    //     confirmButtonText: 'OK',
    //   });
    //   console.log(response);
    //   return;
    // }
    // Create a URL for the blob
    const url = URL.createObjectURL(response.body);

    // Create an anchor element to "point" to it
    const anchor = document.createElement('a');
    anchor.href = url;

    // Get the suggested filename for the file from the response headers

    anchor.download = `${this.filename}`;

    // Simulate a click on our anchor element
    anchor.click();

    // Discard the object data
    URL.revokeObjectURL(url);
    this.loader.stop();
  }

  private getFilenameFromHeaders(headers: HttpHeaders) {
    // The content-disposition header should include a suggested filename for the file
    const contentDisposition = headers.get('Content-Disposition');
    if (!contentDisposition) {
      return null;
    }

    /* StackOverflow is full of RegEx-es for parsing the content-disposition header,
     * but that's overkill for my purposes, since I have a known back-end with
     * predictable behaviour. I can afford to assume that the content-disposition
     * header looks like the example in the docs
     * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
     *
     * In other words, it'll be something like this:
     *    Content-Disposition: attachment; filename="filename.ext"
     *
     * I probably should allow for single and double quotes (or no quotes) around
     * the filename. I don't need to worry about character-encoding since all of
     * the filenames I generate on the server side should be vanilla ASCII.
     */

    const leadIn = 'filename=';
    const start = contentDisposition.search(leadIn);
    if (start < 0) {
      return null;
    }

    // Get the 'value' after the filename= part (which may be enclosed in quotes)
    const value = contentDisposition.substring(start + leadIn.length).trim();
    if (value.length === 0) {
      return null;
    }

    // If it's not quoted, we can return the whole thing
    const firstCharacter = value[0];
    if (firstCharacter !== '"' && firstCharacter !== "'") {
      return value;
    }

    // If it's quoted, it must have a matching end-quote
    if (value.length < 2) {
      return null;
    }

    // The end-quote must match the opening quote
    const lastCharacter = value[value.length - 1];
    if (lastCharacter !== firstCharacter) {
      return null;
    }

    // Return the content of the quotes
    return value.substring(1, value.length - 1);
  }
}
