import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MISResponse } from "../models/mis.interface";

import { Config } from '../config';
const baseURL = new Config().getBaseURL();

@Injectable({
    providedIn: 'root',
})
export class MISService {
    constructor(private http: HttpClient) { }

    getMISCount$(): Observable<MISResponse> {
        var financeType = 'RBF'
        return this.http.get<MISResponse>(`${baseURL}/applications/mis/misCount?financeType=${financeType}`)
    }

    getMISData$(misType: string, page: number, count: number, company?, fromDate?, toDate?, status?): Observable<MISResponse> {
        var financeType = 'RBF'
        return this.http.get<MISResponse>(`${baseURL}/applications/mis/misDetails/${misType}`, {
            params: {
                page: page.toString(),
                count: count.toString(),
                company: company || '',
                fromDate: fromDate || '',
                toDate: toDate || '',
                status: status || '',
                financeType: financeType
            }
        })
    }

    getDealMIS$(page: number, count: number, company?, fromDate?, toDate?, status?): Observable<MISResponse> {
        return this.http.get<MISResponse>(`${baseURL}/applications/mis/misTAT`, {
            params: {
                page: page.toString(),
                count: count.toString(),
                company: company || '',
                fromDate: fromDate || '',
                toDate: toDate || '',
                status: status || '',
                financeType: 'RBF'
            }
        })
    }

    getDealsComapny():Observable<any>{
        // return this.http.get<any>(`${baseURL}/companies/allCompanies`)
        return this.http.get<any>(`${baseURL}/deal/mis/dealsCompany`)
    }

    getAlphaDash$(filterBy:string, filterValue:string):Observable<MISResponse>{
        return this.http.post<MISResponse>(`${baseURL}/deal/mis/alphaDashboard`,{filterBy,filterValue})
    }

    getDisbursements$(page:any, count:any, payload:any):Observable<MISResponse>{
        return this.http.post<MISResponse>(`${baseURL}/deal/mis/alphaDashboardDetails`,payload,{params:{page,count}})
    }

    getPrincipleOutstanding$(page:any, count:any, payload:any):Observable<MISResponse>{
        return this.http.post<any>(`${baseURL}/deal/mis/principalOutstanding`,payload,{ params:{page,count}})
    }

    getDealSummary$(page:string, count:string, payload:any):Observable<MISResponse>{
        return this.http.post<MISResponse>(`${baseURL}/deal/mis/dealSummeryMIS`, payload,{params:{page,count}})
    }
}
