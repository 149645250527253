import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchDealsEFApplications } from "../actions/deals.action";
import { ApplicationService } from "../../services/application.service";
import { updateState } from "../../utils/immutableUpdate";
import { tap } from 'rxjs/operators';

/* state model */
export class DealEFStateModel {
    isApplicationsLoaded:   boolean
    efApplicationsList :    any[]
    applicationsCurrentPage:            number
    applicationsTotalPages:             number
    applicationsSearchedKey:            string
    
    isApplicationTranchesLoaded:   boolean
    applicationTranches :   any[]
    trancheCurrentPage:     number
    trancheTotalPages:      number
    totalTranches:          number

    isApplicationLedgerLoaded:   boolean
    applicationLedger :   any[]
    ledgerCurrentPage:     number
    ledgerTotalPages:      number
}

/* initial state */
@State<DealEFStateModel>({
    name: 'dealEfState',
    defaults: {
        isApplicationsLoaded: false,
        efApplicationsList:   [],
        applicationsCurrentPage:    0,
        applicationsTotalPages:     0,
        applicationsSearchedKey:    '',

        isApplicationTranchesLoaded: false,
        applicationTranches:   [],
        trancheCurrentPage:    0,
        trancheTotalPages:     0,
        totalTranches:         0,

        isApplicationLedgerLoaded: false,
        applicationLedger:   [],
        ledgerCurrentPage:    0,
        ledgerTotalPages:     0,
    },
})
@Injectable()
export class DealsEfState {
    constructor( private _appService: ApplicationService ) {}

    @Selector()
    static isApplicationsLoaded(state: DealEFStateModel) {
        return state.isApplicationsLoaded
    }

    @Selector()
    static applicationDetails(state: DealEFStateModel) {
        return {
            applications: state.efApplicationsList,
            currentPage: state.applicationsCurrentPage,
            totalPages: state.applicationsTotalPages,
            searchedApplication: state.applicationsSearchedKey
        }
    }

    @Action(FetchDealsEFApplications)
    fetchApplications({ getState, setState }: StateContext<DealEFStateModel>, { payload }: FetchDealsEFApplications) {
        const state = getState();
        const { page, company, status, role, count, addedThrough, startDate, endDate, dateType, referredBy } = payload
        return this._appService.
        getRoleWiseApplications( page, status, role, company, count, addedThrough, 'EF', startDate, endDate, dateType, referredBy ).pipe(
            tap(data => {
                (data['statusCode'] === "100")
                    && updateState(state, {
                        isApplicationsLoaded: true,
                        efApplicationsList: data['data']['applications'],
                        applicationsTotalPages: Math.ceil(data['data']['count'] / 10),
                        applicationsCurrentPage: page,
                        applicationsSearchedKey: company
                    }, setState)
                    || updateState(state, this.resetEfApplicationsList(), setState)
            },
                error => updateState(state, this.resetEfApplicationsList(), setState))
        )
    }

     /* =====================================helpers=============================== */
    resetEfApplicationsList(){
        return {
            isApplicationsLoaded: false,
            efApplicationsList: [],
            currentPage: 1,
            totalPages: 1,
            searchedKey: ''
        }
    }

    resetTranches(){
        return {
            isApplicationTranchesLoaded: false,
            applicationTranches: [],
            trancheTotalPages: 1,
            trancheCurrentPage: 1
        }
    }

    resetLedger(){
        return {
            isApplicationLedgerLoaded: false,
            applicationLedger: [],
            ledgerTotalPages: 1,
            ledgerCurrentPage: 1
        }
    }

}
