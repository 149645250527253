import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApproveOrRejectAnalysis, FetchAnalysisForIC } from '../../actions/ic.actions';
import { tap } from 'rxjs/operators';
import { IcService } from '../../../services/ic.service';
import { number } from '@amcharts/amcharts4/core';
import { updateState } from 'src/app/utils/immutableUpdate';

/* state model */
export class ICAnalysisStateModel {
  analysis: [];
  analysisLoaded: boolean;
  currentPage: string;
  status: string;
  totalPages: number
  analysisCount: number
  isAnalysisSentBack: boolean
}

/* initial state */
@State<ICAnalysisStateModel>({
  name: 'icAnalysis',
  defaults: {
    analysis: [],
    analysisLoaded: false,
    currentPage: '0',
    status: 'Completed',
    analysisCount: 0,
    totalPages: 0,
    isAnalysisSentBack: false
  },
})
@Injectable()
export class IcAnalysisState {
  constructor(private _icService: IcService ) {}

  /* return analysis from state */
    @Selector()
    static analysisForIC( state: ICAnalysisStateModel ){
        return state
    }

    @Selector()
    static isAnalysisLoaded( state: ICAnalysisStateModel ){
        return state.analysisLoaded
    }

    @Selector()
    static isAnalysisSentBack( state: ICAnalysisStateModel ){
        return state.isAnalysisSentBack
    }
    
    /* fetch analysis pendingAnd set it to state */
    @Action(FetchAnalysisForIC)
    analysis({getState, setState}: StateContext<ICAnalysisStateModel>, {payload}: FetchAnalysisForIC){
        const state = getState();
        return this._icService.fetchAnalysisForIC( payload )
        .pipe(tap(data=>{
            ( data['statusCode']==100 ) && 
                updateState( state, this.analysisSuccess( data, payload ), setState ) ||
                updateState( state, this.analysisFailure( payload ), setState ) 
        },
        error=> updateState( state, this.analysisFailure( payload ), setState ) 
        ));
    }

    @Action(ApproveOrRejectAnalysis)
    approveOrRejectAnalysis({getState, setState}: StateContext<ICAnalysisStateModel>, {payload}: ApproveOrRejectAnalysis){
        const state = getState();
        let { analysisId, companyId, applicationId, status, comment } = payload;
        return this._icService.approveOrRejectAnalysis( analysisId, { status, comment, companyId, applicationId } )
        .pipe(tap(data=>{
          ( data['statusCode']==100 ) && 
            updateState( state, { isAnalysisSentBack: true, analysisLoaded: false }, setState ) ||
            updateState( state, { isAnalysisSentBack: false }, setState ) 
        },
        error=> updateState( state, { isAnalysisSentBack: false }, setState ) 
        ));
    }

    /* helpers */
    analysisSuccess( data, { status, page, count } ){
        return{ 
          analysis: data['data']['creditAnalysis'], 
          analysisLoaded: true, 
          currentPage: page, 
          status: status, 
          analysisCount: data['data']['count'],
          totalPages: Math.ceil( data['data']['count']/count )
        }
    }

    analysisFailure({ status, page }){
        return{ 
          analysis: [], 
          analysisLoaded: false, 
          currentPage: page, 
          status: status, 
          analysisCount: 0, 
          totalPages: 0 
        }
    }

}
