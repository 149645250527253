import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  UpdateState,
} from '@ngxs/store';
import { FetchMISCount, FetchMISData } from '../actions/mis.action';
import { MISService } from '../../services/mis.service';
import { MISCount } from '../../models/misCount.model'
import { updateState } from '../../utils/immutableUpdate';
import { tap } from 'rxjs/operators';
import { LoDashExplicitNillableArrayWrapper } from 'lodash';

/* state model */
export class MIS_StateModel {
    isCountLoaded: boolean
    isTotalSingUpsLoaded: boolean
    isApplicationsLoaded: boolean

    misCounts: MISCount
    totalSignUps: Array<any>
    totalCompletedApplications: Array<any>
    totalNotStartedApplications: Array<any>
    totalPartialApplications: Array<any>
}

/* initial state */
@State<MIS_StateModel>({
    name: 'misState',
    defaults: {
        isCountLoaded: false,
        isTotalSingUpsLoaded: false,
        isApplicationsLoaded: false,
        
        misCounts: null,
        totalSignUps: [],
        totalCompletedApplications: [],
        totalNotStartedApplications: [],
        totalPartialApplications: []
    },
})
@Injectable()
export class MIS_State {

    constructor( private misService: MISService ){}

    @Selector()
    static isMISCountLoaded( state: MIS_StateModel ){
        return state.isCountLoaded
    }

    @Selector()
    static misCounts( state: MIS_StateModel ){
        return state.misCounts
    }

    @Action(FetchMISCount)
    loadDealSummary({getState, setState}: StateContext<MIS_StateModel>){
        const state = getState();
        return this.misService.getMISCount$().pipe(
            tap(data=>{
                (data.statusCode === "100")
                && updateState( state, { isCountLoaded: true, misCounts: data['data'] }, setState )
                || updateState( state, { isCountLoaded: false, misCounts: null }, setState )
            },
            error=> updateState( state, { isCountLoaded: false, misCounts: null }, setState ))
        )
    }


    @Selector()
    static isTotalSignUpsLoaded( state: MIS_StateModel ){
        return state.isTotalSingUpsLoaded
    }

    @Selector()
    static totalSignUps( state: MIS_StateModel ){
        return state.totalSignUps
    }

    @Selector()
    static isApplicationsLoaded( state: MIS_StateModel ){
        return state.isApplicationsLoaded
    }

    @Selector()
    static totalApplications( state: MIS_StateModel ){
        return{
            completedApplications: state.totalCompletedApplications,
            notStartedApplications: state.totalNotStartedApplications,
            partialApplications: state.totalPartialApplications
        }
    }

    @Action(FetchMISData)
    getTotalSignUps({getState, setState}: StateContext<MIS_StateModel>, {misType}:FetchMISData){
        const state = getState();
        // return this.misService.getMISData$( misType ).pipe(
        //     tap(data=>{

        //         if( misType === 'signups' ){
        //             (data.statusCode === "100")
        //             && updateState( state, { isTotalSingUpsLoaded: true, totalSignUps: data['data']['data'] }, setState )
        //             || updateState( state, { isTotalSingUpsLoaded: false, totalSignUps: [] }, setState )
        //         }
        //         else if( misType === 'application' ){
        //             (data.statusCode === "100")
        //             && updateState( state, 
        //                 { 
        //                     isApplicationsLoaded: true, 
        //                     totalCompletedApplications: data['data']['totalCompletedApplications'], 
        //                     totalNotStartedApplications: data['data']['totalNotStartedApplications'], 
        //                     totalPartialApplications: data['data']['totalPartialApplications'] 
        //                 }, setState )
        //             || updateState( state, 
        //                 { 
        //                     isApplicationsLoaded: false, 
        //                     totalCompletedApplications: [], 
        //                     totalNotStartedApplications: [], 
        //                     totalPartialApplications: [] 
        //                 }, setState 
        //             )
        //         }

        //     },
        //     error=>{
        //         if( misType === 'signups' ){
        //             updateState( state, { isTotalSingUpsLoaded: false, totalSignUps: [] }, setState )
        //         }
        //         else if( misType === 'application' ){
        //             updateState( state, 
        //                 { 
        //                     isApplicationsLoaded: false, 
        //                     totalCompletedApplications: [], 
        //                     totalNotStartedApplications: [], 
        //                     totalPartialApplications: [] 
        //                 }, setState 
        //             )
        //         }
        //     })
        // )
    }
    
}