
/* 
  HELPER
*/

import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";

  /* function to immutably update state by getting state, setState and properties to be updated in State */
  export const updateState = (oldObject, updatedProperties, setState) => {
    return setState({
      ...oldObject,
      ...updatedProperties,
    });
  }

  @Injectable({
    providedIn: 'root'
  })
  export class DateTransform{
    constructor( private datepipe: DatePipe ){}

    public transform( date, format ){
      return this.datepipe.transform(date, format)
    }
    
  }