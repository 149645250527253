import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthYear',
})
export class MonthYearPipe implements PipeTransform {
  transform(value: string): string {
    const [month, year] = value.split('-');
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const monthIndex = parseInt(month, 10) - 1;
    const monthName = monthNames[monthIndex] || '';

    return `${monthName}-${year}`;
  }
}
