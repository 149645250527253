import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AnalysisData } from '../../../models/analysisData.model';
import { CreditAnalystService } from '../../../services/credit-analyst.service';
import { FetchRevenues, ResetOnDifferentApplication } from '../../actions/credit.action';
import { tap } from 'rxjs/operators';
import { updateState } from 'src/app/utils/immutableUpdate';

/* state model */
export class RevenueStateModel {
  revenueDataLoaded: boolean;

  selectedCompanyForRevenue: string;
  revenueData: {
    totalDigitalRevenues: AnalysisData[];
    totalNonDigitalRevenues: AnalysisData[];
    totalOr: AnalysisData[];
    totalIncome: AnalysisData[];
    otherIncome: AnalysisData[];
    gstAnalysis: AnalysisData[];
    razorpayRevenue: AnalysisData[];
    cashfreeRevenue: AnalysisData[];
    payuRevenue: AnalysisData[];
    instamojoRevenue: AnalysisData[];
    totalPgAc: AnalysisData[];
    ROAS: AnalysisData[];
  };
}

/* initial state */
@State<RevenueStateModel>({
  name: 'revenueState',
  defaults: {
    selectedCompanyForRevenue: null,
    revenueData: {
      totalDigitalRevenues: [],
      totalNonDigitalRevenues: [],
      totalOr: [],
      totalIncome: [],
      otherIncome: [],
      gstAnalysis: [],
      razorpayRevenue: [],
      cashfreeRevenue: [],
      payuRevenue: [],
      instamojoRevenue: [],
      totalPgAc: [],
      ROAS: [],
    },
    revenueDataLoaded: false,
  },
})
@Injectable()
export class RevenueState {
  constructor(private _creditService: CreditAnalystService) {}

  /* get analysis data from state */
  @Selector()
  static getRevenues(state: RevenueStateModel) {
    return state;
  }

  @Selector()
  static isRevenueLoaded(state: RevenueStateModel) {
    return state.revenueDataLoaded;
  }

  @Selector()
  static selectedCompany(state: RevenueStateModel) {
    return state.selectedCompanyForRevenue;
  }

  @Action(ResetOnDifferentApplication)
  resetOnDifferentApplication({getState, setState}: StateContext<RevenueStateModel>)
  {
    const state = getState();
    updateState(state, { revenueDataLoaded: false }, setState)
  }

  @Action(FetchRevenues)
  fetchRevenues(
    { getState, setState }: StateContext<RevenueStateModel>,
    { id }: FetchRevenues
    ) {
    const state = getState();
    return this._creditService.fetchAnalysisData(id).pipe(
      tap(
        (data) => {
          if (data['statusCode'] == 100) {
            let bank = data['data']['bankAnalysis'],
              gst = data['data']['gstr_analysis'],
              pg = data['data']['revenueAccountsAnalysis'];
            updateState( state, this.fetchRevenueSuccess( bank, gst, pg, id ), setState )
          } 
          else updateState( state, this.fetchRevenueFailure( id ), setState )
        },
        (error) => updateState( state, this.fetchRevenueFailure( id ), setState )
      )
    );
  }

  fetchRevenueSuccess( bank, gst, pg, id ){
    return{
      revenueData: {
        totalDigitalRevenues: bank['totaldigitalrevenues'],
        totalNonDigitalRevenues: bank['totalnondigitalrevenues'],
        otherIncome: bank['otherinc'],
        totalIncome: bank['totalincome'],
        totalOr: bank['totalor'],
        gstAnalysis: gst || [],
        cashfreeRevenue: pg['cashfree'] || [],
        instamojoRevenue: pg['instamojo'] || [],
        payuRevenue: pg['payu'] || [],
        razorpayRevenue: pg['razorpay'] || [],
        totalPgAc: pg['total_pg_ac'] || [],
        ROAS: pg['analysis_ROAS_pg_ad_ac'] || [],
      },
      revenueDataLoaded: true,
      selectedCompanyForRevenue: id
    }    
  }

  fetchRevenueFailure( id ) {
    return{
      revenueData: {
        totalDigitalRevenues: [],
        totalNonDigitalRevenues: [],
        totalOr: [],
        totalIncome: [],
        otherIncome: [],
        gstAnalysis: [],
        razorpayRevenue: [],
        cashfreeRevenue: [],
        payuRevenue: [],
        instamojoRevenue: [],
        totalPgAc: [],
        ROAS: [],
      },
      revenueDataLoaded: false,
      selectedCompanyForRevenue: id
    }
  }
}
