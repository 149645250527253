<div class="container-fluid">
    <div class="d-grid main">
      <div class="logo p-10">
        <img
          src="assets/img/logo_withgv (2).png"
          alt="GetVantage Tech Private Limited"
          loading="lazy"
          width="200px"
        />
        <!-- <img src="../../../assets/img/instagram icon.png" width="40px" class="f-right" loading="lazy" alt="GetVantage Tech"> -->
      </div>
      <div class="d-grid parts">
        <div class="left">
          <img src="../../../assets/img/Finance app_Flatline.svg" style="border-right: 1px solid #ddd;" class="mt-20" loading="lazy" alt="left banner" />
        </div>
        <div class="right">
          <form (ngSubmit)="onSubmit()">
            <h2 class="mb-20">Authentication Code :(</h2>
            <div class="input-fields">
              <div class="field field1 d-grid">
                <div class="input-logo">
                  <img
                    src="../../../assets/img/lock(24x24)@2x.png"
                    width="80%"
                    loading="lazy"
                    alt="authCode"
                  />
                </div>
                <input
                  type="number"
                  placeholder="Auth"
                  name="authCode"
                  autofocus
                  [(ngModel)]="authCode"
                />
              </div>
            </div>
            <button type="submit" class="primary-btn ripple">
              Verify
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  