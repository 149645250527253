import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '../../config'
import { AddVendorLimitPayload } from "../../models/opsEF.model";
import { ESUnderWriting } from "../../models/esUnderWriting.model"

const baseURL = new Config().getBaseURL()
@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  openUrl: string = `${baseURL}/applications/openAPI`
  openUrlUsers: string = `${baseURL}/users/openAPI`
  roleWiseUrl: string = `${baseURL}/applications`
  constructor(private http: HttpClient) { }

  getRoleWiseApplications(page, status: any, role: any, companyName: string = '', count, addedThrough) {
    count = +count;
    var financeType = 'EF'
    var params = {};
    status
      && (params = { page, role, company: companyName, count, status, addedThrough, financeType })
      || (params = { page, role, company: companyName, count, addedThrough, financeType })
    return this.http.get(`${this.roleWiseUrl}/details`, { params: params });

  }

  getAppId(applicationId): Observable<any> {
    return this.http.get(`${this.openUrl}/${applicationId}/appDetails`)
  }

  getEfApplicationCount(): Observable<any> {
    var financeType = 'EF'
    return this.http.get(`${this.openUrl}/count_by_status?financeType=${financeType}`);
  }
  getAllEfApplications(page: number, status: any, companyName: string = ''): Observable<any> {
    var financeType = 'EF'
    return this.http.get(`${this.openUrl}/list?status=${status}&company=${companyName}&page=${page}&financeType=${financeType}`);
  }

  getAllEfApplicationbyAnalystId(page: number, status: any, companyName: string = '', analystId: any): Observable<any> {
    var params = {}
    var financeType = 'EF'
    return this.http.post(`${this.roleWiseUrl}/Manager/${analystId}/analystApplications?status=${status}&company=${companyName}&page=${page}&financeType=${financeType}`, params);
  }

  fetchEfApplicationsOps({ page, company, status }): Observable<any> {
    var financeType = 'EF'
    return this.http.post(`${baseURL}/applications/Operations/applications/listing`, { company, status, financeType }, { params: { page, count: '10' } })
  }

  fetchEfApplicationTranches({ applicationId, page, status, fromDate, toDate }): Observable<any> {
    return this.http.get(`${baseURL}/tranches/tranch/${applicationId}/tranches`, { params: { page, status, fromDate, toDate } })
  }

  fetchEfLedgerByType({ id, type, page }): Observable<any> {
    let ledgersByType = type === 'tranche' ? 'ledgers' : 'ledgersByApplication'
    return this.http.get(`${baseURL}/tranches/ledger/${id}/${ledgersByType}`, { params: { page } })
  }

  uploadFulfillmentReceipt({ id, file }): Observable<any> {
    return this.http.post(`${baseURL}/tranches/tranch/${id}/uploadFulfillmentReciept`, file)
  }

  addTranche(data): Observable<any> {
    return this.http.post(`${baseURL}/tranches/tranch/newTranch`, data)
  }

  updateTrancheStatus(data): Observable<any> {
    return this.http.post(`${baseURL}/tranches/approver/updateStatus`, data)
  }


  // Deals ef API

  fetchEfApplicationsDeals({ page, company, status }): Observable<any> {
    var financeType = 'EF'
    return this.http.get(`${baseURL}/applications/Operations/applications/listing`, { params: { page, company, status, financeType } })
  }

  fetchEfApplicationTranchesDeals({ applicationId, page, status, fromDate, toDate }): Observable<any> {
    return this.http.get(`${baseURL}/tranches/${applicationId}/tranches`, { params: { page, status, fromDate, toDate } })
  }

  fetchEfLedgerByTypeDeals({ id, type, page }): Observable<any> {
    let ledgersByType = type === 'tranche' ? 'ledgers' : 'ledgersByApplication'
    return this.http.get(`${baseURL}/tranches/ledger/${id}/${ledgersByType}`, { params: { page } })
  }

  updateVendorLimit(data: AddVendorLimitPayload, companyId: string): Observable<any> {
    return this.http.post(`${baseURL}/tranches/DealAnalyst/${companyId}/updateVendorLimit`, data)
  }

  addCreditAnalysis(data: ESUnderWriting, companyId: String, applicationId: String): Observable<any> {
    return this.http.post(`${baseURL}/partnerService/partnerAnalysis/creditAnalysis/${companyId}/${applicationId}/addCreditAnalysis`, data)
  }

  getCreditAnalysis(applicationId: String): Observable<any> {
    return this.http.get(`${baseURL}/partnerService/partnerAnalysis/creditAnalysis/${applicationId}/getCreditAnalysis`)
  }

  addDisbursementRequest(data): Observable<any> {
    return this.http.post(`${baseURL}/tranches/disbursement/addDisbursementReq`, data)
  }

  addBulkDisbursementRequest({ file }): Observable<any> {
    return this.http.post(`${baseURL}/tranches/disbursement/addDisbursementReqFile`, file)
  }
}
