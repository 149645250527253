import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SwalPopupService {
  constructor() {}

  // warning function
  warning(message: string) {
    Swal.fire({
      icon: 'warning',
      title: 'Warning!',
      text: message,
      confirmButtonText: 'OK',
    });
  }

  success(message: string, callback?: any) {
    Swal.fire({
      title: 'Success!',
      text: `${message}`,
      icon: 'success',
      confirmButtonText: 'OK',
    }).then(() => { callback && callback(); });
  }

  error(message?: string) {
    Swal.fire({
      title: 'Error!',
      text: `${message || 'Something went wrong!'}`,
      icon: 'error',
      confirmButtonText: 'OK',
    });
  }
}
