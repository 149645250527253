import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

import { Config } from '../config';
const baseURL = new Config().getBaseURL();

@Injectable({
  providedIn: 'root'
})
export class KudosService {

  constructor(private http: HttpClient) { }

  /* ====GET APIS==== */
  getLoanRequest(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/loanRequest`
    );
  }

  updateLoanRequest(companyId, applicationId, params): Observable<any> {
    return this.http.post(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/updateLoanRequest`, params
    );
  }

  getLoanResponse(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/loanResponse`
    );
  }

  getBorrowerRequest(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/borrowerRequest`
    );
  }

  updateBorrowerRequest(companyId, applicationId, params): Observable<any> {
    return this.http.post(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/updateBorrowerRequest`, params
    );
  }

  getBorrowerResponse(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/borrowerResponse`
    );
  }

  getPnachRequest(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/pnachRequest`
    );
  }

  getPnachResponse(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/pnachResponse`
    );
  }

  genPnachReq(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/generatePnachRequest`
    );
  }

  submitPnach(params): Observable<any> {
    return this.http.post(
      `${baseURL}/kudos/OpsExecutive/submitPnachRequest`, params
    );
  }

  getLoanDocs(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/loanDocuments`
    );
  }

  getLoanDocbyId(id): Observable<Blob> {
    var param = {}
    var authorization = 'Bearer ' + localStorage.getItem("token");
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Authorization": authorization, responseType: 'blob'
    });

    return this.http.post<Blob>(`${baseURL}/kudos/OpsExecutive/${id}/downloadLoanDocument`, param, {
      headers: headers, responseType:
        'blob' as 'json'
    });
  }

  uploadLoanDoc(file: File, companyId, applicationId, docType, fileExten): Observable<any> {
    var params = {}
    const formdata: FormData = new FormData();
    formdata.append('file', file)
    return this.http.post(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/loanDocument/${docType}?_f=${fileExten}`, formdata
    );
  }

  reUploadLoanDoc(file: File, _id, docType: any, fileExten): Observable<any> {
    var params = {}
    const formdata: FormData = new FormData();
    formdata.append('file', file)
    return this.http.post(
      `${baseURL}/kudos/OpsExecutive/${_id}/${docType}/updateLoanDocumentRequest?_f=${fileExten}`, formdata
    );
  }

  submitLoanDocReq(companyId, applicationId, params): Observable<any> {
    return this.http.post(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/submitLoanDocument`, params
    );
  }

  submitNachDebit(companyId, applicationId, params): Observable<any> {
    return this.http.post(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/debitPnaach`, params
    );
  }

  NachDebitList(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/debitPnaach`
    );
  }

  genKudosLoanReq(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/genKudosLoanRequest`
    );
  }

  submitLoanRequest(companyId, applicationId, params): Observable<any> {
    return this.http.post(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/submitKudosLoanRequest`, params
    );
  }

  genKudosBorrowerReq(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/genKudosBorrowerRequest`
    );
  }

  submitBorrowerRequest(companyId, applicationId, params): Observable<any> {
    return this.http.post(
      `${baseURL}/kudos/OpsExecutive/${companyId}/${applicationId}/submitKudosBorrowerRequest`, params
    );
  }

  getPnachForm(_id): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${_id}/downloadPnachDocument`
    );
  }

  export(_id: string) {
    return this.http.get(`${baseURL}/kudos/OpsExecutive/${_id}/downloadPnachDocument`,
      { responseType: 'blob' });
  }


  getCompanyDetails(companyId: any): Observable<any> {
    return this.http.get(
      `${baseURL}/kudos/OpsExecutive/${companyId}/companyDetails`
    );

  }




}
