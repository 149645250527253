import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core"
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { tap } from "rxjs/operators";
import { updateState } from "../../../utils/immutableUpdate";
import { ApplicationsService } from "../../../services/embedded-finance/applications.service";
import { FetchOpsEFApplicationLedger, FetchOpsEFApplications, FetchOpsEFApplicationTranches } from "../../actions/ops.action";

export class OpsEFDashboardStateModel {
    isApplicationsLoaded:   boolean
    efApplicationsList :    any[]
    applicationsCurrentPage:            number
    applicationsTotalPages:             number
    applicationsSearchedKey:            string
    
    isApplicationTranchesLoaded:   boolean
    applicationTranches :   any[]
    trancheCurrentPage:     number
    trancheTotalPages:      number
    totalTranches:          number

    isApplicationLedgerLoaded:   boolean
    applicationLedger :   any[]
    ledgerCurrentPage:     number
    ledgerTotalPages:      number
}

@State<OpsEFDashboardStateModel>({
    name: 'opsEFDashboardState',
    defaults: {
        isApplicationsLoaded: false,
        efApplicationsList:   [],
        applicationsCurrentPage:    0,
        applicationsTotalPages:     0,
        applicationsSearchedKey:    '',

        isApplicationTranchesLoaded: false,
        applicationTranches:   [],
        trancheCurrentPage:    0,
        trancheTotalPages:     0,
        totalTranches:         0,

        isApplicationLedgerLoaded: false,
        applicationLedger:   [],
        ledgerCurrentPage:    0,
        ledgerTotalPages:     0,
    }
})

@Injectable()
export class OpsEFDashboardState {
    constructor( private _opsEfService: ApplicationsService ){}

    @Selector()
    static isApplicationsLoaded(state: OpsEFDashboardStateModel) {
        return state.isApplicationsLoaded
    }

    @Selector()
    static applicationDetails(state: OpsEFDashboardStateModel) {
        return {
            applications: state.efApplicationsList,
            currentPage: state.applicationsCurrentPage,
            totalPages: state.applicationsTotalPages,
            searchedApplication: state.applicationsSearchedKey
        }
    }

    @Action(FetchOpsEFApplications)
    fetchApplications({ getState, setState }: StateContext<OpsEFDashboardStateModel>, { payload }: FetchOpsEFApplications) {
        const state = getState();
        const { page, company } = payload
        return this._opsEfService.fetchEfApplicationsOps(payload).pipe(
            tap(data => {
                (data.statusCode === "100")
                    && updateState(state, {
                        isApplicationsLoaded: true,
                        efApplicationsList: data['data']['applications'],
                        applicationsTotalPages: Math.ceil(data['data']['count'] / 10),
                        applicationsCurrentPage: page,
                        applicationsSearchedKey: company
                    }, setState)
                    || updateState(state, this.resetEfApplicationsList(), setState)
            },
                error => updateState(state, this.resetEfApplicationsList(), setState))
        )
    }

    @Selector()
    static isApplicationTranchesLoaded(state: OpsEFDashboardStateModel) {
        return state.isApplicationTranchesLoaded
    }

    @Selector()
    static tranches(state: OpsEFDashboardStateModel) {
        return {
            tranches: state.applicationTranches,
            currentPage: state.trancheCurrentPage,
            totalPages: state.trancheTotalPages,
            totalTranches: state.totalTranches
        }
    }

    @Action(FetchOpsEFApplicationTranches)
    fetchTranches({ getState, setState }: StateContext<OpsEFDashboardStateModel>, { payload }: FetchOpsEFApplicationTranches) {
        const state = getState();
        const { page } = payload
        return this._opsEfService.fetchEfApplicationTranches(payload).pipe(
            tap(data => {
                (data.statusCode === "100")
                    && updateState(state, {
                        isApplicationTranchesLoaded: true,
                        applicationTranches: data['data']['Tranches'],
                        trancheTotalPages: Math.ceil(data['data']['totalCount'] / 10),
                        totalTranches: data['data']['totalCount'],
                        trancheCurrentPage: page
                    }, setState)
                    || updateState(state, this.resetTranches(), setState)
            },
                error => updateState(state, this.resetTranches(), setState))
        )
    }


    @Selector()
    static isApplicationLedgerLoaded(state: OpsEFDashboardStateModel) {
        return state.isApplicationLedgerLoaded
    }

    @Selector()
    static ledger(state: OpsEFDashboardStateModel) {
        return {
            ledgers: state.applicationLedger,
            currentPage: state.ledgerCurrentPage,
            totalPages: state.ledgerTotalPages
        }
    }

    @Action(FetchOpsEFApplicationLedger)
    fetchLedger({ getState, setState }: StateContext<OpsEFDashboardStateModel>, { payload }: FetchOpsEFApplicationLedger) {
        const state = getState();
        const { page } = payload
        return this._opsEfService.fetchEfLedgerByType(payload).pipe(
            tap(data => {
                console.log('data from api',data);
                (data.statusCode === "100")
                    && updateState(state, {
                        isApplicationLedgerLoaded: true,
                        applicationLedger: data['data']['Ledgers'],
                        ledgerTotalPages: Math.ceil(data['data']['totalCount'] / 10),
                        ledgerCurrentPage: page
                    }, setState)
                    || updateState(state, this.resetLedger(), setState)
            },
                error => updateState(state, this.resetLedger(), setState))
        )
    }


    /* =====================================helpers=============================== */
    resetEfApplicationsList(){
        return {
            isApplicationsLoaded: false,
            efApplicationsList: [],
            currentPage: 1,
            totalPages: 1,
            searchedKey: ''
        }
    }

    resetTranches(){
        return {
            isApplicationTranchesLoaded: false,
            applicationTranches: [],
            trancheTotalPages: 1,
            trancheCurrentPage: 1
        }
    }

    resetLedger(){
        return {
            isApplicationLedgerLoaded: false,
            applicationLedger: [],
            ledgerTotalPages: 1,
            ledgerCurrentPage: 1
        }
    }
}