/* Fetch all analysis that is sent to IC with status, eg : status = pending, all pending analysis */
export class FetchAnalysisForIC{
    static readonly type = '[AnalysisForIC] GET'
    constructor( public payload: any ){}
}

/* approve or reject any analysis under IC with analysis id */
export class ApproveOrRejectAnalysis{
    static readonly type = '[ApproveOrRejectAnalysis] POST'
    constructor( public payload: any ){}
}