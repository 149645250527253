import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Config } from '../config'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const baseURL = new Config().getBaseURL()

@Injectable({
  providedIn: 'root'
})
export class IcService {

  constructor(private http: HttpClient) { }

  fetchAnalysisForIC({ status, page, count }): Observable<any> {
    return this.http.get(`${baseURL}/company/IC/creditAnalysis/list`, {
      params: {
        status, page, count
      }
    })
  }

  approveOrRejectAnalysis(analysisId, data): Observable<any> {
    return this.http.post(`${baseURL}/company/IC/${analysisId}/updateAnalysisStatus`, data)
  }

  fetchPDListForIC(page, companyName: string = '', status: any, count): Observable<any> {
    var financeType = 'RBF'
    return this.http.get(`${baseURL}/applications/IC/applicationsByProposalDocument?page=${page}&count=${count}&status=${status}&company=${companyName}&financeType=${financeType}`)
  }

  fetchLeadsForIC(page, status: any, companyName: string = '', count, createdBy, referredBy = '', leadSource = '',
  startDate, endDate, dateType , download ): Observable<any> {
    count = +count;
    var financeType = 'RBF'
    var body = {};
    status
      && (body = { company: companyName, status, referredBy, leadSource, financeType,  startDate, endDate, dateType, createdBy ,download  })
      || (body = { company: companyName, referredBy, leadSource, financeType, startDate, endDate, dateType, createdBy, download })
    return this.http.post(`${baseURL}/applications/IC/detailsForIC`, body, { params: { page, count } });

  }

  fetchLeadsForReport(status: any, companyName: string = '') {
    var params = {};
    var financeType = 'RBF'
    status
      && (params = { role: 'IC', company: companyName, status, financeType })
      || (params = { role: 'IC', company: companyName, financeType })
    return this.http.get(`${baseURL}/applications/IC/applicationsExportForIC`, { params: params });
  }



}
