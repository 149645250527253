import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Config } from '../config';
import { Socket } from 'ngx-socket-io';

export type FilterOptions = {
  months: string;
  startDate: string;
  endDate: string;
};

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  user = {
    name: new BehaviorSubject(''),
    role: new BehaviorSubject(''),
  };

  baseURL = new Config().getBaseURL();

  GSTUpdateRequest = this.socket.fromEvent<any>('GSTUpdateRequest');

  constructor(private http: HttpClient, private socket: Socket) {
    // this.GSTUpdateRequest = this.socket.fromEvent<any>("GSTUpdateRequest");
  }

  /* ====================company api======================= */
  fetchCompanies({ page, company = '' }): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/list`, {
      params: { page, company },
    });
  }

  fetchCompanyDetails(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/details`);
  }

  fetchAppCompanyDetails(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/application`);
  }

  fetchUserDetails(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/user`);
  }
  fetchAllDealAnalyst(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/application1`);
  }

  /* ====================company api end======================= */

  /* ====================bank api======================= */
  fetchBankAccountsByCompany(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/bankaccounts`);
  }

  updatedBankAccount(bankAccountId: string, data: object): Observable<any> {
    return this.http.put(
      `${this.baseURL}/companies/updatedBankAccounts/${bankAccountId}`,
      data
    );
  }
  /* ====================bank api end======================= */

  /* ====================bank api======================= */
  downloadProcessedBankReport(
    companyId: string,
    accountId: string
  ): Observable<any> {
    return this.http.get(
      `${this.baseURL}/company/bank/${companyId}/excelReport?accountId=${accountId}`
    );
  }
  /* ====================bank api end======================= */

  /* ====================pg api======================= */
  fetchPgAccountsByCompany(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/pgaccounts`);
  }

  fetchPgTotal(
    companyId: string,
    { startDate, endDate, months }
  ): Observable<any> {
    let daily = 'false';
    return this.http.get(`${this.baseURL}/companies/${companyId}/pg`, {
      params: {
        startDate,
        endDate,
        months,
        daily,
      },
    });
  }
  fetchpgOndemandSettlement(
    companyId: string,
    { startDate, endDate, months },
    page,
    count,
    download
  ) {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/pgOndemanddata`,
      {
        params: {
          startDate,
          endDate,
          months,
          page,
          count,
          download,
        },
      }
    );
  }
  fetchpgOndemandSettlementMonthlyBasis(
    companyId: string,
    { startDate, endDate, months },
    page,
    count,
    download,
    status
  ) {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/pgOndemandDataMonthlyBasis`,
      {
        params: {
          startDate,
          endDate,
          months,
          page,
          status,
          count,
          download,
        },
      }
    );
  }
  fetchPgDetailedData(
    companyId: string,
    { startDate, endDate, months },
    page,
    count
  ): Observable<any> {
    let daily = 'false';
    return this.http.get(`${this.baseURL}/companies/${companyId}/pgdata`, {
      params: {
        startDate,
        endDate,
        months,
        page,
        count,
        daily,
      },
    });
  }

  fetchPgGraphData(
    companyId: string,
    filterOptions: FilterOptions
  ): Observable<any> {
    const { months, startDate, endDate } = filterOptions;
    return this.http.get(`${this.baseURL}/companies/${companyId}/pgGraph`, {
      params: {
        months,
        startDate,
        endDate,
      },
    });
  }
  /* ====================pg api end======================= */

  /* ====================assets and obligations api======================= */
  fetchAssetsByCompanyId(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/assets`);
  }

  fetchObligationsByCompanyId(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/obligations`);
  }
  /* ====================assets and obligations api end======================= */

  /* ====================gst and itr api======================= */
  fetchGSTsByCompanyId(
    companyId: string,
    page: number,
    count: number
  ): Observable<any> {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/gsts?page=${page}&count=${count}`
    );
  }

  /* ==========fetch and update gst data=========== */
  updateGSTData(id): Observable<any> {
    var data = { id };
    return this.http.put(`${this.baseURL}/companies/gst`, data);
  }

  /* ==========fetch gst reports from karza=========== */
  downloadProcessedGSTKarzaReport(
    companyId: string,
    gstinId: string
  ): Observable<any> {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/gstReports?gstinId=${gstinId}`
    );
  }

  /* ==========fetch and update gst data=========== */
  removeCredentialsOfUserGST(id): Observable<any> {
    var data = { id };
    return this.http.put(`${this.baseURL}/companies/gst/status`, data);
  }

  fetchItrByCompanyId(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/itr`);
  }
  /* ====================gst and itr api end======================= */

  /* ===============marketing apis============= */
  fetchAdAccountsByCompany(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/adaccounts`);
  }

  fetchMarketingTotal(
    companyId: string,
    { startDate, endDate, months }
  ): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/marketing`, {
      params: {
        startDate,
        endDate,
        months,
      },
    });
  }

  fetchMarketingDetailedData(
    companyId: string,
    { startDate, endDate, months },
    page,
    count,
    d = 'false'
  ): Observable<any> {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/marketingdata`,
      {
        params: {
          startDate,
          endDate,
          months,
          page,
          count,
          d,
        },
      }
    );
  }

  fetchMarketingGraph(
    companyId: string,
    filterOptions: FilterOptions
  ): Observable<any> {
    const { months, startDate, endDate } = filterOptions;
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/marketingGraph`,
      {
        params: {
          months,
          startDate,
          endDate,
        },
      }
    );
  }
  /* ===============marketing apis end============= */

  /* ===============documents apis============= */
  fetchAllDocumentsForCompany(companyId): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/documents`);
  }

  fetchAllDocumentsForCompanY(companyId, document = '', page): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/document`, {
      params: { page, document },
    });
  }

  documentUpload(file, data: any, companyId: string): Observable<any> {
    return this.http.post(
      `${this.baseURL}/companies/${companyId}/uploadDocument`,
      file,
      { params: data }
    );
  }

  deleteDocument(companyId, documentId): Observable<any> {
    return this.http.delete(
      `${this.baseURL}/companies/${companyId}/document/${documentId}`
    );
  }
  /* ===============documents apis end============= */

  /* ===============report error api============= */
  submitTicket({ companyId, applicationId, bodyData }): Observable<any> {
    return this.http.post(
      `${this.baseURL}/support/${companyId}/${applicationId}/sendtoTech`,
      bodyData
    );
  }

  /* ===============report error api============= */
  uploadAdditionalSupportFiles(companyId, applicationId, data: FormData) {
    return this.http.post(
      `${this.baseURL}/support/${companyId}/${applicationId}/supportAttachments/supportingDocs`,
      data
    );
  }
  /* ===============report error api end============= */

  /* ===============report error api============= */
  fetchStatementsToDownload({ companyId, accountId }): Observable<any> {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/bankStatementPdf?accountId=${accountId}`
    );
  }

  DownloadITR(url): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(
      `${this.baseURL}/companies/downloadFileFromAWS?url=${url}`,
      { headers: headers, responseType: 'blob' }
    );
  }
  /* ===============report error api end============= */

  runMasterSanction(companyID): Observable<any> {
    return this.http.post(
      `${this.baseURL}/company/dealAnalyst/masterSanction`,
      {
        comp_id: companyID,
      }
    );
  }
  /* ===============parsing api============= */

  BankParsing(companyId: string, data): Observable<any> {
    return this.http.post(
      `${this.baseURL}/companies/${companyId}/parsing`,
      data
    );
  }

  /* ===============parsing api end============= */
  uploadSettlementDataForPG({
    pgAccountId,
    companyId,
    pgType,
    file,
  }): Observable<any> {
    return this.http.post(
      `${this.baseURL}/settlements/dealAnalyst/${companyId}/${pgAccountId}/addPGSettlement`,
      file,
      { params: { pgType } }
    );
  }

  allowApplicationCreation(companyId: string): Observable<any> {
    return this.http.get(
      `${this.baseURL}/company/dealManager/${companyId}/applicationCreation`
    );
  }

  createApplication(companyId: string): Observable<any> {
    return this.http.post(
      `${this.baseURL}/applications/dm-da/${companyId}/nextApplication`,
      {}
    );
  }

  isCompanyAccountsConnected(companyId: string): Observable<any> {
    return this.http.get(
      `${this.baseURL}/company/all/${companyId}/accountsConnected`
    );
  }

  sendAddCategoryRequestToCompany({ companyId, category }): Observable<any> {
    return this.http.post(
      `${this.baseURL}/company/dealsAnalyst/${companyId}/updateCategory`,
      { category }
    );
  }

  uploadBrandLogo(file): Observable<any> {
    return this.http.post(
      `${this.baseURL}/company/opsExecutive/uploadCompanyLogo`,
      file
    );
  }

  addBrandDetail({ data, companyId }): Observable<any> {
    return this.http.post(
      `${this.baseURL}/company/opsExecutive/${companyId}/addCompanyDetails`,
      data
    );
  }

  updateCompanyDetail(data: object, companyId: string): Observable<any> {
    return this.http.post(
      `${this.baseURL}/companies/${companyId}/updateCompanyData`,
      data
    );
  }

  getBrandDetail(companyId: string): Observable<any> {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/getCompanyDetails`
    );
  }

  getBrandDetails(companyId: string): Observable<any> {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/getComDetails`
    );
  }

  getBankStatementsAsPerAccount(_id: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${_id}/bankStatements`);
  }

  isCompanyKycDoneStatus(companyId: string): Observable<any> {
    return this.http.get(
      `${this.baseURL}/applications/Operations/${companyId}/kycDocumentStatus`
    );
  }

  validateIfsc(ifsc: string): Observable<any> {
    return this.http.post(`${this.baseURL}/company/bank/validateIFSC`, {
      ifscCode: ifsc,
    });
  }

  uploadBankStatement(file: FormData, companyId: string): Observable<any> {
    return this.http.post(
      `${this.baseURL}/company/bank/${companyId}/uploadBankStatement`,
      file
    );
  }

  uploadAssetDocument(file: FormData, companyId: string): Observable<any> {
    return this.http.post(
      `${this.baseURL}/company/asset/${companyId}/uploadAssetDocument`,
      file
    );
  }

  uploadObligationDocument(file: FormData, companyId: string): Observable<any> {
    return this.http.post(
      `${this.baseURL}/company/obligation/${companyId}/uploadObligationDocument`,
      file
    );
  }

  addAsset(payload: object, companyId: string): Observable<any> {
    return this.http.post(
      `${this.baseURL}/company/asset/${companyId}/addAsset`,
      payload
    );
  }

  addObligation(payload: object, companyId: string): Observable<any> {
    return this.http.post(
      `${this.baseURL}/company/obligation/${companyId}/addObligation`,
      payload
    );
  }

  uploadGstReport(file: FormData, companyId: string): Observable<any> {
    return this.http.post(
      `${this.baseURL}/company/dealAnalyst/${companyId}/gstParsing/uploads`,
      file
    );
  }

  addBankStatement(data: any, companyId: string): Observable<any> {
    return this.http.post(
      `${this.baseURL}/company/bank/${companyId}/addBankStatement`,
      data
    );
  }

  uploadFulfillmentStatement(file, companyId: string): Observable<any> {
    return this.http.post(
      `${this.baseURL}/tranches/DealAnalyst/${companyId}/uploadFulfillmentStatement`,
      file
    );
  }

  addFulfillmentStatement(data: any): Observable<any> {
    return this.http.post(
      `${this.baseURL}/tranches/DealAnalyst/addFulfillmentStatement`,
      data
    );
  }

  getFulfillmentStatement({ companyId, page, count }): Observable<any> {
    return this.http.get(
      `${this.baseURL}/tranches/DealAnalyst/${companyId}/fulfillmentStatement`,
      { params: { page, count } }
    );
  }

  getTokenForAdYogi(companyId: any): Observable<any> {
    return this.http.get(
      `${this.baseURL}/adyogi/${companyId}/adYogiTokenGenerate`
    );
  }

  fetchPgForecastGraphData(companyId: string): Observable<any> {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/pgForecastGraph`
    );
  }
  fetchPgTrendGraphData(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/pgTrendGraph`);
  }
  fetchPgSeasonalityGraphData(companyId: string): Observable<any> {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/pgSeasonalityGraph`
    );
  }
  fetchPgIrregularityGraphData(companyId: string): Observable<any> {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/pgIrregularityGraph`
    );
  }

  uploadVendorDoc(file: FormData, companyId) {
    return this.http.post(
      `${this.baseURL}/companies/${companyId}/uploadVendorDoc`,
      file
    );
  }

  getVendorDetails(companyId) {
    return this.http.get(
      `${this.baseURL}/companies/${companyId}/getVendorDetails`
    );
  }

  addVendorDetails(companyId, payload) {
    return this.http.post(
      `${this.baseURL}/companies/${companyId}/AddVendorDetails`,
      payload
    );
  }

  fetchCompanyAppsCrimeCheck(companyId: string): Observable<any> {
    return this.http.get(`${this.baseURL}/companies/${companyId}/apps`);
  }

  /* ===============start upload marketing data api============= */

  sendMarketingData(companyId, payload) {
    return this.http.post(`${this.baseURL}/companies/${companyId}/sendMarketingData`,payload)
  }

  uploadMarketingData(file: FormData, companyId: string): Observable<any> {
    return this.http.post(`${this.baseURL}/companies/${companyId}/uploadMarketingData`,file);
  }

  /* ===============end upload marketing data api============= */

  /* ===============start Add PG accounts data api============= */

  addPgAccounts(companyId, payload) {
    return this.http.post(`${this.baseURL}/companies/${companyId}/addPgAccounts`,payload)
  }
  /* ===============end Add PG accounts data api============= */
}
