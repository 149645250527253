import { Injectable } from "@angular/core"
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from "rxjs/operators";
import { RepaymentsService } from "../../../services/repayments.service";
import { FetchB2BRepayments, FetchDailyRepayments, FetchFeeDisbursements, FetchYBLStatements, FetchKotakStatements, FetchVaAccountList } from "../../actions/ops.action";
import { updateState } from "../../../utils/immutableUpdate";
import { B2BRepayment, B2BRepaymentResponse, DailyRepayment, DailyRepaymentResponse, FeeDisbursement, FeeDisbursementResponse, YblStatementResponse, YblStatement } from "../../../models/yesBank.model";
import { KotakStatement, KotakStatementDataResponse } from "src/app/models/kotakBank.model";

export class RepaymentsStateModel {
    isB2BRepaymentsLoaded: boolean;
    isDailyRepaymentsLoaded: boolean;
    isFeeDisbursementsLoaded: boolean;
    isYblStatementsLoaded: boolean;

    b2bRepayments: B2BRepayment[];
    totalPages: number;
    currentPage: number;
    searchedKey: string

    dailyRepayments: DailyRepayment[];
    totalDailyPages: number;
    currentDailyPage: number;
    searchedDailyKey: string

    feeDisbursements: FeeDisbursement[];
    totalFeeDisbursementPages: number;
    currentFeeDisbursementPage: number;
    searchedFeeDisbursementKey: string

    yblStatements: YblStatement[];
    totalStatementPages: number;
    currentStatementPage: number;
    searchedStatementKey: string;

    isvaAccountLoaded:boolean;
    vaAccountList:Array<any>;

    //kotal bank statements 
    isKotakStatementsLoaded     : boolean   ;
    kotakStatements             : Array<KotakStatement> ;
    kotaktotalPages             : number    ;
    kotakcurrentPage            : number    ;
    kotakFilters                :{
                                    accountNo: string   ;
                                    startDate: string   ;
                                    endDate  : string   ;
                                    type     : string   ;
                                 }
}

/* ========================================Initial State======================================== */
@State<RepaymentsStateModel>({
    name: 'repaymentState',
    defaults: {
        isB2BRepaymentsLoaded: false,
        b2bRepayments: [],
        totalPages: 0,
        currentPage: 1,
        searchedKey: '',

        isDailyRepaymentsLoaded: false,
        dailyRepayments: [],
        totalDailyPages: 0,
        currentDailyPage: 1,
        searchedDailyKey: '',

        isFeeDisbursementsLoaded: false,
        feeDisbursements: [],
        totalFeeDisbursementPages: 0,
        currentFeeDisbursementPage: 1,
        searchedFeeDisbursementKey: '',

        isYblStatementsLoaded: false,
        yblStatements: [],
        totalStatementPages: 0,
        currentStatementPage: 1,
        searchedStatementKey: '',


        isvaAccountLoaded: false                 ,
        vaAccountList: []                        ,

        isKotakStatementsLoaded     : false   ,
        kotakStatements             : []      ,
        kotaktotalPages             : 0       ,
        kotakcurrentPage            : 0       ,
        kotakFilters                :{
                                        accountNo: ''   ,
                                        startDate: ''   ,
                                        endDate  : ''   ,
                                        type     : ''   ,
                                     }
    }
})

@Injectable()
export class RepaymentsState {
    constructor(private _repayments: RepaymentsService) { }

    /* ========================================B2B Repayments======================================== */
    @Selector()
    static isB2BRepaymentsLoaded(state: RepaymentsStateModel) {
        return state.isB2BRepaymentsLoaded
    }

    @Selector()
    static b2bRepaymentsData(state: RepaymentsStateModel) {
        return {
            b2bRepayments: state.b2bRepayments,
            totalPages: state.totalPages,
            currentPage: state.currentPage,
            searchedKey: state.searchedKey
        }
    }

    @Action(FetchB2BRepayments)
    getB2BRepayments({ getState, setState }: StateContext<RepaymentsStateModel>, { payload }: FetchB2BRepayments) {
        const state = getState();
        return this._repayments.b2bRepaymentsList(payload).pipe(tap(
            (response: B2BRepaymentResponse) => {
                (response.statusCode === "100")
                    && updateState(state, {
                        isB2BRepaymentsLoaded: true,
                        b2bRepayments: response.data.data,
                        totalPages: Math.ceil(response.data.totalCount / payload.count),
                        currentPage: payload.page,
                        searchedKey: payload.company
                    }, setState)
                    || updateState(state, this.resetB2BRepayments(), setState)
            },
            error => updateState(state, this.resetB2BRepayments(), setState)
        ))
    }

    resetB2BRepayments() {
        return {
            isB2BRepaymentsLoaded: false,
            b2bRepayments: [],
            totalPages: 0,
            currentPage: 1,
            searchedKey: ''
        }
    }

    /* ========================================Daily Repayments======================================== */
    @Selector()
    static isDailyRepaymentsLoaded(state: RepaymentsStateModel) {
        return state.isDailyRepaymentsLoaded
    }

    @Selector()
    static dailyRepaymentsData(state: RepaymentsStateModel) {
        return {
            dailyRepayments: state.dailyRepayments,
            totalDailyPages: state.totalDailyPages,
            currentDailyPage: state.currentDailyPage,
            searchedDailyKey: state.searchedDailyKey
        }
    }

    @Action(FetchDailyRepayments)
    getDailyRepayments({ getState, setState }: StateContext<RepaymentsStateModel>, { payload }: FetchDailyRepayments) {
        const state = getState();
        return this._repayments.dailyRepaymentsList(payload).pipe(tap(
            (response: DailyRepaymentResponse) => {
                (response.statusCode === "100")
                    && updateState(state, {
                        isDailyRepaymentsLoaded: true,
                        dailyRepayments: response.data.data,
                        totalDailyPages: Math.ceil(response.data.totalCount / payload.count),
                        currentDailyPage: payload.page,
                        searchedDailyKey: payload.company
                    }, setState)
                    || updateState(state, this.resetDailyRepayments(), setState)
            },
            error => updateState(state, this.resetDailyRepayments(), setState)
        ))
    }

    resetDailyRepayments() {
        return {
            isDailyRepaymentsLoaded: false,
            dailyRepayments: [],
            totalDailyPages: 0,
            currentDailyPage: 1,
            searchedDailyKey: ''
        }
    }

    /* ========================================Fee Disbursements======================================== */
    @Selector()
    static isFeeDisbursementsLoaded(state: RepaymentsStateModel) {
        return state.isFeeDisbursementsLoaded
    }

    @Selector()
    static feeDisbursements(state: RepaymentsStateModel) {
        return {
            feeRepayments: state.feeDisbursements,
            totalFeeDisbursementPages: state.totalFeeDisbursementPages,
            currentFeeDisbursementPage: state.currentFeeDisbursementPage,
            searchedFeeDisbursementKey: state.searchedFeeDisbursementKey
        }
    }

    @Action(FetchFeeDisbursements)
    getFeeDisbursements({ getState, setState }: StateContext<RepaymentsStateModel>, { payload }: FetchFeeDisbursements) {
        const state = getState();
        return this._repayments.feeDisbursementsList(payload).pipe(tap(
            (response: FeeDisbursementResponse) => {
                (response.statusCode === "100")
                    && updateState(state, {
                        isFeeDisbursementsLoaded: true,
                        feeDisbursements: response.data.data,
                        totalFeeDisbursementPages: Math.ceil(response.data.totalCount / payload.count),
                        currentFeeDisbursementPage: payload.page,
                        searchedFeeDisbursementKey: payload.company
                    }, setState)
                    || updateState(state, this.resetFeeDisbursements(), setState)
            },
            error => updateState(state, this.resetFeeDisbursements(), setState)
        ))
    }

    resetFeeDisbursements() {
        return {
            isFeeDisbursementsLoaded: false,
            feeDisbursements: [],
            totalFeeDisbursementPages: 0,
            currentFeeDisbursementPage: 1,
            searchedFeeDisbursementKey: ''
        }
    }

    /* ========================================YBL Statements======================================== */
    @Selector()
    static isYblStatementsLoaded(state: RepaymentsStateModel) {
        return state.isYblStatementsLoaded
    }

    @Selector()
    static yblStatements(state: RepaymentsStateModel) {
        return {
            yblStatements: state.yblStatements,
            totalStatementPages: state.totalStatementPages,
            currentStatementPage: state.currentStatementPage,
            searchedStatementKey: state.searchedStatementKey
        }
    }

    @Action(FetchYBLStatements)
    getYblStatement({ getState, setState }: StateContext<RepaymentsStateModel>, { payload }: FetchYBLStatements) {
        const state = getState();
        return this._repayments.yblStatements(payload).pipe(tap(
            (response: YblStatementResponse) => {
                (response.statusCode === "100")
                    && updateState(state, {
                        isYblStatementsLoaded: true,
                        yblStatements: response.data.Data,
                        totalStatementPages: Math.ceil(response.data.totalCount / payload.count),
                        currentStatementPage: payload.page,
                        searchedStatementKey: payload.accountNo
                    }, setState)
                    || updateState(state, this.resetYblStatements(), setState)
            },
            error => updateState(state, this.resetYblStatements(), setState)
        ))
    }

    resetYblStatements() {
        return {
            isYblStatementssLoaded: false,
            yblStatements: [],
            totalStatementPages: 0,
            currentStatementPage: 1,
            searchedStatementKey: ''
        }
    }

    /* ========================================client vaNumber  List======================================== */
    @Selector()
    static isVaAccountListLoaded(state: RepaymentsStateModel) {
        return state.isvaAccountLoaded;
    }

    @Selector()
    static vaAccountList(state: RepaymentsStateModel) {
        return {
            vaAccountList: state.vaAccountList,
        }
    }

    @Action(FetchVaAccountList)
    getVaAccountList({ getState, setState }: StateContext<RepaymentsStateModel>, { payload }: FetchVaAccountList) {
        const state = getState();
        
        return this._repayments.VaAccountList().pipe(tap(
            (response: {
                statusCode: string;
                message:    string;
                data:       Array<any>;
            }) => {
                (response.statusCode === "100")
                && updateState(state, {
                    isvaAccountLoaded : true,
                    vaAccountList: response.data,
                    }, setState)
                    || updateState(state, { isvaAccountLoaded:false, vaAccountList:[] }, setState)
                },
                error => updateState(state, { isvaAccountLoaded:false, vaAccountList:[] }, setState)
                ))
            }
     

 
    /* ========================================KOTAK BANK Statements======================================== */
    @Selector()
    static iskotakStatementsLoaded(state: RepaymentsStateModel) {
        return state.isKotakStatementsLoaded
    }

    @Selector()
    static kotakStatements(state: RepaymentsStateModel) {
        return {
        kotakStatements             : state.kotakStatements       ,  
        kotaktotalPages             : state.kotaktotalPages       ,
        kotakcurrentPage            : state.kotakcurrentPage      ,
        kotakFilters                : state.kotakFilters
        }
    }

    @Action(FetchKotakStatements)
    getKotakStatement({ getState, setState }: StateContext<RepaymentsStateModel>, { payload }: FetchKotakStatements) {
        const state = getState();
        const fillter = { accountNo: payload.accountNo, type:payload.type, startDate: payload.startDate, endDate: payload.endDate }
        return this._repayments.kotakStatements(payload).pipe(tap(
            (response: KotakStatementDataResponse) => {
                if(response.statusCode === "100")
                    {
                        updateState(state, {
                            isKotakStatementsLoaded: true,
                            kotakStatements     : response.data.Data,
                            kotaktotalPages     : Math.ceil(response.data.totalCount / payload.count),
                            kotakcurrentPage    : payload.page,
                            kotakFilters        : fillter
                        }, setState)       
                    } else{
                        updateState(state, this.resetKotakStatements(fillter), setState)
                    }
            },
            error => updateState(state, this.resetKotakStatements(fillter), setState)
        ))
    }

    resetKotakStatements(fill?) {
        return {
            isKotakStatementsLoaded: false,
            kotakStatements: [],
            kotaktotalPages: 0,
            kotakcurrentPage: 1,
            kotakFilters: { accountNo:'', type:'', startDate:'', endDate:'', ...fill}
        }
    }           

}