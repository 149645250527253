import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'commaList'
})
export class CommaListPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}

  transform(input: any, ...args: any[]){
    if (input && args && args[0] === 'orderedList') {
      const output = input.split(/\n/).filter(ele => ele).map((ele, i) => `${i+1})&nbsp;${ele}`).join('<br />')
      return this.sanitizer.bypassSecurityTrustHtml(output)
    }
    return 'No Data Added.'
  }

}
