import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'amountToLakh'
})
export class AmountToLakhPipe implements PipeTransform {

    transform(input: any, args?: any): any {
      
        if( input == undefined || input == 0 ){
          return "₹ 0.00L"
        }
        else{
          return "₹"+ (input/ 100000).toFixed(args) + "L";
        }
    
      }

}