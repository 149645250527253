import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDateFormat]'
})
export class DateFormatDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    // Get the input value and remove any non-digit characters
    let inputValue = event.target.value.replace(/\D/g, '');

    // Check the length of the input value
    if (inputValue.length > 0) {
      // Format the date as 'MM/DD/YYYY'
      inputValue = this.formatDate(inputValue);
    }

    // Set the formatted value back to the input
    this.el.nativeElement.value = inputValue;
  }

  private formatDate(inputValue: string): string {
    const parts = [];
    
    // Format the month (MM)
    if (inputValue.length >= 2) {
      parts.push(inputValue.substring(0, 2));
      inputValue = inputValue.substring(2);
    }
    
    // Format the day (DD)
    if (inputValue.length >= 2) {
      parts.push(inputValue.substring(0, 2));
      inputValue = inputValue.substring(2);
    }
    
    // Format the year (YYYY)
    if (inputValue.length > 0) {
      parts.push(inputValue);
    }
    
    return parts.join('/');
  }

}
