import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CreditAnalystService } from '../../../../app/services/credit-analyst.service';
import {
  SetSavedAnalysisLoadedToFalse,
  FetchSavedAnalysis,
  FetchSavedTermsheets,
  SetTSLoadedToFalse,
  ResetOnDifferentApplication,
  FetchCreditStepsStatus,
  SetSelectedApplication,
  ChangeCreditStepStatus,
  FetchUsersListUnderRole
} from '../../actions/credit.action';
import { tap } from 'rxjs/operators';
import { updateState } from '../../../utils/immutableUpdate';
import { ApplicationService } from 'src/app/services/application.service';

export class AnalysisStateModel {
  isSavedAnalysisLoaded: boolean;
  isTermsheetsLoaded: boolean
  isStepStatusLoaded: boolean
  isIcLoaded: boolean

  stepStatus: object
  selectedApplication: string;
  savedAnalysis: Array<any>;
  savedTermsheets: Array<any>
  icUsers: Array<any>
}

@State<AnalysisStateModel>({
  name: 'analysisState',
  defaults: {
    isSavedAnalysisLoaded: false,
    isTermsheetsLoaded: false,
    isStepStatusLoaded: false,
    isIcLoaded: false,

    selectedApplication: null,
    stepStatus: null,
    savedAnalysis: [],
    savedTermsheets: [],
    icUsers: []
  },
})
@Injectable()
export class AnalysisState {
  constructor(private _creditService: CreditAnalystService, private _appService: ApplicationService) { }

  /* Selector to get selected application */
  @Selector()
  static getSelectedApplication(state: AnalysisStateModel) {
    return state.selectedApplication;
  }

  @Selector()
  static isSavedAnalysisLoaded(state: AnalysisStateModel) {
    return state.isSavedAnalysisLoaded;
  }

  /* Selector to get state */
  @Selector()
  static getSavedAnalysis(state: AnalysisStateModel) {
    return state;
  }

  /* get saved termsheets under application from state */
  @Selector()
  static getSavedTermsheets(state: AnalysisStateModel) {
    return state.savedTermsheets;
  }

  @Selector()
  static isTermsheetsLoaded(state: AnalysisStateModel) {
    return state.isTermsheetsLoaded;
  }

  @Selector()
  static getStepStatus(state: AnalysisStateModel) {
    return state.stepStatus;
  }

  @Selector()
  static isStepStatusLoaded(state: AnalysisStateModel) {
    return state.isStepStatusLoaded;
  }

  /* action to fetch analysis from network */
  @Action(FetchSavedAnalysis)
  fetchAnalysis(
    { getState, setState }: StateContext<AnalysisStateModel>,
    { id }: FetchSavedAnalysis
  ) {
    const state = getState();
    return this._creditService.fetchStoredAnalysis(id).pipe(
      tap(
        (data) => {
          (data['statusCode'] == 100 &&
            updateState(state, successState(data, id), setState)) ||
            updateState(state, failedState(id), setState);
        },
        (error) => updateState(state, failedState(id), setState)
      )
    );
  }

  @Action(SetSavedAnalysisLoadedToFalse)
  setSavedAnalysisLoadedToFalse({ getState, setState }: StateContext<AnalysisStateModel>) {
    const state = getState();
    updateState(state, { isSavedAnalysisLoaded: false }, setState)
  }

  @Action(SetSelectedApplication)
  setSelectedApplication({ getState, setState }: StateContext<AnalysisStateModel>, { id }: SetSelectedApplication) {
    const state = getState();
    updateState(state, { selectedApplication: id }, setState)
  }

  @Action(SetTSLoadedToFalse)
  setTSLoadedToFalse({ getState, setState }: StateContext<AnalysisStateModel>) {
    const state = getState();
    updateState(state, { isTermsheetsLoaded: false }, setState)
  }

  @Action(ResetOnDifferentApplication)
  resetOnDifferentApplication({ getState, setState }: StateContext<AnalysisStateModel>) {
    const state = getState();
    updateState(state, { isSavedAnalysisLoaded: false, isTermsheetsLoaded: false, isStepStatusLoaded: false }, setState)
  }


  @Action(FetchSavedTermsheets)
  fetchSavedTermsheets(
    { getState, setState }: StateContext<AnalysisStateModel>,
    { id }: FetchSavedTermsheets
  ) {
    const state = getState();
    return this._creditService.fetchSavedTermsheets(id)
      .pipe(tap(data => {
        (data.statusCode === "100") && updateState(state, { savedTermsheets: data['data'], isTermsheetsLoaded: true, isTermsheetSentForSigning: false }, setState)
          || updateState(state, { savedTermsheets: [], isTermsheetsLoaded: false, isTermsheetSentForSigning: false }, setState)
      },
        error => {
          updateState(state, { savedTermsheets: [], isTermsheetsLoaded: false, isTermsheetSentForSigning: false }, setState)
        }))
  }

  @Action(FetchCreditStepsStatus)
  fetchStepStatus(
    { getState, setState }: StateContext<AnalysisStateModel>,
    { id }: FetchCreditStepsStatus
  ) {
    const state = getState();
    return this._creditService.fetchCreditStepsStatus(id)
      .pipe(tap(data => {
        (data['statusCode'] == 100)
          && updateState(state, { isStepStatusLoaded: true, stepStatus: data['data'] }, setState)
          || updateState(state, { isStepStatusLoaded: false, stepStatus: null }, setState)
      },
        error => {
          updateState(state, { isStepStatusLoaded: false, stepStatus: null }, setState)
        }))
  }

  // @Action(ChangeCreditStepStatus)
  // changeCreditStepStatus(
  //   {getState, setState}: StateContext<AnalysisStateModel>,
  //   {payload}: ChangeCreditStepStatus
  //   )
  // {
  //   const { type, value } = payload
  //   const state = getState();
  //   const stepStatus = state.stepStatus
  //   const newStepStatus = {...stepStatus}
  //   newStepStatus[type] = value
  //   updateState( state, { stepStatus: newStepStatus }, setState )
  // }

  @Action(ChangeCreditStepStatus)
  changeCreditStepStatus(
    { getState, setState }: StateContext<AnalysisStateModel>
  ) {
    const state = getState();
    updateState(state, { isStepStatusLoaded: false }, setState)
  }



  @Selector()
  static icUsers(state: AnalysisStateModel) {
    return state.icUsers;
  }

  @Selector()
  static isICLoaded(state: AnalysisStateModel) {
    return state.isIcLoaded;
  }

  @Action(FetchUsersListUnderRole)
  fetchUsersUnderRole({
    getState,
    setState,
  }: StateContext<FetchUsersListUnderRole>, { role }: FetchUsersListUnderRole) {
    const state = getState();

    return this._appService.getUsers(role).pipe(tap(data => {

      if (data['statusCode'] == 100) {
        if (role == 'IC') {
          updateState(state, { isIcLoaded: true, icUsers: data['data']['data'] }, setState)
        }
      }
      else {
        updateState(state, { isIcLoaded: false, icUsers: [] }, setState)
      }

    },
      error => {
        updateState(state, { isIcLoaded: false, icUsers: [] }, setState)
      }))

  }

}

/* 
  HELPERS
*/

/* helper function to return object to update object function */
const failedState = (id) => {
  return {
    savedAnalysis: [],
    isSavedAnalysisLoaded: false
  };
},
  /* helper function to return object to update object function */
  successState = (data, id) => {
    return {
      savedAnalysis: data['data']['creditAnalysis'],
      isSavedAnalysisLoaded: true
    };
  };
