import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'trimContent'
})
export class TrimContent implements PipeTransform {

    transform(value: any) {
        if (value) {
            return value.trim();
        }
        return value;
    }

}