import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianAmountFormat'
})
export class IndianAmountFormatPipe implements PipeTransform {

  transform(input: any, args: any) {
    if (input === 'NA') return input
    input = +input
    if (input === null || input === undefined) return 'NA';
    return input && input.toLocaleString('en-IN', {minimumFractionDigits: 2}) || 0.00
  }

}
