import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CreditAnalystService } from '../../../../app/services/credit-analyst.service';
import { ApplicationService } from '../../../../app/services/application.service';
import {
  FetchCreditApplications,
  FetchUsersUnderRole,
} from '../../actions/credit.action';
import { tap } from 'rxjs/operators';
import { updateState } from 'src/app/utils/immutableUpdate';

/* state model */
export class CreditApplicationsStateModel {
  applications: [];
  applicationsLoaded: boolean;
  currentPage: string;
  status: string;
  addedThrough: string;
  roleName: string;
  companyName: string;
  applicationsCount: number;
  totalPagesCount: number;
  usersUnderRole: Array<{
    _id: string,
    name: string
  }>
}

/* initial state */
@State<CreditApplicationsStateModel>({
  name: 'creditApplications',
  defaults: {
    applications: null,
    applicationsLoaded: false,
    usersUnderRole: null,
    currentPage: '0',
    status: 'completed',
    addedThrough: 'User',
    roleName: '',
    companyName: '',
    applicationsCount: 0,
    totalPagesCount: 0,
  },
})
@Injectable()
export class CreditApplicationsState {
  constructor(private _appService: ApplicationService) { }

  /* get analysis data from state */
  @Selector()
  static getCreditApplications(state: CreditApplicationsStateModel) {
    return state;
  }

  /* get users under the role that is stored in state  */
  @Selector()
  static getUsersUnderRole(state: CreditApplicationsStateModel) {
    return state.usersUnderRole;
  }

  /* selector to check if applications are loaded or not */
  @Selector()
  static isApplicationsLoaded(state: CreditApplicationsStateModel) {
    return state.applicationsLoaded;
  }

  /* fetching and setting applications to state */
  @Action(FetchUsersUnderRole)
  fetchUsersUnderRole({
    getState,
    setState,
  }: StateContext<CreditApplicationsStateModel>) {
    const state = getState();
    return this._appService.getAnalysts().pipe(
      tap(
        (data) => {
          (data['statusCode'] == 100)
            && updateState(state, { usersUnderRole: data['data']['data']['dealAnalyst'] }, setState)
            || updateState(state, { usersUnderRole: [] }, setState)
        },
        (error) => updateState(state, { usersUnderRole: [] }, setState)
      )
    );
  }

  /* fetching and setting users under role to state */
  @Action(FetchCreditApplications)
  fetchCreditApplications(
    { getState, setState }: StateContext<CreditApplicationsStateModel>,
    { payload }: FetchCreditApplications
  ) {
    console.log('payload here', { payload })
    let { currentPage, roleName, companyName, status, count, addedThrough, startDate, endDate, referredBy, dateType } = payload;
    const state = getState();
    return this._appService
      .getRoleWiseApplications(currentPage, status, roleName, companyName, count, addedThrough, 'RBF', startDate, endDate, dateType, referredBy)
      .pipe(
        tap(
          (data) => {
            (data['statusCode'] == 100) &&
              updateState(state, this.fetchSuccess(data, payload), setState) ||
              updateState(state, this.fetchFailure(), setState)
          },
          (error) => updateState(state, this.fetchFailure(), setState)
        )
      );
  }

  fetchSuccess(data, { currentPage, roleName, companyName, status, count, addedThrough }) {
    return {
      applications: data['data']['applications'],
      applicationsCount: data['data']['count'],
      totalPagesCount: Math.ceil(data['data']['count'] / count),
      applicationsLoaded: true,
      currentPage,
      roleName,
      companyName,
      status,
      addedThrough
    }
  }

  fetchFailure() {
    return {
      applications: [],
      applicationsCount: 0,
      totalPagesCount: 0,
      applicationsLoaded: false,
      currentPage: 0,
      companyName: ''
    }
  }

}