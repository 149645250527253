import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RecoveryService } from '../../services/recovery.service';
import { updateState } from '../../utils/immutableUpdate';
import { tap } from 'rxjs/operators';
import {
  FetchClosedApplications,
  FetchPausedApplications,
  FetchUnderReviewApplications,
  FetchLedgerData,
  FetchDealCollections,
  FetchDealData,
  FetchDealsCumulativeData,
  FetchRecoveryData,
  FetchDisbursedApplications,
  FetchInRecoveryApplications,
  FetchMarketingMonthWiseSpends,
  FetchPGMonthWiseRevenue,
  RemoveObjectFromArray,
  SetClosedApplicationsLoadedToFalse,
  SetDisbursedApplicationsLoadedToFalse,
  SetInRecoveryApplicationsLoadedToFalse,
  SetSelectedDealId,
  ResetOnDifferentApplication,
  FetchInRecoveryAnalysis,
  FetchCollectionPeriod,
  FetchCollectionLiability,
  FetchAllDealApplications,
} from '../actions/recovery.actions';
import { CompanyService } from 'src/app/services/company.service';
import {
  CollectionLiabilityResponse,
  CollectionPeriod,
  CollectionPeriodResponse,
} from 'src/app/models/collectionLIability.model';
import { HttpErrorResponse } from '@angular/common/http';

/* state model */
export class RecoveryStateModel {
  isCumulativeDataLoaded: boolean;
  isDisbursedApplicationsLoaded: boolean;
  isInRecoveryApplicationsLoaded: boolean;
  isAllDealApplicationsLoaded: boolean;
  isInRecoveryAnalysisLoaded: boolean;
  isClosedApplicationsLoaded: boolean;
  isPausedApplicationsLoaded: boolean;
  isMarketingMonthWiseSpendsLoaded: boolean;
  isPGMonthWiseRevenueLoaded: boolean;
  isDealDataLoaded: boolean;
  isRecoveryDataLoaded: boolean;
  isLedgerDataLoaded: boolean;
  isDealCollectionsLoaded: boolean;
  isUnderReviewApplicationsLoaded: boolean;
  isCollectionPeriodsLoaded: boolean;
  isCollectionLiabilitiesLoaded: boolean;

  cumulativeData: any;
  disbursedApplications: Array<any>;
  disbursedTotalPages: number;
  inRecoveryApplications: Array<any>;
  AllDealApplications: Array<any>;
  inRecoveryAnalysis: Array<any>;
  underReviewApplications: Array<any>;
  inRecoveryTotalPages: number;
  inRecoveryAnalysisTotalPages: number;
  closedApplications: Array<any>;
  pausedApplications: Array<any>;
  closedTotalPages: number;
  pausedTotalPages: number;
  AllDealTotalPages: number;
  currentAllDealPage: number;
  marketingMonthWiseSpends: Array<any>;
  pgMonthWiseRevenue: Array<any>;
  ledgerData: Array<any>;
  totalLedgerPages: number;
  underReviewTotalPages: number;
  recoveryData: Array<any>;
  totalRecoveryPage: number;
  totalSpendPage: number;
  dealCollections: Array<any>;
  dealData: any;
  totalCollectionsPage: number;
  totalRecoveryCount: number;
  totalLedgerCount: number;
  totalCollectionsCount: number;
  selectedApplicationId: string;
  currentYetToStartPage: number;
  currentInRecoveryPage: number;
  currentInRecoveryAnalysisPage: number;
  currentClosedPage: number;
  currentPausedPage: number;
  currentUnderReviewPage: number;
  totalInRecoveryCount: number;
  collectionPeriods: CollectionPeriod[];
  collectionLiabilities: [];
  totalLiabilityPages: number;
}

/* initial state */
@State<RecoveryStateModel>({
  name: 'recoveryState',
  defaults: {
    totalInRecoveryCount: 0,
    isCumulativeDataLoaded: false,
    cumulativeData: {
      liveDeals: 0,
      dealsClosed: 0,
      totalFundIssued: 0,
      totalCollection: 0,
    },
    isDisbursedApplicationsLoaded: false,
    disbursedApplications: [],
    isInRecoveryApplicationsLoaded: false,
    inRecoveryApplications: [],
    isAllDealApplicationsLoaded: false,
    AllDealApplications: [],
    isClosedApplicationsLoaded: false,
    closedApplications: [],
    isPausedApplicationsLoaded: false,
    pausedApplications: [],
    isUnderReviewApplicationsLoaded: false,
    underReviewApplications: [],
    isMarketingMonthWiseSpendsLoaded: false,
    marketingMonthWiseSpends: [],
    isPGMonthWiseRevenueLoaded: false,
    pgMonthWiseRevenue: [],
    isDealDataLoaded: false,
    dealData: null,
    isDealCollectionsLoaded: false,
    dealCollections: [],
    isRecoveryDataLoaded: false,
    recoveryData: [],
    isLedgerDataLoaded: false,
    ledgerData: [],
    totalLedgerPages: 0,
    totalCollectionsPage: 0,
    totalSpendPage: 0,
    closedTotalPages: 0,
    pausedTotalPages: 0,
    AllDealTotalPages: 0,
    currentAllDealPage: 0,
    underReviewTotalPages: 0,
    disbursedTotalPages: 0,
    inRecoveryTotalPages: 0,
    inRecoveryAnalysisTotalPages: 0,
    totalRecoveryPage: 0,
    totalRecoveryCount: 0,
    totalLedgerCount: 0,
    totalCollectionsCount: 0,
    selectedApplicationId: '',
    currentYetToStartPage: 1,
    currentInRecoveryPage: 1,
    currentClosedPage: 1,
    currentPausedPage: 1,
    currentUnderReviewPage: 1,
    currentInRecoveryAnalysisPage: 1,
    inRecoveryAnalysis: [],
    isInRecoveryAnalysisLoaded: false,
    isCollectionPeriodsLoaded: false,
    collectionPeriods: [],
    isCollectionLiabilitiesLoaded: false,
    collectionLiabilities: [],
    totalLiabilityPages: 0,
  },
})
@Injectable()
export class RecoveryState {
  constructor(
    private _recService: RecoveryService,
    private _compService: CompanyService
  ) {}

  /* ================================cumulative data start============================== */
  @Selector()
  static cumulativeData(state: RecoveryStateModel) {
    return state.cumulativeData;
  }

  @Selector()
  static isCumulativeDataLoaded(state: RecoveryStateModel) {
    return state.isCumulativeDataLoaded;
  }

  @Action(FetchDealsCumulativeData)
  loadCumulativeData({ getState, setState }: StateContext<RecoveryStateModel>) {
    const state = getState();
    let all = 'all';
    return this._recService.fetchCumulativeData(all).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              { isCumulativeDataLoaded: true, cumulativeData: data['data'] },
              setState
            )) ||
            updateState(
              state,
              { isCumulativeDataLoaded: false, cumulativeData: null },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            { isCumulativeDataLoaded: false, cumulativeData: null },
            setState
          )
      )
    );
  }
  /* ================================cumulative data end============================== */

  /* ================================disbursed applications start============================== */
  @Selector()
  static disbursedApplications(state: RecoveryStateModel) {
    return {
      disbursedApplications: state.disbursedApplications,
      totalPages: state.disbursedTotalPages,
      currentPage: state.currentYetToStartPage,
    };
  }

  @Selector()
  static isDisbursedApplicationsLoaded(state: RecoveryStateModel) {
    return state.isDisbursedApplicationsLoaded;
  }

  @Action(FetchDisbursedApplications)
  loadDisbursedApplications(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchDisbursedApplications
  ) {
    const state = getState();
    const { count } = payload;
    return this._recService.fetchDealApplications(payload).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isDisbursedApplicationsLoaded: true,
                disbursedApplications: data['data']['deals'],
                disbursedTotalPages: Math.ceil(data['data']['count'] / count),
                currentYetToStartPage: payload.page,
              },
              setState
            )) ||
            updateState(
              state,
              {
                isDisbursedApplicationsLoaded: false,
                disbursedApplications: [],
                currentYetToStartPage: 1,
              },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            { isDisbursedApplicationsLoaded: false, disbursedApplications: [] },
            setState
          )
      )
    );
  }
  /* ================================disbursed applications start============================== */
  /* =================================All Applications Start ================================== */

  @Selector()
  static AllDealApplications(state: RecoveryStateModel) {
    return {
      AllDealApplications: state.AllDealApplications,
      totalPages: state.AllDealTotalPages,
      currentPage: state.currentAllDealPage,
    };
  }

  @Selector()
  static isAllDealApplicationsLoaded(state: RecoveryStateModel) {
    return state.isAllDealApplicationsLoaded;
  }

  @Action(FetchAllDealApplications)
  loadAllDealApplications(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchAllDealApplications
  ) {
    const state = getState();
    const { count } = payload;
    return this._recService.fetchDealApplications(payload).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isAllDealApplicationsLoaded: true,
                AllDealApplications: data['data']['deals'],
                AllDealTotalPages: Math.ceil(data['data']['count'] / count),
                currentAllDealPage: payload.page,
              },
              setState
            )) ||
            updateState(
              state,
              {
                isAllDealApplicationsLoaded: false,
                AllDealApplications: [],
                currentAllDealPage: 1,
              },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            { isAllDealApplicationsLoaded: false, AllDealApplications: [] },
            setState
          )
      )
    );
  }
  /* =================================All Application Stop ==================================* /
  /* ================================in recovery applications start============================== */
  @Selector()
  static inRecoveryApplications(state: RecoveryStateModel) {
    return {
      inRecoveryApplications: state.inRecoveryApplications,
      totalPages: state.inRecoveryTotalPages,
      currentPage: state.currentInRecoveryPage,
    };
  }

  @Selector()
  static isRecoveryApplicationsLoaded(state: RecoveryStateModel) {
    return state.isInRecoveryApplicationsLoaded;
  }

  @Action(FetchInRecoveryApplications)
  loadRecoveryApplications(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchInRecoveryApplications
  ) {
    const state = getState();
    const { count, page } = payload;
    return this._recService.fetchDealApplications(payload).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isInRecoveryApplicationsLoaded: true,
                inRecoveryApplications: data['data']['deals'],
                inRecoveryTotalPages: Math.ceil(data['data']['count'] / count),
                currentInRecoveryPage: page,
              },
              setState
            )) ||
            updateState(
              state,
              {
                isInRecoveryApplicationsLoaded: false,
                inRecoveryApplications: [],
                currentInRecoveryPage: 1,
              },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            {
              isInRecoveryApplicationsLoaded: false,
              inRecoveryApplications: [],
            },
            setState
          )
      )
    );
  }
  /* ================================in recovery applications start============================== */
  /* ================================in recovery analysis start============================== */
  @Selector()
  static inRecoveryAnalysis(state: RecoveryStateModel) {
    return {
      inRecoveryAnalysis: state.inRecoveryAnalysis,
      totalPages: state.inRecoveryAnalysisTotalPages,
      currentPage: state.currentInRecoveryAnalysisPage,
      totalCount: state.totalInRecoveryCount,
    };
  }

  @Selector()
  static isRecoveryAnalysisLoaded(state: RecoveryStateModel) {
    return state.isInRecoveryAnalysisLoaded;
  }

  @Action(FetchInRecoveryAnalysis)
  loadRecoveryAnalysis(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchInRecoveryAnalysis
  ) {
    const state = getState();
    const { count, page } = payload;
    return this._recService.fetchRecoveryAnalysis(payload).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isInRecoveryAnalysisLoaded: true,
                inRecoveryAnalysis: data['data']['data'],
                inRecoveryAnalysisTotalPages: Math.ceil(
                  data['data']['totalCount'] / count
                ),
                currentInRecoveryAnalysisPage: page,
                totalInRecoveryCount: data['data']['totalCount'],
              },
              setState
            )) ||
            updateState(
              state,
              {
                isInRecoveryAnalysisLoaded: false,
                inRecoveryAnalysis: [],
                currentInRecoveryAnalysisPage: 1,
              },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            {
              isInRecoveryAnalysisLoaded: false,
              inRecoveryAnalysis: [],
            },
            setState
          )
      )
    );
  }
  /* ================================in recovery analysis start============================== */

  /* ================================paused applications start============================== */
  @Selector()
  static pausedApplications(state: RecoveryStateModel) {
    return {
      pausedApplications: state.pausedApplications,
      totalPages: state.pausedTotalPages,
      currentPage: state.currentPausedPage,
    };
  }

  @Selector()
  static isPausedApplicationsLoaded(state: RecoveryStateModel) {
    return state.isPausedApplicationsLoaded;
  }

  @Action(FetchPausedApplications)
  loadPausedApplications(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchPausedApplications
  ) {
    const state = getState();
    const { count, page } = payload;
    return this._recService.fetchDealApplications(payload).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isPausedApplicationsLoaded: true,
                pausedApplications: data['data']['deals'],
                pausedTotalPages: Math.ceil(data['data']['count'] / count),
                currentPausedPage: page,
              },
              setState
            )) ||
            updateState(
              state,
              {
                isPausedApplicationsLoaded: false,
                pausedApplications: [],
                currentPausedPage: 1,
              },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            { isPausedApplicationsLoaded: false, pausedApplications: [] },
            setState
          )
      )
    );
  }
  /* ================================paused applications end============================== */

  /* ================================closed applications start============================== */
  @Selector()
  static closedApplications(state: RecoveryStateModel) {
    return {
      closedApplications: state.closedApplications,
      totalPages: state.closedTotalPages,
      currentPage: state.currentClosedPage,
    };
  }

  @Selector()
  static isClosedApplicationsLoaded(state: RecoveryStateModel) {
    return state.isClosedApplicationsLoaded;
  }

  @Action(FetchClosedApplications)
  loadClosedApplications(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchClosedApplications
  ) {
    const state = getState();
    const { count, page } = payload;
    return this._recService.fetchDealApplications(payload).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isClosedApplicationsLoaded: true,
                closedApplications: data['data']['deals'],
                closedTotalPages: Math.ceil(data['data']['count'] / count),
                currentClosedPage: page,
              },
              setState
            )) ||
            updateState(
              state,
              {
                isClosedApplicationsLoaded: false,
                closedApplications: [],
                currentClosedPage: 1,
              },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            { isClosedApplicationsLoaded: false, closedApplications: [] },
            setState
          )
      )
    );
  }
  /* ================================closed applications start============================== */

  /* ================================under-review applications start============================== */
  @Selector()
  static underReviewApplications(state: RecoveryStateModel) {
    return {
      underReviewApplications: state.underReviewApplications,
      totalPages: state.underReviewTotalPages,
      currentPage: state.currentUnderReviewPage,
    };
  }

  @Selector()
  static isUnderReviewApplicationsLoaded(state: RecoveryStateModel) {
    return state.isUnderReviewApplicationsLoaded;
  }

  @Action(FetchUnderReviewApplications)
  loadUnderReviewApplications(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchUnderReviewApplications
  ) {
    const state = getState();
    const { count, page } = payload;
    return this._recService.fetchDealApplications(payload).pipe(
      tap(
        (data) => {
          console.log(data['data']['deals']);
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isUnderReviewApplicationsLoaded: true,
                underReviewApplications: data['data']['deals'],
                underReviewTotalPages: Math.ceil(data['data']['count'] / count),
                currentUnderReviewPage: page,
              },
              setState
            )) ||
            updateState(
              state,
              {
                isUnderReviewApplicationsLoaded: false,
                underReviewApplications: [],
                currentUnderReviewPage: 1,
              },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            {
              isUnderReviewApplicationsLoaded: false,
              underReviewApplications: [],
            },
            setState
          )
      )
    );
  }
  /* ================================under-review applications end============================== */

  /* ================================marketing month wise spends start============================== */
  @Selector()
  static marketingSpends(state: RecoveryStateModel) {
    return state.marketingMonthWiseSpends;
  }

  @Selector()
  static isMarketingMonthWiseSpendsLoaded(state: RecoveryStateModel) {
    return state.isMarketingMonthWiseSpendsLoaded;
  }

  @Action(FetchMarketingMonthWiseSpends)
  loadMarketingMonthWiseSpends(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchMarketingMonthWiseSpends
  ) {
    const state = getState();
    const { companyId, options } = payload;
    return this._compService.fetchMarketingGraph(companyId, options).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isMarketingMonthWiseSpendsLoaded: true,
                marketingMonthWiseSpends: data['data']['graphData'],
              },
              setState
            )) ||
            updateState(
              state,
              {
                isMarketingMonthWiseSpendsLoaded: false,
                marketingMonthWiseSpends: [],
              },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            {
              isMarketingMonthWiseSpendsLoaded: false,
              marketingMonthWiseSpends: [],
            },
            setState
          )
      )
    );
  }
  /* ================================marketing month wise spends start============================== */

  /* ================================pg month wise revenue start============================== */
  @Selector()
  static pgMonthWiseRevenue(state: RecoveryStateModel) {
    return state.pgMonthWiseRevenue;
  }

  @Selector()
  static isPGMonthWiseRevenueLoaded(state: RecoveryStateModel) {
    return state.isPGMonthWiseRevenueLoaded;
  }

  @Action(FetchPGMonthWiseRevenue)
  loadPGMonthWiseRevenue(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchPGMonthWiseRevenue
  ) {
    const state = getState();
    const { companyId, options } = payload;
    return this._compService.fetchPgGraphData(companyId, options).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isPGMonthWiseRevenueLoaded: true,
                pgMonthWiseRevenue: data['data']['graphData'],
              },
              setState
            )) ||
            updateState(
              state,
              { isPGMonthWiseRevenueLoaded: false, pgMonthWiseRevenue: [] },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            { isPGMonthWiseRevenueLoaded: false, pgMonthWiseRevenue: [] },
            setState
          )
      )
    );
  }
  /* ================================pg month wise revenue start============================== */

  /* ================================deal data start============================== */
  @Selector()
  static dealData(state: RecoveryStateModel) {
    return state.dealData;
  }

  @Selector()
  static selectedAppId(state: RecoveryStateModel) {
    return state.selectedApplicationId;
  }

  @Selector()
  static isDealDataLoaded(state: RecoveryStateModel) {
    return state.isDealDataLoaded;
  }

  @Action(ResetOnDifferentApplication)
  resetOnDifferentApplication({
    getState,
    setState,
  }: StateContext<RecoveryStateModel>) {
    const state = getState();
    updateState(
      state,
      {
        isDealDataLoaded: false,
        dealData: null,
        selectedApplicationId: null,
        isDealCollectionsLoaded: false,
        isRecoveryDataLoaded: false,
        isLedgerDataLoaded: false,
      },
      setState
    );
  }

  @Action(FetchDealData)
  loadDealData(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { id }: FetchDealData
  ) {
    const state = getState();
    return this._recService.fetchDealData(id).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isDealDataLoaded: true,
                dealData: data['data'],
                selectedApplicationId: id,
              },
              setState
            )) ||
            updateState(
              state,
              {
                isDealDataLoaded: false,
                dealData: null,
                selectedApplicationId: null,
              },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            {
              isDealDataLoaded: false,
              dealData: null,
              selectedApplicationId: null,
            },
            setState
          )
      )
    );
  }
  /* ================================deal data start============================== */

  /* ================================deal spends start============================== */
  // @Selector()
  // static dealSpends( state: RecoveryStateModel ){
  //     return{
  //         dealSpends: state.dealSpends,
  //         totalPages: state.totalSpendPage
  //     }
  // }

  // @Selector()
  // static isDealSpendsLoaded( state: RecoveryStateModel ){
  //     return state.isDealSpendsLoaded
  // }

  // @Action(FetchDealSpends)
  // loadDealSpends({getState, setState}: StateContext<RecoveryStateModel>, {payload}:FetchDealSpends){
  //     const state = getState();
  //     return this._recService.fetchDealSpends( payload ).pipe(tap(data=>{
  //         (data.statusCode == 100)
  //         && updateState( state,
  //             {
  //                 isDealSpendsLoaded: true,
  //                 dealSpends: data['data']['allSpends'],
  //                 totalSpendPage: Math.ceil(data['data']['totalCount']/5)
  //             }, setState )
  //         || updateState( state, { isDealSpendsLoaded: false, dealSpends: [] }, setState )
  //     },
  //     error=> updateState( state, { isDealSpendsLoaded: false, dealSpends: [] }, setState )))
  // }
  /* ================================deal spends start============================== */

  /* ================================recovery data start============================== */
  @Selector()
  static recoveryData(state: RecoveryStateModel) {
    return {
      recoveryData: state.recoveryData,
      totalPages: state.totalRecoveryPage,
      totalCount: state.totalRecoveryCount,
    };
  }

  @Selector()
  static isRecoveryDataLoaded(state: RecoveryStateModel) {
    return state.isRecoveryDataLoaded;
  }

  @Action(FetchRecoveryData)
  loadRecoveryData(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchRecoveryData
  ) {
    const state = getState();
    return this._recService.fetchRecoveryData(payload).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isRecoveryDataLoaded: true,
                recoveryData: data['data']['allRecoveryData'],
                totalRecoveryPage: Math.ceil(data['data']['totalCount'] / 5),
                totalRecoveryCount: data['data']['totalCount'],
              },
              setState
            )) ||
            updateState(
              state,
              { isRecoveryDataLoaded: false, recoveryData: [] },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            { isRecoveryDataLoaded: false, recoveryData: [] },
            setState
          )
      )
    );
  }
  /* ================================Recovery data ends============================== */

  /* ================================deal collections start============================== */
  @Selector()
  static dealCollections(state: RecoveryStateModel) {
    return {
      dealCollections: state.dealCollections,
      totalPages: state.totalCollectionsPage,
      totalCount: state.totalCollectionsCount,
    };
  }

  @Selector()
  static isDealCollectionsLoaded(state: RecoveryStateModel) {
    return state.isDealCollectionsLoaded;
  }

  @Action(FetchDealCollections)
  loadDealCollections(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchDealCollections
  ) {
    const state = getState();
    return this._recService.fetchDealCollections(payload).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isDealCollectionsLoaded: true,
                dealCollections: data['data']['collectionData'],
                totalCollectionsPage: Math.ceil(data['data']['totalCount'] / 5),
                totalCollectionsCount: data['data']['totalCount'],
              },
              setState
            )) ||
            updateState(
              state,
              { isDealCollectionsLoaded: false, dealCollections: [] },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            { isDealCollectionsLoaded: false, dealCollections: [] },
            setState
          )
      )
    );
  }
  /* ================================deal collections start============================== */

  @Action(SetDisbursedApplicationsLoadedToFalse)
  setDisbursedApplicationsLoadedToFalse({
    getState,
    setState,
  }: StateContext<RecoveryStateModel>) {
    const state = getState();
    updateState(state, { isDisbursedApplicationsLoaded: false }, setState);
  }

  @Action(SetInRecoveryApplicationsLoadedToFalse)
  setInRecoveryApplicationsLoadedToFalse({
    getState,
    setState,
  }: StateContext<RecoveryStateModel>) {
    const state = getState();
    updateState(state, { isInRecoveryApplicationsLoaded: false }, setState);
  }

  @Action(SetClosedApplicationsLoadedToFalse)
  setClosedApplicationsLoadedToFalse({
    getState,
    setState,
  }: StateContext<RecoveryStateModel>) {
    const state = getState();
    updateState(state, { isClosedApplicationsLoaded: false }, setState);
  }

  @Action(RemoveObjectFromArray)
  removeObjectFromArray(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: RemoveObjectFromArray
  ) {
    const state = getState();
    const { id, type } = payload;
    if (type === 'disbursed') {
      let disbursedApps = state.disbursedApplications.filter((data) => {
        if (data.dealId !== id) {
          return data;
        }
      });
      updateState(state, { disbursedApplications: disbursedApps }, setState);
    } else if (type === 'in-recovery') {
      let inRecoveryApps = state.inRecoveryApplications.filter((data) => {
        if (data.dealId !== id) {
          return data;
        }
      });
      updateState(state, { inRecoveryApplications: inRecoveryApps }, setState);
    } else if (type === 'closed') {
      let closedApps = state.closedApplications.filter((data) => {
        if (data.dealId !== id) {
          return data;
        }
      });
      updateState(state, { closedApplications: closedApps }, setState);
    }
  }
  /* ================================Ledger data start============================== */
  @Selector()
  static ledgerData(state: RecoveryStateModel) {
    return {
      ledgerData: state.ledgerData,
      totalPages: state.totalLedgerPages,
      totalCount: state.totalLedgerCount,
    };
  }

  @Selector()
  static isLedgerDataLoaded(state: RecoveryStateModel) {
    return state.isLedgerDataLoaded;
  }

  @Action(FetchLedgerData)
  loadLedgerData(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchLedgerData
  ) {
    const state = getState();
    return this._recService.fetchLedgerData(payload).pipe(
      tap(
        (data) => {
          (data.statusCode == 100 &&
            updateState(
              state,
              {
                isLedgerDataLoaded: true,
                ledgerData: data['data']['allLedgerData'],
                totalLedgerPages: Math.ceil(data['data']['totalCount'] / 5),
                totalLedgerCount: data['data']['totalCount'],
              },
              setState
            )) ||
            updateState(
              state,
              { isLedgerDataLoaded: false, ledgerData: [] },
              setState
            );
        },
        (error) =>
          updateState(
            state,
            { isLedgerDataLoaded: false, ledgerData: [] },
            setState
          )
      )
    );
  }
  /* ================================Ledger data ends============================== */

  /* ================================collection periods start============================== */
  @Selector()
  static collectionPeriods(state: RecoveryStateModel) {
    return state.collectionPeriods;
  }

  @Selector()
  static isCollectionPeriodsLoaded(state: RecoveryStateModel) {
    return state.isCollectionPeriodsLoaded;
  }

  @Action(FetchCollectionPeriod)
  collectionPeriods({ getState, setState }: StateContext<RecoveryStateModel>) {
    const state = getState();
    return this._recService.fetchCollectionPeriods().pipe(
      tap(
        (response: CollectionPeriodResponse) => {
          (response.statusCode === '100' &&
            updateState(
              state,
              {
                isCollectionPeriodsLoaded: true,
                collectionPeriods: response.data.data,
              },
              setState
            )) ||
            updateState(
              state,
              { isCollectionPeriodsLoaded: false, collectionPeriods: [] },
              setState
            );
        },
        (error: HttpErrorResponse) =>
          updateState(
            state,
            { isCollectionPeriodsLoaded: false, collectionPeriods: [] },
            setState
          )
      )
    );
  }
  /* ================================collection periods ends============================== */

  /* ================================collection liabilities start============================== */
  @Selector()
  static collectionLiabilities(state: RecoveryStateModel) {
    return {
      collectionLiabilities: state.collectionLiabilities,
      totalLiabilityPages: state.totalLiabilityPages,
    };
  }

  @Selector()
  static isCollectionLiabilitiesLoaded(state: RecoveryStateModel) {
    return state.isCollectionLiabilitiesLoaded;
  }

  @Action(FetchCollectionLiability)
  collectionLiability(
    { getState, setState }: StateContext<RecoveryStateModel>,
    { payload }: FetchCollectionLiability
  ) {
    const state = getState();
    const { count } = payload;
    return this._recService.fetchCollectionLiabilities(payload).pipe(
      tap(
        (response: CollectionLiabilityResponse) => {
          (response.statusCode === '100' &&
            updateState(
              state,
              {
                isCollectionLiabilitiesLoaded: true,
                collectionLiabilities: response.data.collectionLiabilities,
                totalLiabilityPages: Math.ceil(
                  response.data.totalCount / count
                ),
              },
              setState
            )) ||
            updateState(
              state,
              {
                isCollectionLiabilitiesLoaded: false,
                collectionLiabilities: [],
              },
              setState
            );
        },
        (error: HttpErrorResponse) =>
          updateState(
            state,
            { isCollectionLiabilitiesLoaded: false, collectionLiabilities: [] },
            setState
          )
      )
    );
  }
  /* ================================collection liabilities ends============================== */
}
