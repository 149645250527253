export class FetchDealsCumulativeData{
    static readonly type = '[FetchDealsCumulativeData] GET'
}

export class FetchDisbursedApplications{
    static readonly type = '[FetchDisbursedApplications] GET'
    constructor( public payload: any ){}
}

export class FetchInRecoveryApplications{
    static readonly type = '[FetchInRecoveryApplications] GET'
    constructor( public payload: any ){}
}
export class FetchAllDealApplications{
    static readonly type = '[FetchAllDealApplications] GET'
    constructor( public payload: any ){}
}

export class FetchInRecoveryAnalysis{
    static readonly type = '[FetchInRecoveryAnalysis] GET'
    constructor( public payload: any ){}
}

export class FetchClosedApplications{
    static readonly type = '[FetchClosedApplications] GET'
    constructor( public payload: any ){}
}

export class FetchPausedApplications{
    static readonly type = '[FetchPausedApplications] GET'
    constructor( public payload: any ){}
}

export class FetchUnderReviewApplications{
    static readonly type = '[FetchUnderReviewApplications] GET'
    constructor( public payload: any ){}
}

export class FetchMarketingMonthWiseSpends{
    static readonly type = '[FetchMarketingMonthWiseSpends] GET'
    constructor( public payload: any ){}
}

export class FetchPGMonthWiseRevenue{
    static readonly type = '[FetchPGMonthWiseRevenue] GET'
    constructor( public payload: any ){}
}
export class FetchDealData{
    static readonly type = '[FetchDealData] GET'
    constructor( public id: string ){}
}
export class ResetOnDifferentApplication{
    static readonly type = '[ResetOnDifferentApplication] SET'
    constructor(){}
}
export class SetSelectedDealId{
    static readonly type = '[SetSelectedDealId] SET'
    constructor( public id: string ){}
}
export class FetchRecoveryData{
    static readonly type = '[FetchRecoveryData] GET'
    constructor( public payload: any ){}
}
export class FetchLedgerData{
    static readonly type = '[FetchLedgerData] GET'
    constructor( public payload: any ){}
}
export class FetchDealCollections{
    static readonly type = '[FetchDealCollections] GET'
    constructor( public payload: any ){}
}
export class SetDisbursedApplicationsLoadedToFalse{
    static readonly type = '[Set_Disbursed_Applications_Loaded_To_False] SET'
}
export class SetInRecoveryApplicationsLoadedToFalse{
    static readonly type = '[Set_In_Recovery_Applications_Loaded_To_False] SET'
}
export class SetClosedApplicationsLoadedToFalse{
    static readonly type = '[Set_Closed_Applications_Loaded_To_False] SET'
}
export class RemoveObjectFromArray{
    static readonly type = '[REMOVE_OBJECT_FROM_ARRAY] POP'
    constructor( public payload: any ){}
}
export class FetchCollectionPeriod{
    static readonly type = '[FETCH_COLLECTION_PERIODS] FETCH'
}
export class FetchCollectionLiability{
    static readonly type = '[FETCH_COLLECTION_LIABILITY] FETCH'
    constructor( public payload: any ){}
}