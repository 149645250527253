import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '../config'
import { B2BRepaymentResponse, DailyRepaymentResponse, FeeDisbursementResponse, QueryParams, B2BChangeStatusPayload, YblStatementResponse, IDFCStatementResponse } from '../models/yesBank.model';
import { KotakStatement , QueryKotakParams , KotakStatementDataResponse} from '../models/kotakBank.model';
const baseURL = new Config().getBaseURL()

@Injectable({
    providedIn: 'root'
})
export class RepaymentsService {

    constructor(private http: HttpClient) { }

    b2bRepaymentsList(queryParams: QueryParams): Observable<B2BRepaymentResponse> {
        return this.http.post<B2BRepaymentResponse>(`${baseURL}/recovery/repayments/b2bRepayments`, queryParams);
    }

    updateB2BRepayments(data): Observable<B2BRepaymentResponse> {
        return this.http.post<any>(`${baseURL}/recovery/repayments/b2bRepayments/changeStatus`, data);
    }

    dailyRepaymentsList(queryParams: QueryParams): Observable<DailyRepaymentResponse> {
        return this.http.post<DailyRepaymentResponse>(`${baseURL}/recovery/repayments/dailyRepayments`, queryParams);
    }

    updateDailyRepayments(data): Observable<B2BRepaymentResponse> {
        return this.http.post<any>(`${baseURL}/recovery/repayments/dailyRepayments/changeStatus`, data);
    }

    feeDisbursementsList(queryParams: QueryParams): Observable<FeeDisbursementResponse> {
        return this.http.get<FeeDisbursementResponse>(`${baseURL}/recovery/repayments/feeRepayments`, { params: { ...queryParams } });
    }

    updateFeeRepayments(data): Observable<B2BRepaymentResponse> {
        return this.http.post<any>(`${baseURL}/recovery/repayments/feeRepayments/changeStatus`, data);
    }

    yblStatements(queryParams: QueryParams): Observable<YblStatementResponse> {
        return this.http.get<YblStatementResponse>(`${baseURL}/payment/YBLStatement/getNotifyData`, { params: { ...queryParams } });
    }

    yblVaAccounts(queryParams?): Observable<any> {
        return this.http.get<any>(`${baseURL}/payment/YBLStatement/getYBLAccountDetails`,{ params: queryParams});
    }
    getAllCompanies() {
      return this.http.get(`${baseURL}/recovery/repayments/b2bRepayments/companyList`);
    }
    getAllDealCompanies() {
      return this.http.get(`${baseURL}/recovery/repayments/dailyRepayments/companyList`);
    }

    reinitiateb2brepayments(data:any){
        return this.http.post<any>(`${baseURL}/recovery/repayments/b2bRepayment/reinitiate`, data);
    }

    reinitiatedailyrepayments(data:any){
        return this.http.post<any>(`${baseURL}/recovery/repayments/dailyRepayment/reinitiate`, data);
    }

    IDFCStatements(queryParams: QueryParams): Observable<IDFCStatementResponse> {
        return this.http.get<IDFCStatementResponse>(`${baseURL}/payment/IDFCStatement/IDFCNotifyData`, { params: { ...queryParams } });
    }

    kotakStatements(queryParams: QueryKotakParams): Observable<KotakStatementDataResponse> {
        return this.http.get<KotakStatementDataResponse>(`${baseURL}/payment/kotak/getKotakData`, { params: { ...queryParams }});
    }

    VaAccountList(queryParams?: any): Observable<any> {
        return this.http.get<any>(`${baseURL}/payment/kotak/getVaAccounts`, { params: { ...queryParams } });
    }
}
