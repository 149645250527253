import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Socket } from 'ngx-socket-io';
import { Config } from '../config';
import { Observable } from 'rxjs';
import {
  CollectionLiabilityResponse,
  CollectionPeriodResponse,
  PostAPIResponse,
} from '../models/collectionLIability.model';
const baseURL = new Config().getBaseURL();

export type ReconAccount = {
  bankName: string;
  bankIdentifier: string;
  accountNumber: string;
  statementEndDate?: string;
  statementStartDate?: string;
};

@Injectable({
  providedIn: 'root',
})
export class RecoveryService {
  ownBankStatementParsed = this.socket.fromEvent<any>('ownBankStatementParsed');
  baseURL: any;

  constructor(private http: HttpClient, private socket: Socket) {}

  fetchCumulativeData(value: any): Observable<any> {
    let queryParams = {
      status: value,
    };
    return this.http.get(`${baseURL}/deal/Recovery/dealsCumulative`, {
      params: { ...queryParams },
    });
  }

  fetchDealApplications({ page, count, body }): Observable<any> {
    return this.http.post(
      `${baseURL}/deal/Recovery/dealsListByStatus?page=${page}&count=${count}`,
      body
    );
  }

  getOpsUserOfDeal(dealId: any) {
    return this.http.get(`${baseURL}/deal/Recovery/${dealId}/getOpsUserOfDeal`);
  }

  fetchDealApplicationsFromPost(page, count, body) {
    return this.http.post(
      `${baseURL}/deal/Recovery/dealsListByStatus?page=${page}&count=${count}`,
      body
    );
  }

  fetchRecoveryAnalysis(data): Observable<any> {
    return this.http.get(`${baseURL}/recovery/OpsRecovery/recoveryAnalysis`, {
      params: data,
    });
  }

  fetchDealData(applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/deal/Recovery/${applicationId}/dealDetails`
    );
  }

  fetchDealSpends({ applicationId, page, count, d }): Observable<any> {
    let params = { page, count, d };
    return this.http.get(
      `${baseURL}/recovery/RecoveryUser/${applicationId}/cardSpends`,
      { params: params }
    );
  }

  fetchRecoveryData({ dealId, page, count, d }): Observable<any> {
    let params = { page, count, d };
    return this.http.get(
      `${baseURL}/recovery/RecoveryUser/${dealId}/recoveryData`,
      { params: params }
    );
  }

  fetchLedgerData({ dealId, page, count, d }): Observable<any> {
    let params = { page, count, d };
    return this.http.get(
      `${baseURL}/recovery/RecoveryUser/${dealId}/ledgerData`,
      { params: params }
    );
  }

  fetchRecoveryBankAccounts(): Observable<any> {
    return this.http.get(`${baseURL}/recovery/RecoveryUser/recon/accounts`);
  }

  fetchDealCollections({ dealId, page, count, d }): Observable<any> {
    let params = { page, count, d };
    return this.http.get(
      `${baseURL}/recovery/RecoveryUser/${dealId}/collectionStatements`,
      { params: params }
    );
  }

  addDisbursementDate(date: any, dealId: any) {
    return this.http.post(
      `${baseURL}/deal/Recovery/${dealId}/addDisbursement`,
      {
        date,
      }
    );
  }

  startStopRecovery({ dealId, status }): Observable<any> {
    return this.http.post(`${baseURL}/deal/Recovery/${dealId}/startRecovery`, {
      status,
    });
  }

  closeDeal(dealId: string): Observable<any> {
    return this.http.patch(`${baseURL}/deal/Recovery/closeDeal`, {
      id: dealId,
    });
  }

  getPgMonitoringCount(): Observable<any> {
    return this.http.get(`${baseURL}/pg/api/count`);
  }

  getPgMonitoringList({ page, count, company }): Observable<any> {
    return this.http.get(`${baseURL}/pg/api/status/success`, {
      params: { page, count, company },
    });
  }

  /* Reconciliation routes */
  accountsList(): Observable<any> {
    return this.http.get(`${baseURL}/recovery/RecoveryUser/recon/accounts`);
  }

  uploadBankStatements(
    file: File,
    bankName: string,
    fileName?: string
  ): Observable<any> {
    const formData = new FormData();
    if (fileName) formData.append('file', file, fileName);
    else formData.append('file', file);
    return this.http.post(
      `${baseURL}/recovery/RecoveryUser/recon/bankStatementUpload`,
      formData,
      { params: { bankName } }
    );
  }

  fetchReconciliationsByAccount(
    accountNo: string = '',
    pgNum: string = '1',
    fromDate?: string,
    toDate?: string,
    companyName?: string,
    filter: boolean = false,
    totalCount? : number
  ): Observable<any> {
    if (!fromDate && !toDate && companyName === '') filter = false;
    return this.http.get(`${baseURL}/reconciliation/recon/reconcileData`, {
      params: {
        accountNumber: accountNo,
        page: pgNum, ...(fromDate && { fromDate }), ...(toDate && { toDate }), ...(companyName && { companyName }), filter: filter.toString(),count: totalCount.toString() },
    });
  }

  fetchNonReconciliationsByAccount(
    accountNo: string,
    pgNum: string = '1',
    totalCount : string
  ): Observable<any> {
    return this.http.get(`${baseURL}/reconciliation/recon/nonReconciledData`, {
      params: {
        accountNumber: accountNo,
        page: pgNum,
        count: totalCount ,
      },
    });
  }

  updateOtherPayment( id : string , remark: string , isOtherPayment : boolean): Observable<any> {
    return this.http.put(`${baseURL}/recovery/RecoveryUser/recon/otherpayment`, { id , remark , isOtherPayment});
  }

  fetchOtherPayment( accountNo: string, pgNum: string = '1' ,totalCount: string ) : Observable<any>{
  return this.http.get(`${baseURL}/reconciliation/recon/fetchOtherPayment`, {
      params: {
      accountNumber: accountNo,
      page: pgNum,
      count:  totalCount ,
    },
   })
  }

  moveToNonReconcile(id : string ): Observable<any> {
    return this.http.put(`${baseURL}/reconciliation/recon/otherPaymentToNonReconcile`,{ id})
  }

  fetchCompanies(): Observable<any> {
    return this.http.get(`${baseURL}/recovery/RecoveryUser/deals/active`);
  }

  addToRecon(data: any): Observable<any> {
    return this.http.post(`${baseURL}/reconciliation/recon/addRecon`, data);
  }

  uploadNACH(file: File, total: number): Observable<any> {
    // prepare form data
    const formData = new FormData();

    formData.append('file', file);
    formData.append('data', `${total}`);
    return this.http.post(
      `${baseURL}/recovery/RecoveryUser/recon/parseNachFile`,
      formData
    );
  }

  consolidatedReconcile(id: string, data: object): Observable<any> {
    return this.http.post(
      `${baseURL}/recovery/RecoveryUser/recon/reconcileConsolidate`,
      {
        id,
        data,
      }
    );
  }

  runReconciliations(): Observable<any> {
    return this.http.get(`${baseURL}/reconciliation/recon/reconciliation`);
  }

  fetchCollectionPeriods(): Observable<CollectionPeriodResponse> {
    return this.http.get<CollectionPeriodResponse>(
      `${baseURL}/recovery/OpsRecovery/collectionPeriods`
    );
  }

  fetchCollectionLiabilities(payload): Observable<CollectionLiabilityResponse> {
    return this.http.get<CollectionLiabilityResponse>(
      `${baseURL}/recovery/OpsRecovery/collectionRecords`,
      { params: { ...payload } }
    );
  }

  sendCollectionLiabilityMails(ids: string[]): Observable<any> {
    return this.http.post<any>(
      `${baseURL}/recovery/OpsRecovery/sendCollectionMail`,
      { ids }
    );
  }

  updateDealCollectionEmails({
    _id,
    mailTo,
    mailCc,
  }): Observable<PostAPIResponse> {
    return this.http.post<PostAPIResponse>(
      `${baseURL}/recovery/OpsRecovery/${_id}/updateDealCollectionEmails`,
      { mailTo, mailCc }
    );
  }

  addExtensionFee(payload: any, dealId: string): Observable<any> {
    return this.http.post<any>(
      `${baseURL}/recovery/RecoveryUser/${dealId}/extensionFee`,
      payload
    );
  }

  updateLiability(payload: any, dealId: string): Observable<any> {
    return this.http.post<any>(
      `${baseURL}/recovery/RecoveryUser/${dealId}/updateLiability`,
      payload
    );
  }

  getExtensionFeeList(dealId: string): Observable<any> {
    return this.http.get<any>(
      `${baseURL}/recovery/RecoveryUser/${dealId}/getExtensionFees`
    );
  }

  getActivityLogDetails(collectionRecordId: string): Observable<any> {
    return this.http.get<any>(
      `${baseURL}/recovery/RecoveryUser/${collectionRecordId}/getLiabilityActivityLog`
    );
  }

  sendNoDuesLetter(dealId: string): Observable<any> {
    return this.http.get<any>(
      `${baseURL}/recovery/RecoveryUser/${dealId}/sendNoDuesLetter`
    );
  }

  getSecondaryCollections(payload: any, dealId: string): Observable<any> {
    return this.http.get<any>(
      `${baseURL}/recovery/RecoveryUser/${dealId}/getSecondaryCollections`,
      { params: payload }
    );
  }

  updateSecondaryCollection(payload: any, id: string): Observable<any> {
    return this.http.put<any>(
      `${baseURL}/recovery/RecoveryUser/${id}/updateSecondaryCollectionsStatus`,
      payload
    );
  }

  deleteReconcileEntry(_id): Observable<any> {
    console.log(_id);
    return this.http.post<any>(`${baseURL}/reconciliation/recon/unreconcile`, {
      id: _id,
    });
  }

  deleteNonReconciledAccount(_id): Observable<any> {
    return this.http.delete<any>(
      `${baseURL}/reconciliation/recon/${_id}/removeRecon`
    );
  }

  addReconciliationAccount(data) {
    console.log(data);
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(`${baseURL}/reconciliation/recon/addReconciliationAccount`, data)
        .subscribe(
          (resp) => {
            resolve(resp);
          },
          (err) => {
            reject(err);
          }
        );
    });
    return promise;
  }

  addRefund(data) {
    console.log(data);
    let promise = new Promise((resolve, reject) => {
      this.http
        .post(`${baseURL}/reconciliation/recon/addRefund`, data)
        .subscribe(
          (resp) => {
            resolve(resp);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  fetchDailyInterest(
    payload: any,
    page: number,
    count: number
  ): Observable<any> {
    return this.http.post<any>(
      `${baseURL}/reconciliation/recon/dailyIntestStatements?page=${page}&count=${count}`,
      payload
    );
  }

  fetchIdfcRepayment(
    payload: any,
    page: number,
    count: number
  ): Observable<any> {
    return this.http.post<any>(
      `${baseURL}/reconciliation/recon/dailyRepayments?page=${page}&count=${count}`,
      payload
    );
  }

  updateIdfcRepaymentStatus(payload: any): Observable<any> {
    return this.http.post<any>(
      `${baseURL}/reconciliation/recon/updateDailyRepaymentStatus`,
      payload
    );
  }

  CollectionLiabilityUpdateNachAmount(payload: any): Observable<any> {
    return this.http.post<any>(
      `${baseURL}/recovery/OpsRecovery/updateNachAmount`,
      payload
    );
  }

  CollectionLiabilitySentMailToFP(payload: any): Observable<any> {
    return this.http.post<any>(
      `${baseURL}/recovery/OpsRecovery/sendNachAmountToFP`,
      payload
    );
  }

  fetchNachHistory(params: any): Observable<any> {
    return this.http.get<any>(`${baseURL}/recovery/OpsRecovery/nachHistory`, {
      params,
    });
  }

  fetchLenderSummary( params :any ): Observable<any>{
    return this.http.get<any>(`${baseURL}/recovery/OpsRecovery/ledersummary`, {
      params,
    });
  }

  uploadNachReport(payload: any, { half, period }): Observable<any> {
    return this.http.post<any>(
      `${baseURL}/recovery/repayments/${half}/${period}/uploadNachFile`,
      payload
    );
  }

  //  Added by Kamlesh
  fetchPayableAmount(
    companyId: string,
    applicationId: string
  ): Observable<any> {
    return this.http.get(
      `${baseURL}/fp/dmiService/${companyId}/${applicationId}/fetchPayableAmount`
    );
  }

  getRPSData(dealId: string, facilityType: string) {
    return this.http.get(
      `${baseURL}/reconciliation/recon/${dealId}/${facilityType}/getRPSData`
    );
  }

  uploadRPSFile(formData: any, dealId: string) {
    return this.http.post(
      `${baseURL}/reconciliation/recon/${dealId}/uploadRPS`,
      formData
    );
  }

}
