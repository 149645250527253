export class FetchApplications {
    static readonly type = '[FetchApplicationForOperations] GET'
    constructor(public payload: any) { }
}
export class SetSelectedApplication {
    static readonly type = '[SetSelectedApplication] SET'
    constructor(public payload: any) { }
}
export class FetchDealParams {
    static readonly type = '[FetchDealParams] GET'
    constructor(public payload: any) { }
}
export class AddDealParams {
    static readonly type = '[AddDealParams] POST'
    constructor(public payload: any) { }
}
export class FetchPayments {
    static readonly type = '[FetchPayments] GET'
    constructor(public id: string) { }
}
export class FetchVendors {
    static readonly type = '[FetchVendors] GET'
    constructor(public id: string) { }
}
export class ResetOnDifferentApplication {
    static readonly type = '[ResetOnDifferentApplication] RESET'
}
export class FetchOnboardingChecklist {
    static readonly type = '[FetchOnboardingChecklist] GET'
    constructor(public id: string) { }
}

export class FetchFPChecklist{
    static readonly type = '[FetchFPChecklist] GET'
    constructor(public id: string) { }
}
export class ChangeValueInChecklist {
    static readonly type = '[ChangeValueInChecklist] SET'
    constructor(public payload: any) { }
}
/* 
**** passing company id as id to finbox api to get session url 
*/
export class GenerateFinboxSession {
    static readonly type = '[GenerateFinboxSession] POST'
    constructor(public id: string) { }
}
/* 
**** fetch activity logs for deal from deal id 
*/
export class FetchDealActivityLogs {
    static readonly type = '[FetchDealActivityLogs] GET'
    constructor(public id: string) { }
}
/* 
**** fetch checklist logs for checklist from checklist id 
*/
export class FetchChecklistLogs {
    static readonly type = '[FetchChecklistLogs] GET'
    constructor(public id: string) { }
}
/* 
**** fetch list of uploaded documents by application id  
*/
export class FetchDocumentList {
    static readonly type = '[FetchDocumentList] GET'
    constructor(public id: string) { }
}
/* 
**** fetch document logs by document id  
*/
export class FetchDocumentLogs {
    static readonly type = '[FetchDocumentLogs] GET'
    constructor(public id: string) { }
}
export class FetchLoanRequest {
    static readonly type = '[FetchLoanRequest] GET'
    constructor(public compId: string, public appId) { }
}
export class FetchBorrowerRequest {
    static readonly type = '[FetchBorrowerRequest] GET'
    constructor(public compId: string, public appId) { }
}
export class FetchPnachRequest {
    static readonly type = '[FetchPnachRequest] GET'
    constructor(public compId: string, public appId) { }
}
export class FetchRecoveryAccounts {
    static readonly type = '[FetchRecoveryAccounts] GET'
    constructor(public id: string) { }
}
export class FetchPgAccounts {
    static readonly type = '[FetchPgAccounts] GET'
    constructor(public id: string) { }
}
export class FetchAllInvoices {
    static readonly type = '[FetchAllInvoices] GET'
    constructor(public payload: any) { }
}
export class FetchCardSpends {
    static readonly type = '[FetchCardSpends] GET'
    constructor(public payload: any) { }
}
export class AddCardSpends {
    static readonly type = '[AddCardSpends] ADD'
    constructor(public payload: any) { }
}
export class UpdateCardSpends {
    static readonly type = '[UpdateCardSpends] UPDATE'
    constructor(public payload: any) { }
}
export class FetchInvestingPartners {
    static readonly type = '[FetchInvestingPartners] FETCH'
}
export class FetchPendingActionCount {
    static readonly type = '[FETCH_PENDING_ACTION_COUNT] FETCH'
}
export class FetchPendingActionsByType {
    static readonly type = '[FETCH_PENDING_ACTIONS_BY_TYPE] FETCH'
    constructor(public payload: any) { }
}
export class FetchOpsEFApplications {
    static readonly type = '[FETCH_OPS_EF_APPLICATIONS] FETCH'
    constructor(public payload: any) { }
}
export class FetchOpsEFApplicationTranches {
    static readonly type = '[FETCH_OPS_EF_TRANCHES] FETCH'
    constructor(public payload: any) { }
}
export class FetchOpsEFApplicationLedger {
    static readonly type = '[FETCH_OPS_EF_LEDGER] FETCH'
    constructor(public payload: any) { }
}
export class FetchFeeDisbursements {
    static readonly type = '[FETCH_FEE_DISBURSEMENTS] FETCH'
    constructor(public payload: any) { }
}
export class FetchDailyRepayments {
    static readonly type = '[FETCH_DAILY_REPAYMENTS] FETCH'
    constructor(public payload: any) { }
}
export class FetchB2BRepayments {
    static readonly type = '[FETCH_B2B_REPAYMENTS] FETCH'
    constructor(public payload: any) { }
}

export class FetchYBLStatements {
    static readonly type = '[FETCH_YBL_STATEMENTS] FETCH'
    constructor(public payload: any) { }
}

export class FetchVaAccountList {
    static readonly type = '[FETCH_VA_ACCOUNTS] FETCH'
    constructor(public payload: any) { }
}

export class FetchKotakStatements {
    static readonly type = '[FETCH_KOTAK_STATEMENTS] FETCH'
    constructor(public payload: any) { }
}