import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/internal/operators';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

import { Config } from '../config'
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';
const baseURL = new Config().getBaseURL()
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private url = '/login/';
    TOKEN_KEY = 'token'
    REFRESH_TOKEN = 'refresh-token'
    USER_ROLE_DETAILS = 'user-details'
    constructor(private http: HttpClient, private router: Router, private cookie: CookieService, private store: Store) {
    }

    login(email: string, password: string): Observable<any> {
        var params = {
            "email": email,
            "password": password
        }
        return this.http.post(`${baseURL}/signin/basic`, params);
    }

    forgotPassword(email: string) {
        var params = {
            "email": email
        }
        return this.http.post(`${baseURL}/signin/sendAuthCode`, params);
    }

    verifyAuthCode(authCode:number) {
        var params = {
            "authCode": authCode
        }
        return this.http.post(`${baseURL}/signin/verifyAuthCode`, params);
    }

    resetPassword(password: string, confirmPassword: string) {
        var params = {
            "password": password,
            "confirmPassword" : confirmPassword
        }
        return this.http.post(`${baseURL}/users/openAPI/newPassword`, params);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        this.cookie.deleteAll();
        this.router.navigate(['']).then(()=>this.store.dispatch( new StateClear() ))
    }

    setUserAndToken(token, refreshToken, roleDetails, email) {
        localStorage.setItem(this.TOKEN_KEY, token)
        localStorage.setItem(this.REFRESH_TOKEN, refreshToken)
        localStorage.setItem('roles', JSON.stringify(roleDetails))
        localStorage.setItem('user_email', email)
    }
    getRoles() {
        return JSON.parse(localStorage.getItem('roles'))
    }

}