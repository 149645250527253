
/* 
    fetch deal summary action for disbursed and closed deal, 
    id is application id
*/
export class FetchDealSummary{
    static readonly type = '[FetchDealSummary] GET'
    constructor( public id: string ){}
}
export class FetchDealsEFApplications {
    static readonly type = '[FETCH_DEALS_EF_APPLICATIONS] FETCH'
    constructor(public payload: any) { }
}
export class FetchDealsEFApplicationTranches {
    static readonly type = '[FETCH_DEALS_EF_TRANCHES] FETCH'
    constructor(public payload: any) { }
}
export class FetchDealsEFApplicationLedger {
    static readonly type = '[FETCH_DEALS_EF_LEDGER] FETCH'
    constructor(public payload: any) { }
}