import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchAccountLogs, FetchCompanies, FetchCompanyDetails, FetchStatements, ResetOnDifferentCompany } from '../actions/company.action';
import { CompanyService } from '../../services/company.service';
import { tap } from 'rxjs/operators';
import { updateState } from '../../../app/utils/immutableUpdate';
import { FinboxService } from 'src/app/services/finbox.service';
import { Config } from '../../config';

/* state model */
export class CompanyStateModel {
  isBankStatementsLoaded: boolean;
  isCompaniesLoaded: boolean;
  isAccountLogsLoaded: boolean;
  isCompanyDetailsLoaded: boolean;

  companies: Array<any>;
  currentPage: number;
  totalPages: number;
  totalCount: number;
  selectedCompany: object;
  searchKey: string;
  selectedAccountId: string;
  bankStatements: Array<any>;
  accountLogs: Array<any>
  companyDetails: Object
}

/* initial state */
@State<CompanyStateModel>({
  name: 'companyState',
  defaults: {
    isBankStatementsLoaded: false,
    isCompaniesLoaded: false,
    isAccountLogsLoaded: false,
    isCompanyDetailsLoaded: false,

    companies: null,
    currentPage: 0,
    totalPages: 0,
    totalCount : 0,
    selectedCompany: null,
    searchKey: null,
    selectedAccountId: null,
    bankStatements: [],
    accountLogs: [],
    companyDetails: null
  },
})
@Injectable()
export class CompanyState {
  baseURL = new Config().getBaseURL();
  constructor(private _compService: CompanyService, private _finService: FinboxService) {}

  /* Selector to select companies from state */
  @Selector()
  static getCompanyData(state: CompanyStateModel) {
    return {
      companies: state.companies,
      currentPage: state.currentPage,
      totalPages: state.totalPages,
      searchedCompany: state.searchKey,
      totalCount : state.totalCount
    };
  }

  /* Selector to check if companies are loaded in state */
  @Selector()
  static isCompaniesLoaded(state: CompanyStateModel) {
    return state.isCompaniesLoaded;
  }

  /* Selector to check if bank statements are loaded in state */
  @Selector()
  static isBankStatementsLoaded(state: CompanyStateModel) {
    return state.isBankStatementsLoaded;
  }

  /* Selector to get selected account id in state */
  @Selector()
  static accountId(state: CompanyStateModel) {
    return state.selectedAccountId;
  }

  /* Selector to get bank statements in state */
  @Selector()
  static bankStatements(state: CompanyStateModel) {
    return state.bankStatements;
  }

  @Selector()
  static accountLogs(state: CompanyStateModel) {
    return state.accountLogs;
  }

  @Selector()
  static isAccountLogsLoaded(state: CompanyStateModel) {
    return state.isAccountLogsLoaded;
  }

  @Selector()
  static companyDetails(state: CompanyStateModel) {
    return state.companyDetails;
  }

  @Selector()
  static isCompanyDetailsLoaded(state: CompanyStateModel) {
    return state.isCompanyDetailsLoaded;
  }

  @Selector()
  static selectedCompany(state: CompanyStateModel) {
    return state.selectedCompany;
  }


  @Action(ResetOnDifferentCompany)
  resetOnDifferentCompany({ getState, setState }: StateContext<CompanyStateModel>,) {
      const state = getState();
      updateState(state, {   isBankStatementsLoaded: false, isCompaniesLoaded: false, isAccountLogsLoaded: false,
        isCompanyDetailsLoaded: false, }, setState)
  }

  /* action to fetch companies from api */
  @Action(FetchCompanies)
  fetchCompanies(
    { getState, setState }: StateContext<CompanyStateModel>,
    { payload }: FetchCompanies
  ) {
    const state = getState();
    return this._compService.fetchCompanies(payload).pipe(
      tap((data) => {
        (data.statusCode === "100" &&
          updateState(state, this.successResponse(data, payload), setState)) ||
          updateState(state, this.failureResponse(), setState);
      })
    );
  }

  @Action(FetchCompanyDetails)
  fetchCompanyDetails(
    { getState, setState }: StateContext<CompanyStateModel>,
    { id }: FetchCompanyDetails
  ) {
    const state = getState();
    return this._compService.fetchCompanyDetails(id).pipe(
      tap((data) => {
        (data.statusCode === "100" &&
          updateState(state, {isCompanyDetailsLoaded: true, companyDetails: data['data'], selectedCompany: id}, setState)) ||
          updateState(state, {isCompanyDetailsLoaded: false, companyDetails: [], selectedCompany: id}, setState);
      },
      error=> updateState(state, {isCompanyDetailsLoaded: false, companyDetails: [], selectedCompany: id}, setState))
    );
  }

  /* action to fetch companies from api */
  @Action(FetchStatements)
  fetchBankStatements(
    { getState, setState }: StateContext<CompanyStateModel>,
    { payload }: FetchStatements
  ) {
    const state = getState();
    return this._compService.fetchStatementsToDownload(payload).pipe(
      tap(
        (data) => {
          if (data.statusCode === "100") {
            let bankStatements = data['data']['pdfData'];
            bankStatements = bankStatements.map((ele) => ({
              ...ele,
              pdf_url: ele.pdf_url.includes("gv-companydata")
                ? `${this.baseURL}/companies/${payload.companyId}/downloadBankStatement?url=${ele.pdf_url}`
                : ele.pdf_url,
              finboxUrl: !ele.pdf_url.includes("gv-companydata")
            }));
            updateState(
              state,
              {
                selectedAccountId: payload['accountId'],
                isBankStatementsLoaded: true,
                bankStatements,
              },
              setState
            )
          } else {
            updateState(
              state,
              { bankStatements: null, isBankStatementsLoaded: false },
              setState
            );
          }
        },
        (error) =>
          updateState(
            state,
            { bankStatements: null, isBankStatementsLoaded: false },
            setState
          )
      )
    );
  };

    /* action to fetch logs for account */
    @Action(FetchAccountLogs)
    fetchAccountLogs({ getState, setState }: StateContext<CompanyStateModel>, {id}: FetchAccountLogs) {
      const state = getState();
      return this._finService.fetchAccountLogs(id).pipe(
        tap(data=> {
          (data['statusCode'] == 100)
          && updateState(state, { isAccountLogsLoaded: true, accountLogs: data['data'] }, setState)
          || updateState(state, { isAccountLogsLoaded: false, accountLogs: [] }, setState);
        },
        error=> updateState(state, { isAccountLogsLoaded: false, accountLogs: [] }, setState))
      );
    }

  successResponse(data, { page, company }) {
    return {
      isCompaniesLoaded: true,
      companies: data['data']['companies'],
      currentPage: page,
      totalPages: Math.ceil(data['data']['compCount'] / 10),
      totalCount : data['data']['compCount'],
      selectedCompany: null,
      searchKey: company,
    };
  }

  failureResponse() {
    return {
      isCompaniesLoaded: false,
      companies: [],
      currentPage: 0,
      totalPages: 0,
      selectedCompany: null,
      searchKey: null,
    };
  }
}
