import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { SwalPopupService } from 'src/app/services/swal-popup.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassword: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  email: string = "";

  constructor(
    private formBuilder: FormBuilder, private route: ActivatedRoute,
    private router: Router, private authenticationService: AuthService,
    private loader: NgxUiLoaderService, private swal: SwalPopupService
  ) { }

  ngOnInit(): void {
  }

  get f() {
    return this.forgotPassword.controls;
  }

  onSubmit() {
    if (this.email.length > 0) {
      this.loader.start()
      this.authenticationService.forgotPassword(this.email).subscribe(resp => {
        this.loader.stop()
        if (resp['statusCode'] == 100 && resp['message'] == 'Authentication code sent successfully') {
            
          this.swal.success('Authentication code sent successfully', () => {
            this.router.navigate(['/login']);
          });
          
        } else {
          this.loader.stop()
          this.swal.error('Something went wrong')
        }
      }, (error: HttpErrorResponse) => {
        this.loader.stop()
        console.error(error)
        error['error']['message'] == 'Authentication failure'
          ? this.swal.error('Email incorrect')
          : this.swal.error(error['error']['message'])
      })
    } else {
      this.swal.error('Email is required')
    }
  }
}