<div class="container-fluid">
    <div class="d-grid main">
        <div class="logo p-10">
            <img src="assets/img/logo_withgv (2).png" alt="GetVantage Tech Private Limited" loading="lazy" width="200px" />
            <!-- <img src="../../../assets/img/instagram icon.png" width="40px" class="f-right" loading="lazy" alt="GetVantage Tech"> -->
        </div>
        <div class="d-grid parts">
            <div class="left">
                <img src="../../../assets/img/Finance app_Flatline.svg" class="mt-20 borderright" loading="lazy" alt="left banner" />
            </div>
            <div class="right">
                <form (ngSubmit)="onSubmit()">
                    <h2>Welcome Back :)</h2>
                    <p class="gray">
                        Please Login with your personal email address and password.
                    </p>
                    <div class="input-fields">
                        <div class="field field1 d-grid">
                            <div class="input-logo">
                                <i class="fal fa-envelope"></i>
                                <!-- <img src="../../../assets/img/mail(24x24)@2x.png" loading="lazy" alt="email" /> -->
                            </div>
                            <input type="email" placeholder="Email" name="email" autofocus [(ngModel)]="email" />
                        </div>
                        <div class="field field2 d-grid">
                            <div class="input-logo">
                                <i class="far fa-lock-alt"></i>
                                <!-- <img src="../../../assets/img/lock(24x24)@2x.png" loading="lazy" alt="password lock" /> -->
                            </div>
                            <input type="password" placeholder="Password" name="password" [(ngModel)]="password" />
                        </div>
                        <p><a routerLink='/forgot-password'><small>Forgot Password?</small></a></p>
                    </div>
                    <button type="submit" class="primary-btn ripple mb-4">
            Login
          </button>
                </form>
            </div>
        </div>
    </div>
</div>