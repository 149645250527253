<div class="container-fluid">
    <div class="d-grid main">
      <div class="logo p-10">
        <img
          src="assets/img/getvantage_logo.png"
          alt="GetVantage Tech Private Limited"
          loading="lazy"
          width="200px"
        />
        <img src="../../../assets/img/instagram icon.png" width="40px" class="f-right" loading="lazy" alt="GetVantage Tech">
      </div>
      <div class="d-grid parts">
        <div class="left">
          <img src="../../../assets/img/Finance app_Flatline.svg" style="border-right: 1px solid #ddd;" class="mt-20" loading="lazy" alt="left banner" />
        </div>
        <div class="right">
          <form (ngSubmit)="onSubmit()">
            <h2 class="mb-20">Enter your new password :)</h2>
            <div class="input-fields">
                <div class="field field1 d-grid mb-20">
                    <div class="input-logo">
                        <img src="../../../assets/img/lock(24x24)@2x.png"loading="lazy" alt="password lock" />
                      </div>
                    <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    [(ngModel)]="password1"
                    required
                    />
                </div>
              <div class="field field2 d-grid mb-0">
                <div class="input-logo">
                  <img src="../../../assets/img/lock(24x24)@2x.png"loading="lazy" alt="password lock" />
                </div>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  name="password"
                  [(ngModel)]="password2"
                />
              </div>
            </div>
            <button type="submit" class="primary-btn ripple">
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  