import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { SwalPopupService } from 'src/app/services/swal-popup.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPassword: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  password1: string = '';
  password2: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthService,
    private loader: NgxUiLoaderService,
    private swal: SwalPopupService
  ) {}

  ngOnInit(): void {}

  get f() {
    return this.resetPassword.controls;
  }

  onSubmit() {
    if (
      this.password1.length > 0 &&
      this.password2.length > 0 &&
      this.password1 == this.password2
      ) {
        this.loader.start();
        this.authenticationService.resetPassword(this.password1, this.password2).subscribe(
        (resp) => {
          this.loader.stop();
          if (
            resp['statusCode'] == 100 &&
            resp['message'] == 'Password updated successfully'
          ) {
            this.swal.success('Password updated successfully', () => {
              this.router.navigate(['/login']);
            });
          } else {
            this.loader.stop();
            this.swal.error('Something went wrong');
          }
        },
        (error: HttpErrorResponse) => {
          this.loader.stop();
          console.error(error);
          error['error']['message'] == 'Authentication failure'
            ? this.swal.error('Password and confirm password should be same')
            : this.swal.error(error['error']['message']);
        }
      );
    } else {
      this.swal.error('Password and confirm password did not match!');
    }
  }
}
