import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Config } from "../config";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FinboxService {
  
  baseUrl : string = new Config().getBaseURL()
  api_key: string = "9YQ3Xmi3pvtH4zPHKsp9pT2Wq5D4NnqZFCR0XZtM";
  
  constructor( private http: HttpClient ) { }

  /*
    =============================================GET API 's=============================================
  */
  fetchAccounts({ companyId, entityId }){
    return this.http.get(`${this.baseUrl}/finbox/entity/${companyId}/${entityId}/accounts`)
  }

  fetchAccountLogs( accountId ){
    return this.http.get(`${this.baseUrl}/finbox/account/${accountId}/accountLogs`)
  }

  /*
    =============================================POST API 's=============================================
  */
  generateSession(link_id): Observable<any> {
    var body = {
      link_id,
      api_key: this.api_key
    };
    return this.http.post(
      `https://portal.finbox.in/bank-connect/v1/session/`,
      body
    );
  }

  addIFSC(ifsc, accountId, accountNumber?): Observable<any> {
    return this.http.post(`${this.baseUrl}/finbox/entity/saveIfsc`, { accountNumber, ifsc, accountId })
  }


}
