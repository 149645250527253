import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Config } from '../config';
import { Observable } from 'rxjs';
const baseURL = new Config().getBaseURL();

@Injectable({
  providedIn: 'root',
})
export class OpsService {
  constructor(private http: HttpClient) {}

  /* ==============================GET APi's============================== */

  /* ------------------load applications for ops---------------- */
  fetchApplications({
    page,
    company,
    status,
    startDate,
    endDate,
    dateType,
    referredBy,
  }): Observable<any> {
    var financeType = 'RBF';
    return this.http.post(
      `${baseURL}/applications/Operations/applications/listing`,
      {
        startDate,
        endDate,
        dateType,
        referredBy,
        company,
        status,
        financeType,
      },
      { params: { page, count: '10' } }
    );
  }

 /* ------------------Get Application for ops ---------------- */
  getApplicationListForOps(page?: number, count?: number, payload?: object) {
    return this.http.post(`${baseURL}/applications/Operations/getApplicationListForOps?page=${page}&count=${count}`, payload );
  }
  fetchIds(searchTerm:string) {
    return this.http.get(`${baseURL}/applications/Operations/fetchIds?search=${searchTerm}` );
  }
  fetchApplicationListForOpsNew(query:{}) {
    return this.http.post(`${baseURL}/applications/Operations/getApplicationListForOpsNew`,query );
  }
  /* ------------------load deal data for application---------------- */
  fetchDealData({ companyId, applicationId }): Observable<any> {
    return this.http.get(
      `${baseURL}/deal/ops/${companyId}/${applicationId}/deals`
    );
  }

  /* ------------------fetch vendors under company---------------- */
  fetchVendors(companyId): Observable<any> {
    return this.http.get(`${baseURL}/vendor/ops/${companyId}/vendors`);
  }

  /* ------------------get payments under application---------------- */
  fetchPayments(applicationId): Observable<any> {
    return this.http.get(`${baseURL}/payment/ops/${applicationId}/payments`);
  }

  /* ------------------fetch onboarding check list for ops---------------- */
  fetchChecklist(applicationId): Observable<any> {
    return this.http.get(`${baseURL}/checklist/ops/${applicationId}/checklist`);
  }

  fetchFPChecklist(applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/fp-checklist/ops/${applicationId}/checklist`
    );
  }

  /* ------------------load activity logs for deal from deal id---------------- */
  loadDealActivityLogs(dealId): Observable<any> {
    return this.http.get(`${baseURL}/deal/ops/${dealId}/dealActivityLogs`);
  }

  /* ------------------fetch checklist logs for from checklist id---------------- */
  fetchChecklistLogs(checklistId): Observable<any> {
    return this.http.get(
      `${baseURL}/checklist/ops/${checklistId}/checklistLog`
    );
  }

  /* ------------------load application details for ops---------------- */
  loadApplicationDetail(applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/applications/Operations/${applicationId}/info`
    );
  }

  /* ------------------get list of uploaded documents---------------- */
  getListOfUploadedDocuments(applicationId): Observable<any> {
    return this.http.get(`${baseURL}/document/openAPI/${applicationId}/list`);
  }

  getListOfUploadedDocumentsbyCompany(companyId): Observable<any> {
    return this.http.get(
      `${baseURL}/document/openAPI/${companyId}/listbyCompany`
    );
  }

  /* --------------------load document logs/activity of ops from document id-------------------- */
  fetchDocumentLogs(documentId): Observable<any> {
    return this.http.get(`${baseURL}/document/openAPI/${documentId}/logs`);
  }

  /* --------------------fetch recovery accounts under company-------------------- */
  fetchRecoveryAccounts(companyId): Observable<any> {
    return this.http.get(
      `${baseURL}/recovery/ops/${companyId}/recoveryAccounts`
    );
  }

  /* --------------------fetch latest invoice for all companies-------------------- */
  fetchLatestInvoices({ page, company }): Observable<any> {
    return this.http.get(`${baseURL}/payment/ops/latestpayments`, {
      params: { page, company },
    });
  }

  /* --------------------fetch latest invoice for all companies-------------------- */
  fetchCardSpends({ applicationId, page, mode }): Observable<any> {
    return this.http.get(
      `${baseURL}/cardSpends/OpsExecutive/${applicationId}/cardSpends`,
      { params: { page, mode } }
    );
  }

  downloadProposalDocument({ companyId, applicationId }): Observable<any> {
    return this.http.get(
      `${baseURL}/proposalDocument/all/${companyId}/${applicationId}/proposalDocument/downloadProposalPDF`
    );
  }

  getCibilUrlFromProposal({ companyId, applicationId }): Observable<any> {
    return this.http.get(
      `${baseURL}/proposalDocument/all/${companyId}/${applicationId}/getCibilUrlFromProposal`
    );
  }

  downloadAuditTrail({ companyId, applicationId }): Observable<any> {
    return this.http.get(
      `${baseURL}/applications/OpsExcMananger/${applicationId}/${companyId}/auditTrail`
    );
  }

  fetchInvestingPartnersList(): Observable<any> {
    return this.http.get(`${baseURL}/deal/investingPartner`);
  }

  downloadGST(): Observable<any> {
    return this.http.get(`${baseURL}/deal/OpsRecovery/gst/download`, {
      responseType: 'blob',
    });
  }

  downloadFinalTermsheet(applicationId) {
    let token = localStorage.getItem('token');
    let url = `${baseURL}/termsheet/${applicationId}/download?accessToken=${token}`;
    window.open(url, '_blank');
  }

  /* ==============================POST APi's============================== */

  /* --------------------add / update deal-------------------- */
  addDeal({ companyId, applicationId, dealData }): Observable<any> {
    return this.http.post(
      `${baseURL}/deal/OpsExecutive/${companyId}/${applicationId}/addDeal`,
      dealData
    );
  }

  /* --------------------update deal params-------------------- */
  dealParamUpdate({ companyId, applicationId, dealData }): Observable<any> {
    return this.http.patch(
      `${baseURL}/deal/OpsExecutive/${companyId}/${applicationId}/updateDeal`,
      dealData
    );
  }

  /* --------------------add payment for vendor-------------------- */
  addPayment({ companyId, invoiceData }): Observable<any> {
    return this.http.post(
      `${baseURL}/payment/OpsExecutive/${companyId}/add`,
      invoiceData
    );
  }

  /* --------------------upload payment invoice to s3-------------------- */
  uploadPaymentInvoice({ companyId, applicationId, file }): Observable<any> {
    return this.http.post(
      `${baseURL}/payment/OpsExecutive/${companyId}/${applicationId}/invoiceUpload`,
      file
    );
  }

  updatePaymentStatus({
    paymentId,
    status,
    comment,
    fundingPartnerApproval,
    integrationType,
  }): Observable<any> {
    return this.http.post(
      `${baseURL}/payment/Approver/${paymentId}/updateStatus`,
      { status, comment, fundingPartnerApproval, integrationType }
    );
  }

  /* --------------------update onboarding checklist-------------------- */
  updateChecklist({ checklistId, data, status, remark }): Observable<any> {
    var body = {};
    if (status) {
      body = {
        data,
        status,
        remark,
      };
      return this.http.post(
        `${baseURL}/fp-checklist/ops/${checklistId}/updateStatus`,
        body
      );
    } else {
      body = data;
      return this.http.post(
        `${baseURL}/fp-checklist/ops/${checklistId}/updateStatus`,
        { data: body }
      );
    }
  }

  /* --------------------upload document for ops-------------------- */
  uploadDocument({
    companyId,
    applicationId,
    type,
    file,
    name,
    fileExtension,
  }): Observable<any> {
    return this.http.post(
      `${baseURL}/document/OpsExecutive/${companyId}/${applicationId}/${type}?_f=${fileExtension}&name=${name}`,
      file
    );
  }

  uploadEFDocument({ applicationId, type, file, name }): Observable<any> {
    return this.http.post(
      `${baseURL}/document/OpsExecutive/${applicationId}/uploadEFDocument?document=${type}&name=${name}`,
      file
    );
  }

  /* --------------------update deal status for manager and admin-------------------- */
  updateDealStatus({ dealId, data }): Observable<any> {
    return this.http.post(
      `${baseURL}/deal/Approver/${dealId}/updateStatus`,
      data
    );
  }

  /* --------------------update document status for manager and admin-------------------- */
  updateDocumentStatus({ documentId, data }): Observable<any> {
    return this.http.post(
      `${baseURL}/document/Approver/${documentId}/updateStatus`,
      data
    );
  }

  /* --------------------create recovery account for manager-------------------- */
  createRecoveryAccount({ companyId, applicationId, data }): Observable<any> {
    return this.http.post(
      `${baseURL}/recovery/ops/${companyId}/${applicationId}/addRecoveryAccounts`,
      data
    );
  }

  /* --------------------add pg account id to recovery account id-------------------- */
  addPgToRecoveryAccount({ recoveryAccountId, pgAccountId }): Observable<any> {
    return this.http.post(
      `${baseURL}/recovery/ops/${recoveryAccountId}/addPgToRecoveryAccount`,
      { pgAccountId }
    );
  }

  /* --------------------add pg account id to recovery account id-------------------- */
  addCardSpends({ applicationId, data }): Observable<any> {
    return this.http.post(
      `${baseURL}/cardSpends/OpsExecutive/${applicationId}/add`,
      data
    );
  }

  getDealAmount(companyId: string, applicationId: string,dealId: string): Observable<any> {
    return this.http.get(
      `${baseURL}/ops/${companyId}/${applicationId}/${dealId}/dealAmount`
    );
  }

  addledgerEntry(companyId: string, applicationId: string, payload:object): Observable<any>{
    return this.http.post(
      `${baseURL}/ops/${companyId}/${applicationId}/addLedgerEntry`,
      payload
    );

  }
  downloadInvoice(url:any): Observable<any>{
    return this.http.post(`${baseURL}/ops/downloadInvoice?url=${url}`,
      ''
    );

  }
  sendEmail(url:any ,applicationId: string): Observable<any>{
    return this.http.post(`${baseURL}/ops/${applicationId}/sendEmail?url=${url}`,
      ''
    );

  }

  /* --------------------add pg account id to recovery account id-------------------- */
  updateCardSpends({ spendId, data }): Observable<any> {
    return this.http.post(
      `${baseURL}/cardSpends/OpsExecutive/${spendId}/update`,
      data
    );
  }

  /* --------------------add pg account id to recovery account id-------------------- */
  deleteCardSpends(spendId): Observable<any> {
    return this.http.post(
      `${baseURL}/cardSpends/OpsExecutive/${spendId}/delete`,
      {}
    );
  }

  splitFromPG({
    applicationId,
    recoveryAccountId,
    amount,
    pennyTest,
    splitFrom,
  }): Observable<any> {
    let body = { recoveryAccountId, amount, pennyTest };
    return this.http.post(
      `${baseURL}/recovery/ops/${applicationId}/${splitFrom}`,
      body
    );
  }

  changeApplicationStatus({ status, applicationId }): Observable<any> {
    return this.http.patch(
      `${baseURL}/applications/Operations/${applicationId}/changeApplicationStatus`,
      { status }
    );
  }

  updateVendorStatus({ vendorID, status, comment = '' }): Observable<any> {
    return this.http.post(
      `${baseURL}/vendor/OpsManager/${vendorID}/updateStatus`,
      { status, comment }
    );
  }

  /* --------------------update deal status for manager and admin-------------------- */
  updateDocumentationStatus(applicationId, data): Observable<any> {
    return this.http.post(
      `${baseURL}/applications/OpsExcMananger/${applicationId}/updateDocumentationStatus`,
      data
    );
  }

  uploadAdditionalFiles(companyId, applicationId, data: FormData) {
    return this.http.post(
      `${baseURL}/applications/OpsExcMananger/${companyId}/${applicationId}/uploadCFM`,
      data
    );
  }

  callForMoneyRequest(applicationId, data): Observable<any> {
    return this.http.post(
      `${baseURL}/applications/OpsExcMananger/${applicationId}/updateCFMStatus`,
      data
    );
  }

  /* --------------------approve/reject documents when uploaded by executive for manager and admin-------------------- */
  approveRejectDocuments(data: any): Observable<any> {
    return this.http.post(`${baseURL}/document/Approver/updateStatus`, data);
  }

  /* --------------------counts of pending deals, documents and payments role wise for Ops Exec/Manager/Admin-------------------- */
  getPendingActionsCount(): Observable<any> {
    return this.http.get(
      `${baseURL}/applications/Operations/getPendingActionables`
    );
  }

  /* --------------------a list of company's in pending as per Ops role-------------------- */
  /* --------------------type would be one of Deals/Documents/Payments-------------------- */
  getPendingActions(type: string): Observable<any> {
    return this.http.get(
      `${baseURL}/applications/Operations/getPendingActionablesDetails`,
      { params: { type } }
    );
  }

  /* --------------------fetch pending fee disbursements list as per status-------------------- */
  /* --------------------status should be one of Pending/Approved/Rejected-------------------- */
  fetchFeeDisbursementsList(status: string): Observable<any> {
    return this.http.get(`${baseURL}/to be added`);
  }

  /* --------------------fetch pending daily repayments list -------------------- */
  fetchDailyRepayment(): Observable<any> {
    return this.http.get(`${baseURL}/to be added`);
  }

  fetchBankDetails(companyId: string): Observable<any> {
    return this.http.get(
      `${baseURL}/deal/OpsExecutive/${companyId}/bankDetails`
    );
  }
  fetchBankDetails2(companyId: string, applicationId: string): Observable<any> {
    return this.http.get(
      `${baseURL}/deal/OpsExecutive/${companyId}/${applicationId}/bankAccountsFromDeal`
    );
  }

  generatePnach(applicationId: string, payload: any): Observable<any> {
    return this.http.post(
      `${baseURL}/payment/nach/${applicationId}/nach`,
      payload
    );
  }

  getPnachList(applicationId: string): Observable<any> {
    return this.http.get(`${baseURL}/payment/nach/${applicationId}/nach`);
  }

  updatePNachStatus(nachid: string, payload: any): Observable<any> {
    return this.http.patch(
      `${baseURL}/payment/nach/${nachid}/updateNachStatus`,
      payload
    );
  }

  uploadSignedNach({ nachid, file }): Observable<any> {
    return this.http.post(
      `${baseURL}/payment/nach/${nachid}/signedNachUpload`,
      file
    );
  }

  getHandOverDetails(body: any, page: any, count: any) {
    return this.http.post(
      `${baseURL}/applications/Operations/listView?page=${page}&count=${count}`,
      body
    );
  }
  getHandOverDetailsByAppId(appId: any) {
    return this.http.get(
      `${baseURL}/applications/Operations/handOverByApplications?applicationId=${appId}`
    );
  }
  updateHandOverDetails(id: any, payload: any) {
    return this.http.post(
      `${baseURL}/applications/${id}/updateHandOver`,
      payload
    );
  }
  updateHandOverDetailsOps(id: any, payload: any) {
    return this.http.post(
      `${baseURL}/applications/${id}/updateHandOverOps`,
      payload
    );
  }

  uploadDocForOsv({
    email,
    name,
    empcode,
    file,
    pages,
    companyId,
    applicationId,
  }) {
    return this.http.post(
      `${baseURL}/FP/financialService/${companyId}/${applicationId}/dmiOSVDocuments?name=${name}&pages=${pages}&email=${email}&employeeCode=${empcode}`,
      file
    );
  }

  addDrawdownRequest(payload: any) {
    return this.http.post(`${baseURL}/disbursement/addDrawdownRequest`,payload);
  }

  drawdownRequestList(payload?: object) {
    return this.http.post(`${baseURL}/disbursement/getDrawdownRequestList`,payload);
  }
  
  updateDrawdownRequestStatus(id:any,payload?: object) {
    return this.http.post(`${baseURL}/opsDrawDownRequestRouter/${id}/updateDrawdownRequestStatus`,payload);
  }

  disbursedDrawdownRequest(id:any,payload?: object) {
    return this.http.post(`${baseURL}/disbursement/${id}/disbursedDrawdownRequest`,payload);
  }
  updateReceivedAmount(id:any,payload?: object) {
    return this.http.post(`${baseURL}/disbursement/${id}/updateReceivedAmount`,payload);
  }
  
  getDrawdownRequestDetails(payload?: object) {
    return this.http.post(`${baseURL}/disbursement/getDrawdownRequestDetails`,payload);
  }

  getAppVendors({ companyId, applicationId }): Observable<any> {
    return this.http.get(`${baseURL}/ops/${companyId}/${applicationId}/getVendorDetails`);
  }

  UpdateAppVendorsLimit({ companyId ,payload}): Observable<any> {
    return this.http.post(`${baseURL}/ops/${companyId}/setApplicationVendor`, payload);
  }
  uploadInvoice(companyId:string, applicationId:string, file): Observable<any> {
    console.log("Data==>",file)
    return this.http.post(`${baseURL}/ops/${companyId}/${applicationId}/uploadInvoice`, file);
  }
  uploadInvoiceAndGRNFile(companyId:any,applicationId:any,docType: any,file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post(`${baseURL}/disbursement/${companyId}/${applicationId}/uploadInvoice?docType=${docType}`,formdata);
  }

  getBuyerList(payload?: object) {
    return this.http.post(`${baseURL}/disbursement/getBuyerList`,payload);
  }

  getVendorList(payload?: object) {
    return this.http.post(`${baseURL}/disbursement/getVendorList`,payload);
  }

  downloadOpsDocument(doc: any): Observable<any>{
    const headers: HttpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });
    return this.http.get(`${baseURL}/document/openAPI/${doc._id}/download?name=${doc.name}&status=`, {responseType: 'blob' as 'json'})
  }

  downloadOpsDoc(doc: any): Observable<any>{
    const headers: HttpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });
    return this.http.get(`${baseURL}/document/openAPI/${doc._id}/downloadOpsDocs?name=${doc.name}&status=&url=${doc.url}`, {responseType: 'blob' as 'json'})
  }
  
  getOpsStageAndStatusList(){
    return this.http.get(`${baseURL}/applications/Operations/getOpsStageAndStatusList`);
  }
  getOpsStage(){
    return this.http.get(`${baseURL}/applications/Operations/getOpsStage`);
  }

  getOpsStatus(queryParams:object){
    return this.http.get(`${baseURL}/applications/Operations/getOpsStatus`,{params:{...queryParams}});
  }
  // Upload VA Letter End point start
  uploadIDFCVARequestLetter(applicationId: string, file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post(`${baseURL}/ops/uploadVALetterToS3?applicationId=${applicationId}`, formdata);
  }
  // Upload VA Letter End point ends
  // Send Mail Start
  idfcVALetterSendMail(applicationId: string) {
    return this.http.get(`${baseURL}/ops/sentVALetterMailByApplicationID?applicationId=${applicationId}`);
  }
  // Send mail ends

  getHandoverForAgreement(applicationId){
    return this.http.get(`${baseURL}/agreement/legal/${applicationId}/handover-details`)
  }

  getAgreement(applicationId){
    return this.http.get(`${baseURL}/agreement/legal/${applicationId}/get-agreement`)
  }

  getCluaseStatic(type){
    return this.http.get(`assets/html/clause/${type}`,{responseType :'html' as 'text'})
  }
  getAnnexureStatic(type){
    return this.http.get(`assets/html/${type}`,{responseType :'html' as 'text'})
  }

  addAgreement(applicationId, data){
    return this.http.post(`${baseURL}/agreement/legal/${applicationId}/add-agreement`,data)
  }

  updateAgreement(agreementId, payload ){
    return this.http.post(`${baseURL}/agreement/legal/${agreementId}/update-agreement`, payload)
  }

  validateAgreement(agreementId ){
    return this.http.get(`${baseURL}/agreement/legal/${agreementId}/validate-agreement`)
  }

  downloadAgreement(agreementId){
    return this.http.get(`${baseURL}/agreement/legal/${agreementId}/download-agreement`)
  }

  signRequestAgreement(agreementId){
    return this.http.post(`${baseURL}/agreement/legal/${agreementId}/sign-request-agreement`,{})
  }

  getDetailsForApplicationForm(companyId: any, applicationId: any) {
    console.log("check:::", companyId, applicationId)
    return this.http.get(`${baseURL}/ops/${companyId}/${applicationId}/getDetailsForApplicationForm`);
  }

  uploadAuthSignatoryPhoto(data: any, companyId: any, applicationId: any, document: any) {
    console.log("check data:", data)
    return this.http.post(`${baseURL}/ops/${companyId}/${applicationId}/${document}/uploadAuthSignatoryPhoto`, data);
  }

  resetImageService(data: any, companyId: any, applicationId: any) {
    console.log("check data:", data)
    return this.http.post(`${baseURL}/ops/${companyId}/${applicationId}/resetImage`, data);
  }

  generateIncredApplicationDocument(data: any, companyId: any, applicationId: any) {
    console.log("check data:", data)
    return this.http.post(`${baseURL}/ops/${companyId}/${applicationId}/generateIncredApplicationDocument`, data);
  }

}
