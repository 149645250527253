import { Directive,HostListener } from '@angular/core';

@Directive({
  selector: '[appNumbervalidate]'
})
export class NumbervalidateDirective {

  constructor() { }

  @HostListener('keydown',['$event'])
  onKeyDown(event:KeyboardEvent){
    //const keyCode=event.key
    ['e', 'E', '+'].includes(event.key) && event.preventDefault();

  }

  @HostListener('paste', ['$event']) onPaste(event:ClipboardEvent){
    let clipboardData=(event.clipboardData);
    let pastedText=clipboardData.getData('text/plain');
    // console.log("pasteddddddddddeeeeeeeeeeee::",pastedText.split(',').join(''))
    if(/[\n\s\t,]/g.test(pastedText) ) pastedText=pastedText.trim().replace(/[\n\s\t,]/g,'')

    if(+pastedText>1e9) pastedText=pastedText.slice(0,10);
    // console.log(pastedText)
    clipboardData.setData('text/plain', pastedText)
    // console.log()

    const regex=new RegExp(/^-?[0-9]+(\.\d+)?$/);
    if(!regex.test(pastedText)){
      event.preventDefault();
    }
  }

}
