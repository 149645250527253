import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[readonly],[readOnly]',
  host: {
    '[attr.readonly]': '_isReadonly ? "" : null',
  },
})
export class ReadOnlyDirective {
  constructor() {}
  _isReadonly = false;
  @Input() set readonly(v) {
    this._isReadonly = coerceBooleanProperty(v);
  }
  ngOnChanges(changes) {
    console.log(changes);
  }
}
