import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, UpdateState } from '@ngxs/store';
import { FetchDealSummary } from "../actions/deals.action";
import { ApplicationService } from "../../services/application.service";
import { updateState } from "../../utils/immutableUpdate";
import { DealSummary } from 'src/app/models/dealSummary.model';
import { tap } from 'rxjs/operators';

/* state model */
export class DealStateModel {
    isDealSummaryLoaded: false

    dealSummary: DealSummary
}

/* initial state */
@State<DealStateModel>({
  name: 'dealState',
  defaults: {
    isDealSummaryLoaded: false,
    dealSummary: null
  },
})
@Injectable()
export class DealsState {
    constructor( private _appService: ApplicationService ) {}

    @Selector()
    static isDealSummaryLoaded( state: DealStateModel ){
        return state.isDealSummaryLoaded
    }

    @Selector()
    static dealSummary( state: DealStateModel ){
        return state.dealSummary
    }

    @Action(FetchDealSummary)
    loadDealSummary({getState, setState}: StateContext<DealStateModel>, {id}: FetchDealSummary){
        const state = getState();
        return this._appService.fetchDealSummary(id).pipe(
            tap(data=>{
                (data.statusCode === "100")
                && updateState( state, { isDealSummaryLoaded: true, dealSummary: data['data'] }, setState )
                || updateState( state, { isDealSummaryLoaded: false, dealSummary: null }, setState )
            },
            error=> updateState( state, { isDealSummaryLoaded: false, dealSummary: null }, setState ))
        )
    }

}
