
/* Fetch all companies list */
/* payload contains page number and company name if includes search */
export class FetchCompanies{
    static readonly type = '[FetchCompanies] GET'
    constructor( public payload: any ){}
}

/* action to fetch downloadable bank statements under a particular account */
/* payload to receive company id and account id */
export class FetchStatements{
    static readonly type = '[FetchStatements] GET'
    constructor( public payload: any ){}
}

/* action to fetch logs for account from account id */
export class FetchAccountLogs{
    static readonly type = '[FetchAccountLogs] GET'
    constructor( public id: string ){}
}

/* fetch company details by company id */
export class FetchCompanyDetails{
    static readonly type = '[FetchCompanyDetails] GET'
    constructor( public id: string ){}
}

export class ResetOnDifferentCompany{
    static readonly type = '[ResetOnDifferentCompany] RESET'
}