/* action on dashboard to get all applications for credit analysis */
/* 
    here payload consist of companyName, status, roleName & currentPage in pagination
*/
export class FetchCreditApplications {
    static readonly type = '[CreditApplications] GET'
    constructor(public payload: any) { }
}
/* action to fetch users under particular role on credit dashboard */
export class FetchUsersUnderRole {
    static readonly type = '[UsersUnderRole] GET'
}

/* action to fetch revenues for a particular company */
/* Here id in payload is company id */
export class FetchRevenues {
    static readonly type = '[Revenues] GET'
    constructor(public id: string) { }
}

/* action to fetch saved analysis on a particular application */
/* Here id in payload is application id */
export class FetchSavedAnalysis {
    static readonly type = '[FetchSavedAnalysis] GET'
    constructor(public id: string) { }
}
export class SetSavedAnalysisLoadedToFalse {
    static readonly type = '[SetSavedAnalysisLoadedToFalse] SET'
}

/* save termsheet */
/* payload contains companyId, applicationId, analysisId and termsheetData */
export class SetTSLoadedToFalse {
    static readonly type = '[SetTSLoadedToFalse] SET'
}

/* fetch saved termsheets */
/* payload contains companyId, applicationId, analysisId and termsheetData */
export class FetchSavedTermsheets {
    static readonly type = '[FetchTermsheetsUnderApplication] GET'
    constructor(public id: string) { }
}

/* download termsheet from digio */
/* id in payload is digio signing id */
export class DownloadTSFromDigio {
    static readonly type = '[DownloadTSFromDigio] GET'
    constructor(public id: string) { }
}

export class ResetOnDifferentApplication {
    static readonly type = '[ResetOnDifferentApplication] RESET'
}
export class FetchCreditStepsStatus {
    static readonly type = '[FetchCreditStepsStatus] RESET'
    constructor(public id: string) { }
}
export class SetSelectedApplication {
    static readonly type = '[SetSelectedApplication] SET'
    constructor(public id: string) { }
}
export class ChangeCreditStepStatus {
    static readonly type = '[ChangeCreditStepStatus] SET'
}
export class FetchUsersListUnderRole {
    static readonly type = '[FETCH_USERS_LIST_UNDER_ROLE] GET'
    constructor(public role: string) { }
}