import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { SwalPopupService } from 'src/app/services/swal-popup.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  email: string = '';
  password: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthService,
    private loader: NgxUiLoaderService,
    private swal: SwalPopupService
  ) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    if (!!this.email && !!this.password) {
      this.loader.start()
      this.authenticationService.login(this.email, this.password).subscribe(resp => {
        this.loader.stop()
        if (resp['statusCode'] == 100 && resp['message'] == 'Login Success') {

          let { tokens: { accessToken, refreshToken }, user } = resp['data']

          this.authenticationService.setUserAndToken(
            accessToken,
            refreshToken,
            user,
            this.email
          );
          this.checkRedirection(user);
        } else {
          this.loader.stop();
          this.swal.error('Something went wrong');
        }
      },
        (error: HttpErrorResponse) => {
          this.loader.stop();
          console.error(error);
          error['error']['message'] == 'Authentication failure'
            ? this.swal.error('Email or Password incorrect')
            : this.swal.error(error['error']['message']);
        });
    }
    else {
      this.swal.error('Email or Password cannot be empty')
    }
  }

  checkRedirection({ roles }) {
    let role = roles[0].role;
    role == 'Deals-Manager' ? this.redirectTo('deals-dashboard')
      : role == 'Deals-Analyst' ? this.redirectTo('deals-dashboard')
        : role == 'Credit-Manager' ? this.redirectTo('analyst-dashboard')
          : role == 'Credit-Analyst' ? this.redirectTo('analyst-dashboard')
            : role == 'Recovery-Manager' ? this.redirectTo('recovery-dashboard')
              : role == 'Recovery-Analyst' ? this.redirectTo('recovery-dashboard')
                : role == 'Ops-Manager' ? this.redirectTo('ops-dashboard')
                  : role == 'Ops-Admin' ? this.redirectTo('ops-dashboard')
                    : role == 'Ops-Executive' ? this.redirectTo('ops-dashboard')
                      : role == 'IC' ? this.redirectTo('ic-dashboard/pd-list')
                        : role == 'Admin' ? this.redirectTo('admin-dashboard')
                          : role == 'Hr' ? this.redirectTo('hr-dashboard')
                            : role == 'Growth' ? this.redirectTo('ic-dashboard/leads')
                              : role == 'Alliances' ? this.redirectTo('companies')
                                : role == 'DCM' ? this.redirectTo('ic-dashboard/pd-list')
                                  : role == 'Trustee' ? this.redirectTo('trustee/pending-payments')
                                    : role == 'Business-Head' ? this.redirectTo('ic-dashboard/deals-dashboard')
                                    : role == 'Deals-Admin' ? this.redirectTo('deals-dashboard') 
                                    : role == 'Res-User' ? this.redirectTo('companies') 
                                    : role == 'Legal' ? this.redirectTo('ops-dashboard') : null
  }

  redirectTo(url) {
    if (this.returnUrl && this.returnUrl.includes('?')) {
      // split the url to remove query params
      let urlSplit = this.returnUrl.split('?');
      this.router.navigate([urlSplit[0]], {
        queryParams: { status_id: new URL('http://localhost/' + this.returnUrl).searchParams.get('status_id') },
      });
    } else if (this.returnUrl === '/') {
      this.router.navigate([`/main/${url}`]);
    } else {
      this.router.navigate([this.returnUrl])
    }
  }
}
