import { environment } from "../environments/environment";
export class Config {
    baseUrl: string
    authRoles: {}
    signer_2: any = {
        "identifier": "bhavik@getvantage.co",
        "name": "Bhavik Vasa",
        "sign_type": "aadhaar",
        "reason": "Sign requested"
    }//Live
    tagArray: any = [
        { item_id: 1, item_text: 'MH-100-100-Estamp' },
        { item_id: 2, item_text: 'MH-500-500-Estamp' }
    ]//Live
    yblBalanceCheckUrl: string;

    // signer_2: any = {
    //     "identifier": "deepss507@gmail.com",
    //     "name": "Bhavik Vasa",
    //     "sign_type": "aadhaar",
    //     "reason": "Sign requested"
    // }//Test
    // tagArray: any = [
    //     { item_id: 1, item_text: 'MH-100-Test1' },
    //     { item_id: 2, item_text: 'MH-500-Test' }
    // ]//Test
    constructor() {

        this.baseUrl = environment.apiUrl
        this.authRoles = {
            DEALS_M: 'Deals-Manager',
            DEALS_A: 'Deals-Analyst',
            CREDIT_M: 'Credit-Manager',
            CREDIT_A: 'Credit-Analyst',
            RECOVERY_M: 'Recovery-Manager',
            RECOVERY_A: 'Recovery-Analyst',
            OPS_A: 'Ops-Admin',
            OPS_M: 'Ops-Manager',
            OPS_EX: 'Ops-Executive',
            IC: 'IC',
            ALL_READ: 'Read',
            ADMIN: 'Admin',
            HR: 'Hr',
            GROWTH: 'Growth',
            ALLIANCE: 'Alliances',
            DCM: 'DCM',
            TRUSTEE: 'Trustee',
            BUSINESS_HEAD: 'Business-Head',
            DEALS_ADMIN: 'Deals-Admin',
            RES_USER: 'Res-User',
            LEGAL: 'Legal'
        }

    }

    getBaseURL() {
        return this.baseUrl
    }
    getRoles() {
        return this.authRoles
    }
    getSigner2Details() {
        return this.signer_2
    }
    getTagArray() {
        return this.tagArray
    }
    getSocketUrl() {
        return environment.apiUrl.split('/v1')[0]
    }
}
