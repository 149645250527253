import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[onlyNumber]'
})
export class OnlyNumberDirective {

  constructor() { }

  @HostListener('keypress', ['$event']) AllowedOnlyNumberic(event:any){
    var regex = new RegExp("^[0-9]+$");
            var key = String.fromCharCode(event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
            else
            {
              return true;
            }
  }

}
