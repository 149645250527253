import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({
  name: 'notesFormat'
})
export class NotesFormatPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}
  roles=JSON.parse(localStorage.getItem('roles')).roles.map(x=>x.role)
  transform(input: any, args: any){
    if (input) {
      const output = input.split(/\n/).filter(ele => ele).map((ele, i) => `${i+1})&nbsp;${ele}`).join('<br /><li>')
      return this.sanitizer.bypassSecurityTrustHtml(`<ul><li>${output}</li></ul>`)
    }
    if(this.roles.includes('IC')) return this.sanitizer.bypassSecurityTrustHtml('<br />')
    return this.sanitizer.bypassSecurityTrustHtml('<br />Note(s): No Notes added. Click edit icon above to add some notes.')
  }

}
