import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApproveOrRejectAnalysis, FetchAnalysisForIC } from '../../actions/ic.actions';
import { tap } from 'rxjs/operators';
import { IcService } from '../../../services/ic.service';
import { number } from '@amcharts/amcharts4/core';
import { updateState } from '../../../utils/immutableUpdate';
import { FetchPdListForIC } from '../../actions/pd-list.actions';

/* state model */
export class PDListStateModel {
  pdlist: [];
  pdlistLoaded: boolean;
  currentPage: string;
  status: string;
  companyName: string;
  totalPages: number
  pdlistCount: number
  pdlistSentBack: boolean
  searchKey: string
}

/* initial state */
@State<PDListStateModel>({
  name: 'pdList',
  defaults: {
    pdlist: [],
    pdlistLoaded: false,
    currentPage: '0',
    status: 'Pending',
    companyName: '',
    pdlistCount: 0,
    totalPages: 0,
    pdlistSentBack: false,
    searchKey: null,
  },
})
@Injectable()
export class PdListState {
  constructor(private _icService: IcService) { }
  /* return analysis from state */
  @Selector()
  static pdlistForIC(state: PDListStateModel) {
    return state;
  }

  @Selector()
  static isPdListLoaded(state: PDListStateModel) {
    return state.pdlistLoaded
  }

  @Selector()
  static isPdListSentBack(state: PDListStateModel) {
    return state.pdlistSentBack
  }

  /* fetch analysis pendingAnd set it to state */
  @Action(FetchPdListForIC)
  pdlist({ getState, setState }: StateContext<PDListStateModel>, { payload }: FetchAnalysisForIC) {
    let { currentPage, companyName, status, count } = payload;
    const state = getState();
    return this._icService
      .fetchPDListForIC(currentPage, companyName, status, count)
      .pipe(
        tap(
          (data) => {
            (data['statusCode'] == 100) &&
              updateState(state, this.fetchSuccess(data, payload), setState) ||
              updateState(state, this.fetchFailure(), setState)
          },
          (error) => updateState(state, this.fetchFailure(), setState)
        )
      );
  }

  fetchSuccess(data, { currentPage, companyName, status, count }) {
    return {
      applications: data['data']['applications'],
      applicationsCount: data['data']['count'],
      totalPagesCount: Math.ceil(data['data']['count'] / count),
      applicationsLoaded: true,
      currentPage,
      companyName,
      status,
    }
  }

  fetchFailure() {
    return {
      applications: [],
      applicationsCount: 0,
      totalPagesCount: 0,
      applicationsLoaded: false,
      currentPage: 0,
      companyName: ''
    }
  }


}