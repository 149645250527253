import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfRolesDirective } from './if-roles.directive';
import { IfNotRolesDirective } from './if-not-roles.directive';
import { DownloadDocumentDirective } from './download-document.directive';
import { AmountToLakhPipe } from './amount-to-lakh.pipe';
import { IndianAmountFormatPipe } from './indian-amount-format.pipe';
import { NotesFormatPipe } from './notes.pipe';
import { DaysToStringPipe } from './days-to-string.pipe';
import { CommaListPipe } from './comma-to-list.pipe';
import { AmountToWordsPipe } from './amount-to-words.pipe';
import { NumberToWordsPipe } from './amount-to-million.pipe';
import { InputWithCommaDirective } from './input-with-comma.directive';
import { SortDirective } from './sort.directive';
import { ReadOnlyDirective } from './read-only.directive';
import { PercentageDirective } from './percentage.directive';
import { NumbervalidateDirective } from './numbervalidate.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { TrimContent } from './trimContent.pipe';
import { MonthYearPipe } from './month-year.pipe';
import { DateFormatDirective } from './date-format.directive';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations: [
    IfRolesDirective,
    IfNotRolesDirective,
    DownloadDocumentDirective,
    AmountToLakhPipe,
    IndianAmountFormatPipe,
    NotesFormatPipe,
    DaysToStringPipe,
    CommaListPipe,
    AmountToWordsPipe,
    NumberToWordsPipe,
    InputWithCommaDirective,
    SortDirective,
    ReadOnlyDirective,
    PercentageDirective,
    NumbervalidateDirective,
    OnlyNumberDirective,
    TrimContent,
    MonthYearPipe,
    DateFormatDirective,
    TruncatePipe
  ],
  exports: [
    IfRolesDirective,
    SortDirective,
    IfNotRolesDirective,
    DownloadDocumentDirective,
    AmountToLakhPipe,
    IndianAmountFormatPipe,
    NotesFormatPipe,
    DaysToStringPipe,
    CommaListPipe,
    AmountToWordsPipe,
    NumberToWordsPipe,
    InputWithCommaDirective,
    PercentageDirective,
    NumbervalidateDirective,
    OnlyNumberDirective,
    TrimContent,
    MonthYearPipe,
    DateFormatDirective,
    TruncatePipe
  ],
  imports: [CommonModule],
})
export class AppPipesModule {}
